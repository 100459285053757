import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
import React, { useRef, useState } from 'react';
import { documentsAPI } from 'stateManager/services';
import { classnames } from 'utils';
import { ReactComponent as Icon } from 'assets/images/icon_download.svg';
// import { useTitle } from 'hooks';
import { Link } from 'react-router-dom';
import metatags from 'metatags';
import styles from './DocumentationPage.module.scss';

const Documentation = () => {
  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Компания',
      link: '/about',
    },
    {
      title: 'Документация',
      link: '/documentation',
    },
  ];
  // useTitle('Документация');

  const { data: docs, isFetching } = documentsAPI.useFetchAllDocumentsQuery();
  const [certButtonStateOpen, setCertButtonStateOpen] = useState(false);
  const [documentsButtonStateOpen, setDocumentsButtonStateOpen] = useState(false);

  const certWrapper = useRef();
  const documentsWrapper = useRef();

  const getCertificateHeight = (h) => {
    if (certButtonStateOpen) {
      return h;
    }
    return 0;
  };
  const getDocumentsHeight = (h) => {
    if (documentsButtonStateOpen) {
      return h;
    }
    return 0;
  };

  if (isFetching) {
    <div className="page_container">
      <Loading />
    </div>;
  }

  if (!docs) {
    return <div className="page_container" style={{ color: 'white' }}>not found</div>;
  }

  return (
    <div className="page_container">
      <HelmetHandle
        title="Документация"
        metaTitle="Документация"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />

      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>
      <h1>Документация</h1>

      {/* Сертификаты */}
      <section className={classnames([styles.inner, styles.certificates, [styles.open, certButtonStateOpen]])}>
        <h2 className="visually-hidden">Сертификаты:</h2>
        <button
          className={styles.innerTitle}
          type="button"
          onClick={() => {
            setCertButtonStateOpen(!certButtonStateOpen);
          }}
        >
          Сертификаты
        </button>

        <div
          className={styles.categoriesContainer}
          style={{ height: `${getCertificateHeight(certWrapper.current?.getBoundingClientRect().height)}px` }}
        >
          <ul className={styles.categoriesWrapper} ref={certWrapper}>
            {docs.certificates.map((cat) => (
              <li key={cat.id}>
                <h3 className={styles.categoriesTitle}>{cat.title}</h3>
                <ul className={styles.certificateWrapper}>
                  {cat.certificates.map((cert) => (

                    <li className={styles.certItem} key={cert.id}>
                      <h3 className="visually-hidden">{cert.title}</h3>
                      <Link to={cert.file} className={styles.certFile} download target="_blank" rel="noreferer">
                        <Icon className={styles.icon} />
                        <span className={styles.certTitle}>{cert.title}</span>
                      </Link>
                    </li>

                  ))}
                </ul>

              </li>
            ))}
          </ul>
        </div>

      </section>

      {/* Документы */}
      <section className={classnames([styles.inner, styles.documents, [styles.open, documentsButtonStateOpen]])}>

        <h2 className="visually-hidden">Документы</h2>
        <button
          className={styles.innerTitle}
          type="button"
          onClick={() => {
            setDocumentsButtonStateOpen(!documentsButtonStateOpen);
          }}
        >Документы
        </button>

        <div
          className={styles.categoriesContainer}
          style={{ height: `${getDocumentsHeight(documentsWrapper.current?.getBoundingClientRect().height)}px` }}
        >
          <ul className={styles.categoriesWrapper} ref={documentsWrapper}>
            {docs.documents.map((doc) => (
              <li className={styles.certItem} key={doc.id}>
                <h3 className="visually-hidden">{doc.title}</h3>
                <Link to={doc.file} className={styles.certFile} download target="_blank" rel="noreferer">
                  <Icon className={styles.icon} />
                  <span className={styles.certTitle}>{doc.title}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>

      </section>
    </div>
  );
};

export default Documentation;
