import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { serverMessageSlice } from 'stateManager/reducers/ServerMessageSlice';
import { classnames } from 'utils';
import styles from './ServerMessage.module.scss';

const ServerMessage = ({ text, id, type }) => {
  const dispatch = useDispatch();
  const { removeMessage } = serverMessageSlice.actions;
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        dispatch(removeMessage(id));
      }, 350);
      setHidden(true);
    }, 3000);
  }, []);
  return (
    <AnimatePresence>
      {!hidden && (
        <motion.div
          className={classnames([styles.text, [styles.success, type === 'success']])}
          initial={{ left: -500 }}
          animate={{ left: 0, transition: { duration: 0.35 } }}
          exit={{ left: -500 }}
        >
          {text}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ServerMessage;
