import { BlueButton, BreadCrumbs, CustomInput, EmptyButton, Loading } from 'components/universal_components';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { catalogAPI, orderAPI } from 'stateManager/services';
import { getUuid, handleServerError, moneyFormatter } from 'utils';
import { clearOrder } from 'stateManager/reducers/OrderSlice';
import { useTitle } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { serverMessageSlice } from 'stateManager/reducers/ServerMessageSlice';
import styles from './OrderPage.module.scss';
import OrderItem from './OrderItem/OrderItem';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Кабинет',
    link: '/cabinet',
  },
  {
    title: 'Корзина',
    link: '/order',
  },
];

const OrderPage = () => {
  useTitle('Корзина');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [_sendOrder, { isLoading }] = orderAPI.useCreateOrderMutation();

  const { order } = useSelector((state) => state.orderReducer);

  const { addMessage } = serverMessageSlice.actions;

  const sendOrder = async () => {
    const res = await _sendOrder(order);
    if (!res.error) {
      dispatch(clearOrder());
      dispatch(
        addMessage({ id: getUuid(), text: 'Ваш заказ успешно создан!', type: 'success' }),
      );
      navigate('/cabinet/orders');
    } else {
      handleServerError(res.error);
    }
  };

  const { data: products, isFetching } = catalogAPI.useFetchAllInstancesQuery({ ids: order.map((pr) => pr.id) });

  const [filter, setFilter] = useState('');

  if (isFetching || isLoading) {
    return <Loading />;
  }

  if (!order.length || !products.length) {
    return (
      <div className="page_container">
        <p className={styles.isEmpty}>В корзине пока пусто</p>
      </div>
    );
  }

  const totalPrice = order?.map(
    (pr) => products.find((item) => item.id === pr.id).product.price * pr.quantity,
  ).reduce((a, b) => a + b, 0);
  let nds = totalPrice / 120;
  nds *= 20;
  nds = Math.round(nds);

  return (
    <div className="page_container">
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>
      <h1 className={styles.title}>Моя корзина</h1>

      <div className={styles.buttons}>
        <div className={styles.filter}>
          <CustomInput inputType="text" placeholder="Фильтр" onChange={(value) => setFilter(value.toLowerCase())} />
        </div>
        <div className={styles.clean}>
          <BlueButton onClick={() => { dispatch(clearOrder()); }} text="Очистить корзину" />
        </div>

        <p className={styles.inCart}>Товаров в&nbsp;корзине:&nbsp;{order.reduce((a, b) => a + b.quantity, 0)}</p>
      </div>

      <div className={styles.list}>
        {products.filter((product) => product.product.title.toLowerCase().includes(filter) || product.article.toLowerCase().includes(filter) || filter === '').map((item) => (
          <OrderItem {...item} key={item.id} />
        ))}
      </div>

      {/* Нижняя секция */}
      <div className={styles.total}>

        <div className={styles.continue}>
          <EmptyButton to="/catalog" text="Продолжить покупки" variant="bigPadding" />
        </div>

        <div className={styles.result}>
          <p className={styles.result_nds}>Сумма НДС: {moneyFormatter(nds)}</p>
          <p className={styles.result_sum}>Итого: {moneyFormatter(totalPrice)}</p>
        </div>

        <div className={styles.buy}>
          <BlueButton text="Купить в 1 клик" onClick={sendOrder} />
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
