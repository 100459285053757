import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const thanksAPI = createApi({
  reducerPath: 'thanks',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllThanks: build.query({
      query: (limit = 5) => ({
        url: '/thanks/thanks/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results,
    }),

    // fetchSingleNews: build.query({
    //   query: (slug) => ({ url: `/news/news/${slug}/` }),
    // }),
  }),
});
export default thanksAPI;
