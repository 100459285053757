import React from 'react';
import styles from './PartnerItem.module.scss';

const PartnerItem = ({ image, title }) => (
  <div className={styles.image}>
    <img src={image} alt={title} />
  </div>
);

export default PartnerItem;
