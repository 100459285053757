import { BlueButton, CustomForm, HelmetHandle, Loading } from 'components/universal_components';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { authAPI } from 'stateManager/services';
import { handleServerError, notEmptyValidator, passwordValidators, setValidationErrors } from 'utils';
import metatags from 'metatags';
import styles from './ResetPasswordPage.module.scss';

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();

  const [password, setPassword] = useState('');
  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);
  const [repassword, setRePassword] = useState('');
  const [repasswordValidationErrors, setRePasswordValidationErrors] = useState([]);

  const fields = [
    {
      name: 'password',
      inputType: 'password',
      placeholder: 'Новый пароль',
      required: true,
      value: password,
      onChange: (value) => setPassword(value),
      validators: [notEmptyValidator, ...passwordValidators],
      validationErrors: passwordValidationErrors,
      setValidationErrors: (value) => {
        setPasswordValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'repassword',
      inputType: 'password',
      placeholder: 'Повторите пароль',
      required: true,
      value: repassword,
      onChange: (value) => setRePassword(value),
      validators: [{ id: 1, func: (value) => value === password, errorText: 'Пароли не совпадают' }],
      validationErrors: repasswordValidationErrors,
      setValidationErrors: (value) => {
        setRePasswordValidationErrors((state) => setValidationErrors(state, value));
      },
    },

  ];

  const [sendForm, {
    isLoading,
    isError: isFetchingError,
    error,
    isSuccess,
  }] = authAPI.useForgotPasswordConfirmMutation();

  useEffect(() => {
    if (isFetchingError) {
      handleServerError(error);
    }
  }, [isFetchingError]);

  if (isLoading) {
    return <Loading />;
  }

  const sendData = async () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      await sendForm({ ...dataToSend, token: searchParams.get('token') });
    }
  };

  return (
    <div className="page_container">
      <HelmetHandle
        title="Восстановление пароля"
        metaTitle="Восстановление пароля"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Восстановление пароля</h3>
        {isSuccess ? <div className={styles.success}>Пароль успешно изменен</div>
          : (
            <>
              <CustomForm fields={fields} styles={styles} />
              <p className={styles.required}>* - Поля, обязательные для заполнения</p>
              <BlueButton
                text="Отправить"
                variant="diller"
                onClick={() => {
                  sendData();
                }}
              />
            </>

          )}

      </div>
    </div>

  );
};

export default ResetPasswordPage;
