import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { handleServerError, notEmptyValidator, phoneValidator, setValidationErrors } from 'utils';
import { BlueButton, ConsentPersonalData, CustomForm, CustomInput } from 'components/universal_components';

import proceedGif from 'assets/images/proceed.gif';
import { askDiscountAPI } from 'stateManager/services';
import styles from './AskDiscount.module.scss';

const AskDiscount = ({ ProductLink }) => {
  const [name, setName] = useState('');
  const [nameValidationErrors, setNameValidationErrors] = useState([]);

  const [phone, setPhone] = useState('');
  const [phoneValidationErrors, setPhoneValidationErrors] = useState([]);

  const [text, setText] = useState('');
  const [textValidationErrors, setTextValidationErrors] = useState([]);

  const fields = [
    {
      name: 'name',
      inputType: 'text',
      placeholder: 'Имя',
      required: true,
      value: name,
      onChange: (value) => setName(value),
      validators: [notEmptyValidator],
      validationErrors: nameValidationErrors,
      setValidationErrors: (value) => {
        setNameValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'phone',
      inputType: 'tel',
      placeholder: 'Телефон',
      required: true,
      value: phone,
      onChange: (value) => setPhone(value),
      validators: [notEmptyValidator, phoneValidator],
      validationErrors: phoneValidationErrors,
      setValidationErrors: (value) => {
        setPhoneValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'text',
      inputType: 'textarea',
      placeholder: 'Дополнительная информация',
      required: true,
      value: text,
      onChange: (value) => setText(value),
      validators: [notEmptyValidator],
      validationErrors: textValidationErrors,
      setValidationErrors: (value) => {
        setTextValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'link',
      inputType: 'string',
      placeholder: ProductLink,
      required: true,
      value: ProductLink,
      onChange: (value) => setText(value),
      validators: [notEmptyValidator],
      validationErrors: textValidationErrors,
      setValidationErrors: (value) => {
        setTextValidationErrors((state) => setValidationErrors(state, value));
      },
    },
  ];

  const [isAgree, setIsAgree] = useState(false);
  const [sendForm, { isLoading, isSuccess, isError: isFetchError, error }] =
    askDiscountAPI.useCreateAskDiscountApiMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  const sendData = () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      sendForm(dataToSend);
    }
  };

  // анимация ожидания
  if (isLoading) {
    return <img src={proceedGif} alt="" style={{ width: `${60}px`, height: `${60}px` }} />;
  }

  if (isSuccess) {
    return (
      <AnimatePresence>
        <motion.div
          className={styles.success_sending}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.35,
            },
          }}
          exit={{
            opacity: 0,
          }}
        >
          <p>Вопрос отправлен!</p>
          <p>Менеджер свяжется с&nbsp;вами в&nbsp;ближайшее время</p>
        </motion.div>
      </AnimatePresence>
    );
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className={styles.wrapper} onClick={(event) => event.stopPropagation()}>
      <h3 className={styles.title}>Узнать размер скидки</h3>
      <CustomForm styles={styles} fields={fields} />
      <div className={styles.agree}>
        <CustomInput inputType="checkbox" checked={isAgree} onChange={(value) => setIsAgree(value)} />
        <ConsentPersonalData setIsAgree={setIsAgree} />
      </div>
      <p className={styles.required_fields}>* - Поля обязательные для заполнения</p>
      <BlueButton text="Отправить заявку" disabled={!isAgree} variant="question" onClick={sendData} />
    </div>
  );
};

export default AskDiscount;
