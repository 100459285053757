import React from 'react';
import { useSelector } from 'react-redux';
import ServerMessage from './ServerMessage/ServerMessage';
import styles from './ServerMessages.module.scss';

const ServerMessages = () => {
  const { messages } = useSelector((state) => state.serverMessageReducer);
  return (
    <div className={styles.wrapper}>
      {messages.map((message) => (
        <div className={styles.message} key={message.id}>
          <ServerMessage {...message} />
        </div>
      ))}
    </div>
  );
};

export default ServerMessages;
