import React from 'react';
import { ReactComponent as Arrow } from 'assets/images/slider/arrow.svg';

const NextArrow = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Arrow style={{ transform: 'rotate(180deg)' }} />
  </div>
);

export default NextArrow;
