import React from 'react';
import { newsAPI } from 'stateManager/services';
import { CustomSlider, Loading } from 'components/universal_components';
import { useWindowSize } from 'hooks';
import NewsItem from './NewsItem/NewsItem';
import styles from './NewsSection.module.scss';

const NewsSection = () => {
  const [width] = useWindowSize();

  const { data: news, isFetching } = newsAPI.useFetchAllNewsQuery(20);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  const settings = {
    slidesToShow: width > 1365 ? 2 : 1,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: true,
  };

  return (
    <>
      <div className={styles.title}>Новости</div>
      <div className={styles.items}>
        <CustomSlider settings={settings} data={news.items} SliderItem={NewsItem} variant="black" />
      </div>
    </>
  );
};

export default NewsSection;
