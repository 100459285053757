import React from 'react';
import { useParams } from 'react-router-dom';
import { catalogAPI } from 'stateManager/services';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
// import { useTitle } from 'hooks';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import ProductsSection from '../ProductsSection/ProductsSection';
import styles from './SubCategoryPage.module.scss';

const SubCategoryPage = () => {
  const params = useParams();

  const { data: subCategory, isFetching } = catalogAPI.useFetchSingleSubCategoryQuery(params.slug);

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!subCategory) {
    return <NotFoundPage />;
  }
  // useTitle(subCategory.title);

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Каталог',
      link: '/catalog',
    },
    {
      title: subCategory.category.title,
      link: `/catalog/${subCategory.category.slug}`,
    },
    {
      title: subCategory.title,
      link: `/catalog/subcategory/${subCategory.slug}`,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className="page_container">
        <HelmetHandle
          title={subCategory.title}
          metaTitle={subCategory.meta_title}
          description={subCategory.description_title}
          metaDescription={subCategory.meta_description}
          image={subCategory.image}
        />

        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} variant="blue" />
        </div>

        <h1 className={styles.title}>{subCategory.title}</h1>

        <div className={styles.desc}>
          <h2 className="visually-hidden">{`Описание подкатегории ${subCategory.title}`}</h2>
          <div className={styles.image}>
            <img src={subCategory.image} alt={subCategory.title} />
          </div>

          <p className={styles.desc_title}>{subCategory.description_title}</p>
          <p className={styles.desc_text}>{subCategory.description_text}</p>
        </div>

        <section>
          <h2 className="visually-hidden">{`Товары, соответствующие подкатегории ${subCategory.title}`}</h2>
          <ProductsSection subcategoryId={subCategory.id} />
        </section>
      </div>
    </div>
  );
};

export default SubCategoryPage;
