import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { catalogAPI } from 'stateManager/services';
import { Loading, Pagination } from 'components/universal_components';
import { useSearchParams } from 'react-router-dom';
import { classnames, checkObjectFields } from 'utils';
import { ReactComponent as Cards } from 'assets/images/catalog/cards.svg';
import { ReactComponent as List } from 'assets/images/catalog/list.svg';
import { ReactComponent as More } from 'assets/images/catalog/more.svg';
import styles from './ProductsSection.module.scss';
import CatalogPageProduct from './CatalogPageProduct/CatalogPageProduct';

const LIMIT_PRODUCTS_ON_PAGE = 20;

const ProductsSection = ({ filters, categoryId, subcategoryId, variant = '', pagination = true }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') ? +searchParams.get('page') - 1 : 0;
  const { data: products, isFetching: isFetchingProducts } = catalogAPI.useFetchAllProductsQuery({
    limit: LIMIT_PRODUCTS_ON_PAGE,
    offset: page * LIMIT_PRODUCTS_ON_PAGE,
    category: categoryId || filters?.category?.join(','),
    subcategory: subcategoryId,
    fastening_type: filters?.fastening_type?.join(','),
    temperature: filters?.temperature?.join(','),
    power_min: filters?.power_min,
    power_max: filters?.power_max,
    price_min: filters?.price_min,
    price_max: filters?.price_max,
    light_flow_min: filters?.light_flow_min,
    light_flow_max: filters?.light_flow_max,
    old_price: filters?.old_price,
    is_new: filters?.is_new,
  });

  const [itemStyle, setItemStyle] = useState('cards');

  useEffect(() => {
    if (filters && pagination && checkObjectFields(filters)) {
      setSearchParams((params) => {
        params.set('page', 1);
        return params;
      });
    }
  }, [filters]);

  if (isFetchingProducts) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  const pageCount = Math.ceil(products.count / LIMIT_PRODUCTS_ON_PAGE);

  return (
    <>
      <div className={classnames([styles.itemStyleControl, styles[variant]])}>
        <button type="button" onClick={() => setItemStyle('cards')} className={classnames([[styles.active, itemStyle === 'cards']])}>
          <div className="visually-hidden">Карточки</div>
          <div className={styles.icon}>
            <Cards />
          </div>
        </button>
        <button type="button" onClick={() => setItemStyle('list')} className={classnames([[styles.active, itemStyle === 'list']])}>
          <div className="visually-hidden">Список</div>
          <div className={styles.icon}>
            <List />
          </div>
        </button>
        <button type="button" onClick={() => setItemStyle('more')} className={classnames([[styles.active, itemStyle === 'more']])}>
          <div className="visually-hidden">Подробнее</div>
          <div className={styles.icon}>
            <More />
          </div>
        </button>
      </div>
      <div itemScope itemType="http://schema.org/ItemList" className={classnames([styles.inner, styles[itemStyle]])}>
        {products.results.map((product) => (
          <AnimatePresence mode="popLayout" key={product.id}>
            <motion.div
              className={styles.product}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.35,
                },
              }}
              exit={{
                opacity: 0,
              }}
            >
              <CatalogPageProduct {...product} type={itemStyle} />
            </motion.div>
          </AnimatePresence>
        ))}
      </div>
      {(pageCount > 1 && pagination) && (
      <div className={styles.pagination}>
        <Pagination pageCount={pageCount} page={page} variant="black" />
      </div>
      )}
    </>
  );
};

export default ProductsSection;
