import { CustomSlider, Loading } from 'components/universal_components';
import React from 'react';
import { newsAPI } from 'stateManager/services';
import { useWindowSize } from 'hooks';
import LastNewsItem from './LastNewsItem';

const LastNewsSection = ({ currentNewsID = -1 }) => {
  const { data: news, isFetching } = newsAPI.useFetchAllNewsQuery({ limit: 20 });

  const [width] = useWindowSize();

  if (isFetching) {
    return <Loading />;
  }

  const settings = {
    slidesToShow: width > 1365 ? 4 : 2,
    slidesToScroll: 1,
    infinite: false,
  };

  return (
    <CustomSlider
      data={news.items.filter((item) => item.id !== currentNewsID)}
      SliderItem={LastNewsItem}
      settings={settings}
      variant="black"
    />
  );
};

export default LastNewsSection;
