import React from 'react';
import { newsAPI } from 'stateManager/services';
import { classnames } from 'utils';
import { Loading } from 'components/universal_components';
import styles from './NewsPage.module.scss';

const YearsSection = ({ setYearFilter, yearFilter }) => {
  const { data: years, isFetching } = newsAPI.useFetchAllYearsQuery();

  if (isFetching) {
    return <Loading />;
  }

  return (
    years.map((year) => (
      <li key={year}>
        <button
          type="button"
          className={classnames([styles.yearFilter, [styles.active, year === +yearFilter]])}
          onClick={() => setYearFilter(year)}
        >
          {year}
        </button>
      </li>
    ))
  );
};

export default YearsSection;
