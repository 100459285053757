import React from 'react';
import { classnames } from 'utils';
import styles from './Burger.module.scss';

const Burger = ({ isBurgerMenuActive, black }) => (
  <div className={classnames([styles.burger, [styles.pressed, isBurgerMenuActive], [styles.black, black]])}>
    <span />
    <span />
    <span />
  </div>
);

export default Burger;
