import React from 'react';
import styles from './FactorySection.module.scss';

const FactoryItem = ({ id, title, image, description }) => (
  <div className={styles.item} key={id}>
    <h3 className={styles.itemTitle}><span>{title}</span></h3>
    <div className={styles.itemImage}>
      <img src={image} alt={title} />
    </div>
    <div className={styles.itemText}>{description}</div>
  </div>
);

export default FactoryItem;
