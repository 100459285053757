import { BlueButton, BreadCrumbs, EmptyButton, Loading } from 'components/universal_components';
import React, { useState } from 'react';
import { catalogAPI } from 'stateManager/services';
import CatalogPageProduct from 'components/pages/CatalogPages/ProductsSection/CatalogPageProduct/CatalogPageProduct';
import { classnames } from 'utils';
import styles from './CoparisonPage.module.scss';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Мой кабинет',
    link: '/cabinet',
  },
  {
    title: 'Сравнение',
    link: '/cabinet/coparisons',
  },
];

const CoparisonPage = () => {
  const { data: coparisons, isFetching: isFetchingCoparisons } = catalogAPI.useFetchAllComparisonsQuery();
  const { data: products, isFetching: isFetchingProducts } = catalogAPI.useFetchAllInstancesQuery({ ids: coparisons });
  const [deleteAll] = catalogAPI.useDeleteAllCoparisonsMutation();

  // брэнд
  const brand = products?.map((el) => (el.led_brand ? el.led_brand : ''));
  // артикул
  const articles = products?.map((el) => (el.article ? el.article : ''));
  // цветовая температура
  const colorTemperature = products?.map((el) => (el.color_temperature ? el.color_temperature : ''));
  // тип крепления
  const fasteningType = products?.map((el) => (el.product.fastening_type.title ? el.product.fastening_type.title : ''));
  // температура эксплуатации
  const tempRange = products?.map((el) => (el.product.temperature ? el.product.temperature : ''));
  // мощность
  const power = products?.map((el) => (el.product.power ? el.product.power : ''));
  // степень защиты
  const protection = products?.map((el) => (el.product.protection ? el.product.protection : ''));
  // габаритные размеры
  const sizes = products?.map((el) => (el.product.sizes ? el.product.sizes : ''));
  // оптика
  const optic = products?.map((el) => (el.optic ? el.optic : ''));
  // световая отдача
  const lightOutput = products?.map((el) => (el.light_output ? el.light_output : ''));
  // цена

  const [isFilter, setIsFilter] = useState(false);
  if (isFetchingCoparisons || isFetchingProducts) {
    return <Loading />;
  }
  if (!coparisons.length) {
    return <div className="page_container"><p className={styles.nothing}>Вы ничего не добавили к сравнению</p></div>;
  }
  return (
    <div className="page_container">
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>
      <h1 className={styles.title}>Сравнение</h1>

      <div className={styles.filters}>
        <div className={styles.filter}>
          <BlueButton text="Все характеристики" onClick={() => setIsFilter(false)} variant="coparison" />
        </div>

        {coparisons.length > 1 && (
        <div className={styles.difference}>
          <EmptyButton variant="coparison" text="Только отличающиеся" onClick={() => setIsFilter(true)} />
        </div>
        )}
        <div className={styles.clear}>
          <BlueButton text="Очистить сравнение" onClick={deleteAll} variant="coparison" />
        </div>
      </div>

      <div className={styles.wrapper}>
        {/* карточка */}
        {([...new Set(products.map((pr) => pr.product.id))].length || !isFilter) && (
        <div className={styles.card} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }}>
          {products?.map((el) => (
            <CatalogPageProduct coparisonId={el.id} {...el.product} key={el.product.id} />
          ))}
        </div>
        )}

        {/* брэнд */}
        {([...new Set(brand)].length > 1 || !isFilter) && (
        <div className={classnames([styles.brand, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Бренд">
          {brand.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* артикул */}
        {([...new Set(articles)].length > 1 || !isFilter) && (
        <div className={classnames([styles.article, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Артикул">
          {articles.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* цветовая температура */}
        {([...new Set(colorTemperature)].length > 1 || !isFilter) && (
        <div className={classnames([styles.colorTemperature, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Цветовая температура">
          {colorTemperature.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* тип крепления */}
        {([...new Set(fasteningType)].length > 1 || !isFilter) && (
        <div className={classnames([styles.fasteningType, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Тип крепления">
          {fasteningType.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* температура эксплуатации */}
        {([...new Set(tempRange.map((el) => el.id))].length > 1 || !isFilter) && (
        <div className={classnames([styles.tempRange, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Температура эксплуатации">
          {tempRange.map((el) => (
            <p key={el.id}>от {el.start} до {el.end}℃</p>
          ))}
        </div>
        )}
        {/* мощность */}
        {([...new Set(power)].length > 1 || !isFilter) && (
        <div className={classnames([styles.power, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Мощность">
          {power.map((el) => (
            <p key={el}>{el}Вт</p>
          ))}
        </div>
        )}
        {/* степень защиты */}
        {([...new Set(protection)].length > 1 || !isFilter) && (
        <div className={classnames([styles.protection, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Степень защиты">
          {protection.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* габаритные размеры */}
        {([...new Set(sizes)].length > 1 || !isFilter) && (
        <div className={classnames([styles.sizes, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Габаритные размеры">
          {sizes.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* оптика */}
        {([...new Set(optic)].length > 1 || !isFilter) && (
        <div className={classnames([styles.optic, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Оптика">
          {optic.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}
        {/* световая отдача */}
        {([...new Set(lightOutput)].length > 1 || !isFilter) && (
        <div className={classnames([styles.lightOutput, styles.item])} style={{ gridTemplateColumns: `repeat(${products.length}, 250px)` }} data-text="Световая отдача">
          {lightOutput.map((el) => (
            <p key={el}>{el}</p>
          ))}
        </div>
        )}

      </div>
    </div>
  );
};

export default CoparisonPage;
