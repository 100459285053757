import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { blogAPI } from 'stateManager/services';
import { Loading, Pagination } from 'components/universal_components';
import BlogArticleSection from './BlogArticleSection/BlogArticleSection';
import styles from './BlogSection.module.scss';

const LIMIT_VIDEOS_ON_PAGE = 20;

const BlogVideoListSection = () => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') ? +searchParams.get('page') - 1 : 0;
  const offset = page * LIMIT_VIDEOS_ON_PAGE;

  const { data, isFetching: isFetchingVideos } = blogAPI.useFetchAllBlogVideosQuery(
    { limit: LIMIT_VIDEOS_ON_PAGE, offset },
  );
  const { results: blogVideos, count } = data || { items: {}, count: 0 };
  const pageCount = Math.ceil(count / LIMIT_VIDEOS_ON_PAGE);

  if (isFetchingVideos) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!blogVideos) {
    return <>not found</>;
  }

  return (
    <section className={styles.section}>
      <h2 className="visually-hidden">Статьи</h2>

      <ul className={styles.list}>
        {blogVideos.map((el) => (
          <BlogArticleSection key={el.id} image={el.image} title={el.title} slug={el.slug} />
        ))}
      </ul>

      {pageCount > 1 && <Pagination pageCount={pageCount} page={page} /> }

    </section>
  );
};

export default BlogVideoListSection;
