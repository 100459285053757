import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { BlueButton, Loading } from 'components/universal_components';
import { contactsAPI } from 'stateManager/services';
import { Link } from 'react-router-dom';
import styles from './ContactsFilialsMap.module.scss';

const ContactsFilialsMap = () => {
  const [activeFilials, setActiveFilials] = useState();
  const [cities, setCities] = useState();
  const [city, setCity] = useState('');

  const { data: filials, isFetching } = contactsAPI.useFetchAllFilialsQuery();

  useEffect(() => {
    if (filials?.length) {
      const tmp = filials.map((item) => item.title);
      setCities(tmp.filter((item, index) => tmp.indexOf(item) === index).sort());
    }
  }, [filials]);

  if (isFetching) {
    return <Loading />;
  }
  if (!cities) return null;

  return (
    <div className={styles.filials}>
      {/* <div className={styles.title}>Наши филиалы</div> */}

      <iframe
        title="FilialsMap"
        src="https://yandex.ru/map-widget/v1/?um=constructor%3A350fe2ad9de006048cba2732429daf42f5fc1e6b0faa6df486de45bc8083ece8&amp;source=constructor"
        width="1280"
        height="720"
        className={styles.map}
      />
      <div className={styles.control}>
        <Dropdown
          className={styles.myClassName}
          controlClassName={styles.myControlClassName}
          placeholderClassName={styles.myPlaceholderClassName}
          menuClassName={styles.myMenuClassName}
          arrowClassName={styles.myArrowClassName}
          arrowClosed={<span className={styles.arrow_closed} />}
          arrowOpen={<span className={styles.arrow_open} />}
          options={cities?.map((el) => el)}
          onChange={(el) => {
            setCity(cities.find((item) => item === el.value));
            setActiveFilials(filials.filter((item) => item.title === el.value));
          }}
          value={city !== '' ? city : 'Выбрать город'}
          placeholder="Выбрать город"
        />

        <BlueButton className={styles.BlueButton} onClick={() => setCity('')} text="Все объекты" />
      </div>
      <div className={styles.items}>
        {city
          ? activeFilials.map((activeFilial) => (
            <div className={styles.item} key={activeFilial.id}>
              <div className={styles.image}>
                <img src={activeFilial.image} alt={activeFilial.title} />
              </div>
              <div className={styles.info}>
                <h3 className={styles.title}>{activeFilial.title}</h3>
                <p className={styles.address}>{activeFilial.address}</p>
                {activeFilial.phone && (
                  <p className={styles.phone}>
                    <span>Телефон:&nbsp;</span>
                    <Link to={`tel:${activeFilial.phone}`}>
                      {activeFilial.phone}
                    </Link>
                  </p>
                )}
                {activeFilial.email && (
                  <p className={styles.email}>
                    <span>E-mail:&nbsp;</span>
                    <span>{activeFilial.email}</span>
                  </p>
                )}
              </div>
            </div>
          ))
          : filials.map((el) => (
            <div className={styles.item} key={el.id}>
              <div className={styles.image}>
                <img src={el.image} alt={el.title} />
              </div>
              <div className={styles.info}>
                <h3 className={styles.title}>{el.title}</h3>
                <p className={styles.address}>{el.address}</p>
                {el.phone && (
                  <p className={styles.phone}>
                    <span>Телефон:&nbsp;</span>
                    <Link to={`tel:${el.phone}`}>{el.phone}</Link>
                  </p>
                )}
                {el.email && (
                  <p className={styles.email}>
                    <span>E-mail:&nbsp;</span>
                    <span>{el.email}</span>
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ContactsFilialsMap;
