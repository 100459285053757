import React from 'react';

import { Link } from 'react-router-dom';
import styles from './SearchResultItem.module.scss';

const SearchResultItem = ({ slug, title, short_description: shortDescription, page = '' }) => (
  <Link to={`/${page}/${slug}`} className={styles.article}>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.description}>{shortDescription}</p>
  </Link>
);

export default SearchResultItem;
