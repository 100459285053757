import { BreadCrumbs, Loading } from 'components/universal_components';
import React, { useState } from 'react';
import { catalogAPI } from 'stateManager/services';
import CatalogPageProduct from 'components/pages/CatalogPages/ProductsSection/CatalogPageProduct/CatalogPageProduct';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './LikesPage.module.scss';
import ControlPanel from './ControlPanel';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Мой кабинет',
    link: '/cabinet',
  },
  {
    title: 'Избранные товары',
    link: '/cabinet/likes',
  },
];

const LikesPage = () => {
  const [search, setSearch] = useState('');
  const { data: likes, isFetching: isLikesFetching } = catalogAPI.useFetchAllLikesQuery();
  const { data: products, isFetching: isProductsFetching } = catalogAPI.useFetchAllProductsQuery({ ids: likes?.join(',') });

  const [deleteAllLikes] = catalogAPI.useDeleteAllLikesMutation();

  if (isLikesFetching || isProductsFetching) {
    return <Loading />;
  }

  const filteredProducts = search
    ? products.results.filter((product) => product.title.toLowerCase().includes(search.toLowerCase()))
    : products.results;

  const content = !filteredProducts.length
    ? <p className={styles.nothing}>Ничего не найдено</p>
    : (
      <div className={styles.inner}>
        {filteredProducts.map((product) => (
          <AnimatePresence mode="popLayout" key={product.id}>
            <motion.div
              className={styles.product}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.35,
                },
              }}
              exit={{
                opacity: 0,
              }}
            >
              <CatalogPageProduct {...product} />
            </motion.div>
          </AnimatePresence>
        ))}
      </div>
    );

  if (likes.length === 0) {
    return (
      <div className="page_container">
        <p className={styles.nothing}>Вы ничего не добавили в избранное</p>
      </div>
    );
  }

  return (
    <div className="page_container">
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>

      <h1 className={styles.title}>Избранные товары</h1>

      <ControlPanel setSearch={setSearch} deleteAllLikes={deleteAllLikes} search={search} />

      {content}
    </div>
  );
};

export default LikesPage;
