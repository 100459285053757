import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const projectsAPI = createApi({
  reducerPath: 'projects',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  //   tagTypes: ['Post'],
  endpoints: (build) => ({
    fetchAllProjects: build.query({
      query: (limit = 5) => ({
        url: '/projects/projects/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results,

      //   providesTags: (result) => ['Post'],
    }),
    fetchAllProjectsCategories: build.query({
      query: (limit = 5) => ({ url: '/projects/categories/', params: { limit } }),
      transformResponse: (response) => response.results,
    }),
    fetchProject: build.query({
      query: (slug) => ({ url: `/projects/projects/${slug}/` }),
    }),
    // createPost: build.mutation<IPost, IPost>({
    //     query: (post) => ({
    //         url: `/posts`,
    //         method: 'POST',
    //         body: post
    //     }),
    //     invalidatesTags: ['Post']
    // }),
    // updatePost: build.mutation<IPost, IPost>({
    //     query: (post) => ({
    //         url: `/posts/${post.id}`,
    //         method: 'PUT',
    //         body: post
    //     }),
    //     invalidatesTags: ['Post']
    // }),
    // deletePost: build.mutation<IPost, IPost>({
    //     query: (post) => ({
    //         url: `/posts/${post.id}`,
    //         method: 'DELETE',
    //     }),
    //     invalidatesTags: ['Post']
    // }),
  }),
});
export default projectsAPI;
