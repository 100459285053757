import React from 'react';
import { useParams } from 'react-router-dom';
import { catalogAPI } from 'stateManager/services';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import ProductsSection from '../ProductsSection/ProductsSection';
import styles from './SingleCatalogPage.module.scss';
import SubCategoryCard from './SubCategoryCard/SubCategoryCard';

const SingleCatalogPage = () => {
  const params = useParams();

  const { data: category, isFetching: isFetchingCategories } = catalogAPI.useFetchSingleCategoryQuery(params.slug);

  if (isFetchingCategories) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  if (!category) {
    return <NotFoundPage />;
  }

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Каталог',
      link: '/catalog',
    },
    {
      title: category.title,
      link: `/catalog/${category.slug}`,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className="page_container">
        <HelmetHandle
          title={category.title}
          metaTitle={category.meta_title}
          description={category.description_text}
          metaDescription={category.meta_description}
          image={category.image}
        />

        <div className={styles.container}>
          <div className="breadCrumbs">
            <BreadCrumbs items={BREAD_CRUMBS} variant="blue" />
          </div>

          <h1 className={styles.title}>{category.title}</h1>

          <div className={styles.image}>
            <img src={category.image} alt={category.title} />
          </div>
          <div className={styles.desc}>
            <h2 className="visually-hidden">{`Описание категории ${category.title}`}</h2>
            <p className={styles.desc_title}>{category.description_title}</p>
            <p className={styles.desc_text}>{category.description_text}</p>
          </div>

          <section className={styles.subcategories}>
            <h2 className="visually-hidden">{`Подкатегории категории ${category.title}`}</h2>
            {category.subcategories.map((subcategory) => (
              <div key={subcategory.id} className={styles.subcategory}>
                <SubCategoryCard {...subcategory} />
              </div>
            ))}
          </section>
          <div className={styles.products}>
            <h2 className="visually-hidden">{`Товары категории ${category.title}`}</h2>
            <ProductsSection categoryId={category.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleCatalogPage;
