import React from 'react';
import { classnames, dateFormater } from 'utils';
import CatalogPageProduct from 'components/pages/CatalogPages/ProductsSection/CatalogPageProduct/CatalogPageProduct';
import styles from './OrdersListPage.module.scss';
import OrderStatusIcons from './OrderStatusIcons/OrderStatusIcons';

const OrderListItem = ({ section, time, products, filter }) => {
  const filteredProducts = products.filter((product) => product.product.title.toLowerCase().includes(filter.toLowerCase()) || filter === '').filter((product) => section.items.find((item) => item.product === product.id));
  return filteredProducts.length ? (
    <div
      className={classnames([styles.section])}
      key={section.id}
    >
      <p className={styles.date}>Заказ от {dateFormater(time)}</p>

      <div className={styles.panel}>
        <OrderStatusIcons status={section.status} />
      </div>

      <div className={styles.grid}>
        {products
          .filter((el) => (section.items.find((i) => i.product === el.id)))
          .map((product) => (
            <div className={styles.item} key={product.id}>
              <CatalogPageProduct
                {...product.product}
                isActive={product.product.title.toLowerCase().includes(filter.toLowerCase()) && !(filter === '')}
              />
              <div className={styles.quantity}>
                {section.items.find((item) => item.product === product.id).quantity} шт.
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default OrderListItem;
