import React from 'react';
import styles from './FooterContacts.module.scss';

const FOOTER_CONTACTS = [
  { text: '8-800-600-55-69', link: 'tel:8-800-600-55-69' },
  { text: 'г. Красноярск, ул. Пограничников, д. 31', link: '' },
  { text: 'info@fosgroup.ru', link: 'mailto:info@fosgroup.ru' },
];

const FooterContacts = () => (
  <div className={styles.wrapper}>
    {FOOTER_CONTACTS.map((contact) => (
      <div className={styles.contact} key={contact.text}>
        {contact.link ? <a href={contact.link}>{contact.text}</a> : <span>{contact.text}</span>}
      </div>
    ))}
  </div>
);

export default FooterContacts;
