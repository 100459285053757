import React from 'react';
import styles from './FooterContactLinksItem.module.scss';

const FooterContactLinksItem = ({ onClick, image }) => (
  <button type="button" onClick={onClick} className={styles.link}>
    <img src={image} alt="задать вопрос" />
  </button>
);

export default FooterContactLinksItem;
