/* eslint-disable no-param-reassign */
const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
  order: JSON.parse(localStorage.getItem('order')) || [],
};

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      state.order = state.order.filter((pr) => pr.id !== action.payload.id);
      state.order.push(action.payload);
      localStorage.setItem('order', JSON.stringify(state.order));
    },
    removeProduct: (state, action) => {
      state.order = state.order.filter((item) => item.id !== action.payload);
      localStorage.setItem('order', JSON.stringify(state.order));
    },
    clearOrder: (state) => {
      state.order = [];
      localStorage.removeItem('order');
    },
    setQuantity: (state, action) => {
      state.order.find((item) => item.id === action.payload.id).quantity = action.payload.quantity;
      localStorage.setItem('order', JSON.stringify(state.order));
    },
  },
});

export default orderSlice.reducer;

export const { addProduct, removeProduct, clearOrder, setQuantity } = orderSlice.actions;
