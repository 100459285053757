import React from 'react';
import { EmptyButton } from 'components/universal_components';
import { Link } from 'react-router-dom';
import styles from './BlogArticleSection.module.scss';

const BlogArticleSection = ({ image, title, slug }) => (
  <li className={styles.wrapper}>
    <Link to={`/articles/${slug}`} className={styles.title}>
      <h3>{title}</h3>
    </Link>

    <EmptyButton to={`/articles/${slug}`} text="Подробнее" />

    <div className={styles.image}>
      <Link to={`/articles/${slug}`}>
        <img src={image} alt={title} />
      </Link>
    </div>
  </li>
);
export default BlogArticleSection;
