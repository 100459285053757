import React, { useRef, useState } from 'react';
import { BlueButton } from 'components/universal_components';
import { classnames } from 'utils';
import styles from './CatalogPageFilters.module.scss';
import CategoryFilter from './CategoryFilter';
import FasteningTypeFilter from './FasteningTypeFilter';
import LightFlowFilter from './LightFlowFilter';
import OperatingTemperaturesFilter from './OperatingTemperaturesFilter';
import PowerFilter from './PowerFilter';
import PriceFilter from './PriceFilter';

const CatalogPageFilters = ({ filters, setFilters, emptyFilters }) => {
  const addFilter = (value) => {
    setFilters({ ...filters, [value.type]: value.value });
  };

  const [activeFilterModal, setActiveFilterModal] = useState('');

  const [isFiltersActive, setIsFiltersActive] = useState(false);

  const removeFilter = (type) => {
    setFilters((value) => value.filter((f) => f.type !== type));
  };

  const categoryRef = useRef(null);
  const fasteningTypeRef = useRef(null);
  const temperatureRef = useRef(null);
  const priceRef = useRef(null);
  const powerRef = useRef(null);
  const lightFlowRef = useRef(null);

  const setFilterHandler = (value) => {
    if (value === emptyFilters) {
      categoryRef.current.emptyFilter();
      fasteningTypeRef.current.emptyFilter();
      temperatureRef.current.emptyFilter();
      priceRef.current.emptyFilter();
      powerRef.current.emptyFilter();
      lightFlowRef.current.emptyFilter();
    }
    setFilters(value);
  };

  const getIsFiltersExists = () => !!Object.values(filters).map((filter) => !!filter?.length).some((item) => item);

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={classnames([styles.show, [styles.filled, getIsFiltersExists()]])}>
          <BlueButton variant="blackTransparent" onClick={() => setIsFiltersActive(!isFiltersActive)} text={isFiltersActive ? 'Скрыть фильтры' : 'Показать фильтры'} />
        </div>
      </div>
      <div className={classnames([styles.filters, [styles.active, isFiltersActive]])}>
        <div className={styles.category}>
          <CategoryFilter
            addFilter={addFilter}
            removeFilter={removeFilter}
            activeFilterModal={activeFilterModal}
            setActiveFilterModal={setActiveFilterModal}
            filters={filters}
            ref={categoryRef}
          />
        </div>
        <div className={styles.power}>
          <PowerFilter
            addFilter={addFilter}
            activeFilterModal={activeFilterModal}
            setActiveFilterModal={setActiveFilterModal}
            removeFilter={removeFilter}
            filters={filters}
            ref={powerRef}
          />
        </div>
        <div className={styles.light_flow}>
          <LightFlowFilter
            addFilter={addFilter}
            removeFilter={removeFilter}
            activeFilterModal={activeFilterModal}
            setActiveFilterModal={setActiveFilterModal}
            filters={filters}
            ref={lightFlowRef}
          />
        </div>
        <div className={styles.fastening_type}>
          <FasteningTypeFilter
            addFilter={addFilter}
            removeFilter={removeFilter}
            activeFilterModal={activeFilterModal}
            setActiveFilterModal={setActiveFilterModal}
            filters={filters}
            ref={fasteningTypeRef}
          />
        </div>
        <div className={styles.temperature}>
          <OperatingTemperaturesFilter
            addFilter={addFilter}
            removeFilter={removeFilter}
            activeFilterModal={activeFilterModal}
            setActiveFilterModal={setActiveFilterModal}
            filters={filters}
            ref={temperatureRef}
          />
        </div>
        <div className={styles.price}>
          <PriceFilter
            addFilter={addFilter}
            removeFilter={removeFilter}
            activeFilterModal={activeFilterModal}
            setActiveFilterModal={setActiveFilterModal}
            filters={filters}
            ref={priceRef}
          />
        </div>
        <div className={styles.reset}>
          <BlueButton
            text="Сбросить"
            variant="readMore"
            onClick={() => setFilterHandler(emptyFilters)}
            filters={filters}
            disabled={filters === emptyFilters}
          />
        </div>
      </div>

    </div>
  );
};

export default CatalogPageFilters;
