import React from 'react';
import styles from './SingleBlogSlider.module.scss';

const SingleBlogSlider = ({ image }) => (
  <div className={styles.image}>
    <img src={image} alt="" />
  </div>
);

export default SingleBlogSlider;
