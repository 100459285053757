/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './HeaderNavItem.module.scss';

const HeaderNavItem = ({ link, text }) => (
  <NavLink
    to={link}
    // eslint-disable-next-line no-confusing-arrow
    className={({ isActive }) =>
      isActive ? `${styles.link} ${styles.pressed}` : styles.link
    }
  >
    {text}
  </NavLink>
);

export default HeaderNavItem;
