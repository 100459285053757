import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProjectsProject.module.scss';

const ProjectsProject = ({ title, image, slug }) => (
  <Link to={`/projects/${slug}`} className={styles.wrapper}>
    <div className={styles.image}>
      <img src={image} alt={title} />
    </div>
    <h3 className={styles.title}>{title}</h3>
  </Link>
);

export default ProjectsProject;
