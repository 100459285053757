/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */
import React from 'react';
import { classnames, moneyFormatter } from 'utils';
import styles from './Price.module.scss';

const CustomPrice = ({ product }) => {
  // console.log('CASE 3', product);

  if (!product) {
    return <>не найдено</>;
  }

  return (
    <div>
      {product.wholesale_price
        ? (
          <p className={classnames([styles.price, styles.wholesale])}>
            <span>
              <span>Цена</span>
              <span itemProp="price" content={product.price}>{moneyFormatter(product.price)}</span>

            </span>
            <span>
              <span>Минимальная&nbsp;цена</span>
              <span>{moneyFormatter(product.min_price)}</span>
            </span>
            <span>
              <span>Оптовая&nbsp;цена</span>
              <span>{moneyFormatter(product.wholesale_price)}</span>
            </span>
          </p>
        )
        : !product.wholesale_price && product.old_price
          ? (
            <p className={classnames([styles.price, styles.old])}>
              <span>Цена&nbsp;{moneyFormatter(product.price)}</span>
              <span>{moneyFormatter(product.old_price)}</span>
            </p>
          )
          : (
            <p itemProp="price" content={product.price} className={classnames([styles.price])}>Цена&nbsp;{moneyFormatter(product.price)}
              <meta itemProp="priceCurrency" content="RUB" />
            </p>
          )}
    </div>
  );
};

export default CustomPrice;
