import { BlueButton, CustomForm, Loading } from 'components/universal_components';
import React, { useEffect, useState } from 'react';
import { emailValidator, handleServerError, notEmptyValidator, setValidationErrors } from 'utils';
import { authAPI } from 'stateManager/services';
import styles from './RestorePasswordModal.module.scss';

const RestorePasswordModal = ({ width, height }) => {
  const [email, setEmail] = useState('');
  const [emailValidationErrors, setEmailValidationErrors] = useState([]);

  const fields = [
    {
      name: 'email',
      inputType: 'text',
      placeholder: 'e-mail',
      required: true,
      value: email,
      onChange: (value) => setEmail(value),
      validators: [notEmptyValidator, emailValidator],
      validationErrors: emailValidationErrors,
      setValidationErrors: (value) => {
        setEmailValidationErrors((state) => setValidationErrors(state, value));
      },
    },

  ];

  const [sendForm, { isLoading, isError: isFetchError, error, isSuccess }] = authAPI.useForgotPasswordMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  if (isLoading) {
    return <Loading />;
  }

  const sendData = async () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      await sendForm(dataToSend);
    }
  };

  return (
    <div className={styles.wrapper} style={{ width: `${width}` }}>
      <h3 className={styles.title}>Восстановление пароля</h3>
      {isSuccess ? <p className={styles.success}>Письмо отправлено на ваш электронный адрес</p>
        : (
          <>
            <CustomForm fields={fields} styles={styles} />
            <p className={styles.required}>* - Поля, обязательные для заполнения</p>
            <BlueButton
              text="Отправить"
              variant="diller"
              onClick={() => {
                sendData();
              }}
            />
          </>

        )}

    </div>
  );
};

export default RestorePasswordModal;
