import React from 'react';
import { useTitle } from 'hooks';
import { HelmetHandle, VideoSection } from 'components/universal_components';
import metatags from 'metatags';
import AboutSection from './AboutSection/AboutSection';
import DealersSection from './DealersSection/DealersSection';
import HistorySection from './HistorySection/HistorySection';
import LaboratorySection from './LaboratorySection/LaboratorySection';
import styles from './MainPage.module.scss';
import NewsSection from './NewsSection/NewsSection';
import PartnersSection from './PartnersSection/PartnersSection';
import ProjectsSection from './ProjectsSection/ProjectsSection';
import PromoSection from './PromoSection/PromoSection';

const MainPage = () => {
  useTitle('Главная');

  return (
    <>
      <HelmetHandle
        title="Главная"
        metaTitle="Главная"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <h1 className="visually-hidden">Производим в Красноярске светодиодные светильники широкого применения</h1>
      <section className={styles.promo}>
        <h2 className="visually-hidden">Основные ссылки по товарам</h2>
        <PromoSection />
      </section>
      <div className={styles.info}>
        <div className="page_container">
          <section className={styles.video}>
            <h2 className="visually-hidden">Презентация производства светодиодных светильников FOS</h2>
            <VideoSection />
          </section>

          <section className={styles.about}>
            <h2 className="visually-hidden">О нас</h2>
            <AboutSection />
          </section>

          <section className={styles.history}>
            <h2 className="visually-hidden">Наша история</h2>
            <HistorySection />
          </section>

          <section className={styles.laboratory}>
            <h2 className="visually-hidden">Наша лаборатория</h2>
            <LaboratorySection />
          </section>

          <section className={styles.projects} id="main_page_projects">
            <h2 className="visually-hidden">Наши проекты</h2>
            <ProjectsSection />
          </section>

          <section className={styles.partners} id="main_page_partners">
            <h2 className="visually-hidden">Наши партнеры</h2>
            <PartnersSection />
          </section>

          <section className={styles.news} id="main_page_news">
            <h2 className="visually-hidden">Новости</h2>
            <NewsSection />
          </section>

          <DealersSection />
        </div>
      </div>
    </>
  );
};

export default MainPage;
