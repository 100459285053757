import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL_API } from './BASE_URL';

export const authAPI = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_API,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Account'],
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query(data) {
        return {
          url: 'accounts/register/',
          method: 'POST',
          body: data,
        };
      },
    }),
    loginUser: builder.mutation({
      query(data) {
        return {
          url: 'accounts/login/',
          method: 'POST',
          body: data,
        };
      },
    }),
    getInfo: builder.query({
      query: (limit = 5) => ({
        url: '/accounts/me/',
      }),
      providesTags: ['Account'],
    }),
    setInfo: builder.mutation({
      query: (data) => ({
        url: '/accounts/me/',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Account'],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: '/accounts/change_password/',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Account'],
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: '/accounts/password_reset/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Account'],
    }),
    forgotPasswordConfirm: builder.mutation({
      query: (data) => ({
        url: '/accounts/password_reset/confirm/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Account'],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
} = authAPI;
