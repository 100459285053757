/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetHandle = ({ image, title, metaTitle, description, metaDescription, product = false }) => {
  useEffect(() => {
    const removeMetaTag = (selector) => {
      const tag = document.querySelector(selector);
      if (tag) {
        tag.parentNode.removeChild(tag);
      }
    };

    removeMetaTag('meta[name="description"]');
    removeMetaTag('meta[property="og:description"]');
    removeMetaTag('meta[property="og:title"]');
    removeMetaTag('meta[property="og:image"]');
  }, [image, title, metaTitle, description, metaDescription]);

  return (
    <Helmet prioritizeSeoTags>
      {metaTitle
        ? <title>{`${metaTitle} | FOS Group`}</title>
        : <title>{`${title} | FOS Group`}</title>}

      {metaTitle
        ? <meta property="og:title" content={`${metaTitle} | FOS Group`} />
        : <meta property="og:title" content={`${title} | FOS Group`} />}

      {metaDescription
        ? <meta name="description" content={metaDescription} />
        : product === true
          ? null
          : <meta name="description" content={description} />}

      {metaDescription
        ? <meta property="og:description" content={metaDescription} />
        : product === true
          ? null
          : <meta property="og:description" content={description} />}

      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
};

export default HelmetHandle;
