import React from 'react';
import { projectsAPI } from 'stateManager/services';
import { CustomSlider, Loading } from 'components/universal_components';
import { useWindowSize } from 'hooks';
import ProjectsItem from './ProjectsItem/ProjectsItem';
import styles from './ProjectsSection.module.scss';

const ProjectsSection = () => {
  const [width] = useWindowSize();

  const settings = {
    slidesToShow: width > 767 ? 2 : 1,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: true,
  };

  const { data, isFetching } = projectsAPI.useFetchAllProjectsQuery(20);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  return (
    <>
      <div className={styles.title}>Проекты</div>
      <CustomSlider settings={settings} SliderItem={ProjectsItem} data={data} variant="black" />
    </>
  );
};

export default ProjectsSection;
