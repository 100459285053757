import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const dealersFormAPI = createApi({
  reducerPath: 'dealersForm',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    createRequest: build.mutation({
      query: (data) => ({
        url: '/dealers/requests/',
        method: 'POST',
        body: data,
      }),
    }),
    fetchAllDealers: build.query({
      query: (args = { length: 30 }) => ({
        url: '/dealers/dealers/',
        params: args,
      }),
      transformResponse: (response) => response.results,
    }),
    fetchAllDealersDocs: build.query({
      query: (args) => ({
        url: '/dealers/document/',
        params: args,
      }),
      transformResponse: (response) => response.results,
    }),
  }),
});
export default dealersFormAPI;
