import React, { forwardRef } from 'react';
import { catalogAPI } from 'stateManager/services';
import { classnames } from 'utils';
import { EmptyButton, Loading } from 'components/universal_components';
import styles from './CatalogPageFilters.module.scss';
import ChoiceFilter from './ChoiceFilter/ChoiceFilter';

const OPERATING_TYPE_FILTER_NAME = 'operatingType';

const constactFilter = (value) => (filteringValue) => {
  const { id } = filteringValue;
  return value.map((temperature) => temperature.id).includes(id);
};
const OperatingTemperaturesFilter = forwardRef((props, ref) => {
  const { data: temperatures, isFetching } = catalogAPI.useFetchAllOperatingTemperaturesQuery(20);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  const adaptedTemperatures = temperatures.map((temp) => ({ title: `от ${temp.start} до ${temp.end}`, id: temp.id }));
  return (
    <div className={classnames([styles.filterWrapper, 'ignoreCloseFilter', [styles.filled, props.filters.temperature?.length]])}>
      <EmptyButton
        text="Температура эксплуатации"
        variant="black"
        onClick={() => {
          props.setActiveFilterModal(
            OPERATING_TYPE_FILTER_NAME === props.activeFilterModal ? '' : OPERATING_TYPE_FILTER_NAME,
          );
        }}
      />
      <ChoiceFilter
        choices={adaptedTemperatures}
        isModalActive={OPERATING_TYPE_FILTER_NAME === props.activeFilterModal}
        setIsModalActive={props.setActiveFilterModal}
        removeFilter={props.removeFilter}
        addFilter={props.addFilter}
        filterPrefix="temperature"
        constactFilter={constactFilter}
        filters={props.filters}
        ref={ref}
      />
    </div>
  );
});

export default OperatingTemperaturesFilter;
