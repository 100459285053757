import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Card } from 'assets/images/header/card.svg';
import { ReactComponent as Like } from 'assets/images/header/like.svg';
import { ReactComponent as Profile } from 'assets/images/header/profile.svg';
import { ReactComponent as Statistic } from 'assets/images/header/statistic.svg';
import { classnames } from 'utils';
import { useAuth } from 'hooks';
import { useSelector } from 'react-redux';
import { catalogAPI } from 'stateManager/services';
import styles from './HeaderIcons.module.scss';

const HeaderIcons = ({ black, setModalRegisterState, inBurgerMenu }) => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const { order } = useSelector((state) => state.orderReducer);
  const { data: coparisons } = catalogAPI.useFetchAllComparisonsQuery();
  const { data: likes } = catalogAPI.useFetchAllLikesQuery();

  return (
    <div className={classnames([
      styles.wrapper,
      [styles.black, black],
      [styles.inBurgerMenu, inBurgerMenu],
      [styles.authorise, isAuth]])}
    >
      {
        isAuth && (
        <>
          <Link
            to="/cabinet/likes"
            className={classnames([
              styles.like,
              styles.icon,
              [styles.inBurgerMenu, inBurgerMenu],
              [styles.filled, likes?.length]])}
            data-length={likes?.length}
          >
            <Like />
          </Link>

          <Link
            to="/cabinet/coparisons"
            className={
            classnames([
              styles.statistic,
              styles.icon,
              [styles.filled, coparisons?.length],
              [styles.inBurgerMenu, inBurgerMenu]])
            }
            data-length={coparisons?.length}
          >
            <Statistic />
          </Link>
        </>
        )
      }

      <Link
        to="/cabinet/order"
        className={classnames([
          styles.card,
          styles.icon,
          [styles.inBurgerMenu, inBurgerMenu]])}
      >
        <Card />
        {order.length > 0 && <span className={styles.digit}>{order.length}</span>}
      </Link>

      <button
        type="button"
        className={classnames([
          styles.profile,
          styles.icon,
          [styles.autorise, isAuth],
          [styles.black, black], [styles.inBurgerMenu, inBurgerMenu]])}
        onClick={() => (isAuth ? navigate('/cabinet') : setModalRegisterState('login'))}
      >
        <Profile />
      </button>
    </div>
  );
};

export default HeaderIcons;
