import React from 'react';
import { partnersAPI } from 'stateManager/services';
import { CustomSlider, Loading } from 'components/universal_components';
import { useWindowSize } from 'hooks';
import PartnerItem from './PartnerItem/PartnerItem';
import styles from './PartnersSection.module.scss';

const PartnersSection = () => {
  const [width] = useWindowSize();

  const { data, isFetching } = partnersAPI.useFetchAllPartnersQuery(20);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  const settings = {
    // eslint-disable-next-line no-nested-ternary
    slidesToShow: width > 1365 ? 5 : width > 767 ? 3 : 2,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: true,
  };
  return (
    <>
      <div className={styles.title}>Наши партнёры</div>
      <div className={styles.items}>
        <CustomSlider data={data} SliderItem={PartnerItem} settings={settings} variant="black" />
      </div>
    </>
  );
};

export default PartnersSection;
