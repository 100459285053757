/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import './App.scss';
import {
  AboutCompanyPage,
  AboutCompanyThanks,
  AboutCompanyVacancies,
  BlogPage,
  CabinetPage,
  CatalogPage,
  ContactsPage,
  CooperationPage,
  FooterSection,
  HeaderSection,
  LaboratoryItemPage,
  LaboratoryPage,
  LikesPage,
  LoginModal,
  MainPage,
  NewsPage,
  ProductPage,
  ProjectPage,
  ProjectsPage,
  RegistrationModal,
  ServerMessages,
  SingleBlogPage,
  SingleCatalogPage,
  SingleNewsPage,
  SubCategoryPage,
  SearchPage,
  CoparisonPage,
  OrderPage,
  RestorePasswordModal,
  ResetPasswordPage,
  OrdersListPage,
  Documentation,
  PromotionPage,
  NotFoundPage,
} from './components/pages';
import { ModalWindow, UpButton } from './components/universal_components';
import { ProtectedRoute, classnames } from './utils';

const App = () => {
  const location = useLocation();
  const [modalState, setModalState] = useState();
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [calculatedHeight, setCalculatedHeight] = useState();
  const [calculatedWidth, setCalculatedWidth] = useState();
  const regModalRef = useRef();
  const loginModalRef = useRef();
  const restorePasswordModalRef = useRef();

  useEffect(() => {
    if (regModalRef.current || loginModalRef.current || restorePasswordModalRef.current) {
      setCalculatedHeight(
        window
          .getComputedStyle(regModalRef.current || loginModalRef.current || restorePasswordModalRef.current, null)
          .getPropertyValue('height'),
      );

      setCalculatedWidth(
        window
          .getComputedStyle(regModalRef.current || loginModalRef.current || restorePasswordModalRef.current, null)
          .getPropertyValue('width'),
      );
    }
  }, [modalState]);

  useEffect(() => {
    localStorage.setItem('previousPage', localStorage.getItem('currentPage'));
    localStorage.setItem('currentPage', JSON.stringify(location));
  }, [location]);

  return (
    <>
      <HeaderSection setModalRegisterState={setModalState} />
      <main
        style={{ paddingTop: location.pathname === '/' ? 0 : 160 }}
        className={location.pathname.indexOf('/catalog') >= 0 ? 'whiteBackground' : ''}
      >
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/promotion" element={<PromotionPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/:slug" element={<ProjectPage />} />
          <Route path="/laboratory" element={<LaboratoryPage />} />
          <Route path="/laboratory/:slug" element={<LaboratoryItemPage />} />
          <Route path="/about" element={<AboutCompanyPage />} />
          <Route path="/about/vacancies" element={<AboutCompanyVacancies />} />
          <Route path="/about/thanks" element={<AboutCompanyThanks />} />
          <Route path="/about/cooperation" element={<CooperationPage />} />
          <Route path="/news/" element={<NewsPage />} />
          <Route path="/news/:slug" element={<SingleNewsPage />} />
          <Route path="/articles" element={<BlogPage />} />
          <Route path="/articles/:slug" element={<SingleBlogPage />} />
          <Route path="/catalog" element={<CatalogPage />} />
          <Route path="/catalog/subcategory/:slug" element={<SubCategoryPage />} />
          <Route path="/catalog/products/:slug" element={<ProductPage />} />
          <Route path="/catalog/:slug" element={<SingleCatalogPage />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/documentation" element={<Documentation />} />

          <Route
            path="/cabinet"
            element={(
              <ProtectedRoute>
                <CabinetPage />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/cabinet/likes"
            element={(
              <ProtectedRoute>
                <LikesPage />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/cabinet/coparisons"
            element={(
              <ProtectedRoute>
                <CoparisonPage />
              </ProtectedRoute>
            )}
          />
          <Route
            path="/cabinet/orders"
            element={(
              <ProtectedRoute>
                <OrdersListPage />
              </ProtectedRoute>
            )}
          />
          <Route path="/cabinet/order" element={<OrderPage />} />

          <Route
            path="*"
            element={<NotFoundPage />}
          />
        </Routes>
      </main>
      {modalState && (
        <ModalWindow setElementWindow={setModalState}>
          <div className={styles.login} onClick={(e) => e.stopPropagation()}>
            <button
              className={classnames([styles.button, [styles.active, modalState === 'reg']])}
              type="button"
              onClick={() => setModalState('reg')}
            >
              Зарегистрироваться
            </button>
            <button
              className={classnames([styles.button, [styles.active, modalState === 'login']])}
              type="button"
              onClick={() => setModalState('login')}
            >
              Войти
            </button>
            <button
              className={classnames([styles.button, [styles.active, modalState === 'restore']])}
              type="button"
              onClick={() => setModalState('restore')}
            >
              Забыли пароль?
            </button>

            <div className={styles.content} style={{ height: calculatedHeight, width: calculatedWidth }}>
              {modalState === 'reg' ? (
                <div ref={regModalRef} style={{ width: 'min-content' }}>
                  <RegistrationModal setModalState={setModalState} setRegistrationSuccess={setRegistrationSuccess} />
                </div>
              ) : modalState === 'login' ? (
                <div ref={loginModalRef} style={{ width: 'min-content' }}>
                  <LoginModal setModalState={setModalState} />
                </div>
              ) : (
                <div ref={restorePasswordModalRef} style={{ width: 'min-content' }}>
                  <RestorePasswordModal width={calculatedWidth} height={calculatedHeight} />
                </div>
              )}
            </div>
          </div>
        </ModalWindow>
      )}

      {registrationSuccess && (
        <ModalWindow setElementWindow={setRegistrationSuccess}>
          <div className={styles.successRegister}>
            <p>Аккаунт создан!</p>
            <p>На ваш email выслано письмо для подтверждения регистрации</p>
          </div>
        </ModalWindow>
      )}

      <UpButton />
      <FooterSection />
      <ServerMessages />
    </>
  );
};

export default App;
