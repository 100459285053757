import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const documentsAPI = createApi({
  reducerPath: 'documentation',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllDocuments: build.query({
      query: () => ({
        url: '/documents/documents/',
      }),
    }),
  }),
});

export default documentsAPI;
