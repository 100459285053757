import React, { useRef, useState } from 'react';
import arrow from 'assets/images/vacancies/arrow.svg';
import styles from './AboutCompanyVacanciesItem.module.scss';

const AboutCompanyVacanciesItem = ({ title, payday, description }) => {
  const [isActive, setIsActive] = useState(false);
  const descriptionRef = useRef(null);
  const descriptionHeight = descriptionRef.current?.scrollHeight;
  return (
    <div>
      <button type="button" className={styles.wrapper} onClick={() => setIsActive(!isActive)}>
        <h2 className="visually-hidden">{title}</h2>
        <p className={styles.title}>{title}</p>
        <div itemProp="baseSalary" className={styles.payday}>{payday}</div>
        <div className={`${styles.arrow} ${isActive && styles.active}`}>
          <img src={arrow} alt="Раскрыть" />
        </div>
      </button>
      <div itemProp="employerOverview" ref={descriptionRef} style={{ height: isActive ? descriptionHeight : 0 }} className={styles.description}>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default AboutCompanyVacanciesItem;
