import React from 'react';
import { Link } from 'react-router-dom';
import { classnames } from 'utils';
import styles from './BreadCrumbs.module.scss';

const BreadCrumbs = ({ items, variant = '' }) => (
  <nav>
    <ol itemScope itemType="https://schema.org/BreadcrumbList" className={styles.list}>

      {items.map((item, index) => (
        <li className={styles.inner} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem" key={item.id || index}>
          <Link
            itemProp="item"
            itemType="https://schema.org/WebPage"
            to={item.link}
            className={classnames([styles.item, [styles.blue, variant === 'blue']])}

          >
            <span itemProp="name">
              {item.title}
            </span>
            {index !== items.length - 1 ? <span className={styles.separator}>{' > '}</span> : null}

          </Link>
          <meta itemProp="position" content={index} />
        </li>
      ))}
    </ol>

  </nav>
);

export default BreadCrumbs;
