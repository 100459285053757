/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import phone from 'assets/images/header/burgermenu/phone.svg';
import { catalogAPI } from 'stateManager/services';
import { Loading, ModalWindow } from 'components/universal_components';
import { classnames } from 'utils';
import { useWindowSize } from 'hooks';
import styles from './HeaderBurgerMenu.module.scss';
import HeaderIcons from '../HeaderIcons/HeaderIcons';

const HeaderBurgerMenu = ({
  isActive,
  setIsActive,
}) => {
  const [windowWidth] = useWindowSize();

  const { data: categories, isFetching: isFetchingCategories } = catalogAPI.useFetchAllCategoriesQuery(20);

  const catalogCategoriesList = useRef();
  const infoList = useRef();
  const [catalogButtonStateOpen, setCatalogButtonStateOpen] = useState(false);
  const [infoButtonStateOpen, setInfoButtonStateOpen] = useState(false);

  useEffect(() => {
    setCatalogButtonStateOpen(false);
    setInfoButtonStateOpen(false);
  }, [isActive]);

  const getCatalogHeight = (h) => {
    if (catalogButtonStateOpen) {
      return h;
    }
    return 0;
  };
  const getInfoHeight = (h) => {
    if (infoButtonStateOpen) {
      return h;
    }
    return 0;
  };

  useEffect(() => {
    document.body.style.overflow = isActive ? 'hidden' : 'visible';
  }, [isActive]);

  if (isFetchingCategories) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  return (
    (isActive && (
    <ModalWindow setElementWindow={setIsActive}>
      <div
        className={classnames([styles.wrapper])}
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="visually-hidden">Меню</h2>
        {windowWidth < 768 ? <HeaderIcons inBurgerMenu /> : null}

        <div className={styles.catalog}>
          <button
            type="button"
            className={classnames([
              styles.blockTitle,
              styles.button,
              [styles.open, catalogButtonStateOpen]])}
            onClick={() => {
              setCatalogButtonStateOpen(!catalogButtonStateOpen);
            }}
          >
            <h3>Каталог</h3>
          </button>

          <div
            className={styles.categories}
            style={
              windowWidth < 768
                ? { height: `${getCatalogHeight(catalogCategoriesList.current?.getBoundingClientRect().height)}px` }
                : { height: 'auto' }
            }
          >
            <div className={styles.categories_list} ref={catalogCategoriesList}>
              {categories.map((category) => (
                <Link to={`/catalog/${category.slug}`} className={styles.category} key={category.id}>
                  {category.title}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.info}>
          <button
            type="button"
            className={classnames([styles.blockTitle, styles.button, [styles.open, infoButtonStateOpen]])}
            onClick={() => {
              setInfoButtonStateOpen(!infoButtonStateOpen);
            }}
          >
            <h3>Компания</h3>
          </button>

          <div
            className={styles.infoWrapper}
            style={windowWidth < 768
              ? { height: `${getInfoHeight(infoList.current?.getBoundingClientRect().height)}px` }
              : { height: 'auto' }}
          >
            <div className={styles.infoList} ref={infoList}>
              <NavLink to="/about" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
                О компании
              </NavLink>
              <NavLink to="/about/cooperation" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
                Сотрудничество
              </NavLink>
              <NavLink to="/about/vacancies" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
                Вакансии
              </NavLink>
              <NavLink to="/news" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
                Новости
              </NavLink>
              <NavLink to="/about/thanks" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
                Благодарности
              </NavLink>
              <NavLink to="/documentation" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
                Документация
              </NavLink>
            </div>
          </div>
        </div>

        <div className={styles.links}>
          <NavLink to="/laboratory" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
            Лаборатория
          </NavLink>
          <NavLink to="/projects" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
            Проекты
          </NavLink>
          <NavLink to="/articles" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
            Блог
          </NavLink>
          <NavLink to="/contacts" className={({ itemIsActive }) => (itemIsActive ? styles.pressed : '')}>
            Контакты
          </NavLink>
        </div>

        <div className={styles.contacts}>
          <div className={styles.phoneIcon}>
            <img src={phone} alt="Телефон" />
          </div>
          <a
            href="tel:8-800-600-55-69"
            className={styles.phone}
          >
            8-800-600-55-69
          </a>
          <a href="mailto:info@fosgroup.ru" className={styles.email}>
            info@fosgroup.ru
          </a>
        </div>
      </div>
    </ModalWindow>
    )
    )
  );
};

export default HeaderBurgerMenu;
