const dateFormatter = (date) => {
  if (!date) return '';
  const format = date.split('-');
  format[1] -= 1;
  const event = new Date(Date.UTC(...format));
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return event.toLocaleDateString('ru-RU', options);
};

export default dateFormatter;
