import React from 'react';
import styles from './AboutCompanyThanksItem.module.scss';

const AboutCompanyThanksItem = ({ title, image, isActive, activate }) => (
  <button type="button" className={`${styles.wrapper} ${isActive && styles.active}`} onClick={activate}>
    <h2 className="visually-hidden">{title}</h2>
    <div className={styles.image}>
      <img src={image} alt={title} />
    </div>
    <div className={styles.title}>{title}</div>
  </button>
);

export default AboutCompanyThanksItem;
