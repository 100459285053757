import React from 'react';
import styles from './AboutSection.module.scss';

const AboutSection = () => (
  <div className={styles.wrapper}>
    <div className={styles.title}>О нас</div>
    <div className={styles.text}>
      <div className={styles.text__title}>
        На сегодняшний день «ФОС ГРУПП» является лидером среди производителей светодиодных светильников в
        Красноярском крае.
      </div>
      <div className={styles.text__description}>
        <p>
          Наши преимущества — мощная производственная база и высокий уровень экспертизы на всех этапах производства.
        </p>
        <p>
          Основные линейки выпускаемой продукции — уличное освещение, промышленное освещение, светильники для
          эксплуатации в средах с высокой температурой, светильники для химически агрессивных сред, взрывозащищенные
          светильники, светильники для РЖД, коммерческое освещение, архитектурное освещение.
        </p>
        <p>
          Миссия компании — улучшать условия труда и жизни людей через внедрение инноваций и сервиса в сфере надежного и
          качественного света.
        </p>
      </div>
    </div>
  </div>
);

export default AboutSection;
