import React from 'react';
import { Link } from 'react-router-dom';
import { classnames } from 'utils';

import styles from './BlueButton.module.scss';

const BlueButton = ({ text, disabled, onClick = () => {}, onKeyDown = () => {}, to = '', variant = '', state = '', target = '' }) => {
  if (to) {
    return (
      <Link to={to} className={classnames([styles.button, styles[variant], styles[state]])} target={target}>
        {text}
      </Link>
    );
  }
  return (
    <button
      type="button"
      disabled={disabled}
      className={classnames([styles.button, styles[variant], styles[state]])}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {text}
    </button>
  );
};

export default BlueButton;
