import React from 'react';
import styles from './HistoryItem.module.scss';

const HistoryItem = ({ year, text }) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>
      <div className={styles.year__number}>{year}<span className={styles.year__text}>год</span></div>
    </div>
    <div className={styles.text}>{text}</div>
  </div>
);

export default HistoryItem;
