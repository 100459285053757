import React from 'react';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
import vacancyAPI from 'stateManager/services/VacancyService';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import styles from './AboutCompanyVacancies.module.scss';
import AboutCompanyVacanciesItem from './AboutCompanyVacanciesItem/AboutCompanyVacanciesItem';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Компания',
    link: '/about',
  },
  {
    title: 'Вакансии',
    link: '/about/vacancies',
  },
];

const AboutCompanyVacancies = () => {
  // useTitle('Вакансии');
  const { data: vacancies, isFetching } = vacancyAPI.useFetchAllVacanciesQuery(5);

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  return (
    <div itemScope itemType="https://schema.org/JobPosting" className={styles.wrapper}>
      <HelmetHandle
        title="Вакансии"
        metaTitle="Вакансии"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <h1 className={styles.title}>Вакансии</h1>
        <div className={styles.items}>
          {vacancies.map((item) => (
            <div className={styles.item} key={item.id}>
              <AboutCompanyVacanciesItem {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutCompanyVacancies;
