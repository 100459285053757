import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const catalogAPI = createApi({
  reducerPath: 'catalog',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    } }),
  tagTypes: ['Likes', 'Coparisons'],
  endpoints: (build) => ({
    fetchAllCategories: build.query({
      query: (limit = 5) => ({
        url: '/catalog/categories/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results,
    }),
    fetchSingleCategory: build.query({
      query: (slug) => ({ url: `/catalog/categories/${slug}/` }),
    }),
    fetchSingleSubCategory: build.query({
      query: (slug) => ({ url: `/catalog/sub_categories/${slug}/` }),
    }),
    fetchAllProducts: build.query({
      query: (args = {}) => ({
        url: '/catalog/products/',
        params: args,
      }),
      // transformResponse: (response) => response.results,
    }),
    fetchSingleProduct: build.query({
      query: (slug) => ({ url: `/catalog/products/${slug}/` }),
    }),
    fetchPopularProducts: build.query({
      query: (limit = 5) => ({
        url: '/catalog/products/',
        params: {
          limit,
          is_popular: true,
        },
      }),
      transformResponse: (response) => response.results,
    }),
    fetchAllOperatingTemperatures: build.query({
      query: (limit = 5) => ({
        url: '/catalog/operating-temperatures/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results,
    }),
    fetchAllFasteningTypes: build.query({
      query: (limit = 5) => ({
        url: '/catalog/fastening-types/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results,
    }),
    fetchAllLikes: build.query({
      query: (limit = 100) => ({
        url: '/catalog/likes/',
        params: {
          limit,
        },
      }),
      providesTags: ['Likes'],
      transformResponse: (response) => response.results.map((item) => item.product),
    }),
    createLike: build.mutation({
      query: (productID) => ({
        url: '/catalog/likes/',
        method: 'POST',
        body: { product: productID },
      }),
      invalidatesTags: ['Likes'],
    }),
    deleteAllLikes: build.mutation({
      query: () => ({
        url: '/catalog/delete-all-likes/',
        method: 'DELETE',
      }),
      invalidatesTags: ['Likes'],
    }),
    deleteLike: build.mutation({
      query: (productID) => ({
        url: `/catalog/likes/${productID}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Likes'],
    }),
    fetchAllComparisons: build.query({
      query: (limit = 100) => ({
        url: '/catalog/coparisons/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results.map((item) => item.product),
      providesTags: ['Coparisons'],
    }),
    addToCoparison: build.mutation({
      query: (productID) => ({
        url: '/catalog/coparisons/',
        method: 'POST',
        body: { product: productID },
      }),
      invalidatesTags: ['Coparisons'],
    }),
    deleteFromCoparison: build.mutation({
      query: (productID) => ({
        url: `/catalog/coparisons/${productID}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coparisons'],
    }),
    deleteAllCoparisons: build.mutation({
      query: () => ({
        url: '/catalog/delete-all-coparisons/',
        method: 'DELETE',
      }),
      invalidatesTags: ['Coparisons'],
    }),
    fetchAllInstances: build.query({
      query: (params) => ({
        url: '/catalog/instances/',
        params,
      }),
      transformResponse: (response) => response.results,
      providesTags: ['Coparisons'],
    }),
  }),
});
export default catalogAPI;
