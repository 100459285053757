import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const contactsAPI = createApi({
  reducerPath: 'contacts',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllFilials: build.query({
      query: (args) => ({
        url: '/contacts/filials/',
        params: args,
      }),
      transformResponse: (response) => response.results,
    }),
  }),
});
export default contactsAPI;
