import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { BlueButton, BreadCrumbs, CustomSlider, HelmetHandle, Loading, ModalWindow, NextArrow, PrevArrow } from 'components/universal_components';

import { ReactComponent as Logo1 } from 'assets/images/cooperation/coop_img_1.svg';
import { ReactComponent as Logo2 } from 'assets/images/cooperation/coop_img_2.svg';
import { ReactComponent as Logo3 } from 'assets/images/cooperation/coop_img_3.svg';
import { ReactComponent as Icon } from 'assets/images/icon_download.svg';
import { classnames } from 'utils';

import { BASE_URL_MEDIA, dealersFormAPI } from 'stateManager/services';
import { useWindowSize } from 'hooks';
import metatags from 'metatags';
import styles from './Cooperation.module.scss';
import CooperationSlider from './CooperationSlider';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Компания',
    link: '/about',
  },
  {
    title: 'Сотрудничество',
    link: '/about/cooperation',
  },
];

const Cooperation = () => {
  const { data, isFetching } = dealersFormAPI.useFetchAllDealersQuery();
  const { data: docs, isFetching: isFetchingDocs } = dealersFormAPI.useFetchAllDealersDocsQuery();
  const [docsButtonStateOpen, setDocsButtonStateOpen] = useState(false);

  const [activeImage, setActiveImage] = useState();
  const documentsWrapper = useRef();

  // useTitle('Сотрудничество');
  const [width] = useWindowSize();
  const settings = {
    slidesToShow: width > 767 ? 3 : 1,
    slidesToScroll: 1,
    infinite: false,
  };

  const getDocumentsHeight = (h) => {
    if (docsButtonStateOpen) {
      return h;
    }
    return 0;
  };

  if (isFetching || isFetchingDocs) {
    return <Loading />;
  }

  const activeImageData = data.find((item) => item.id === activeImage);

  const setNextItem = (event) => {
    event.stopPropagation();
    const activeIndex = data.indexOf(activeImageData);
    setActiveImage(data[activeIndex < data.length - 1 ? activeIndex + 1 : 0].id);
  };
  const setPrevItem = (event) => {
    event.stopPropagation();
    const activeIndex = data.indexOf(activeImageData);
    setActiveImage(data[activeIndex > 0 ? activeIndex - 1 : data.length - 1].id);
  };
  return (
    <div className="page_container">
      <HelmetHandle
        title="Сотрудничество"
        metaTitle="Сотрудничество"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />

      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>

      <h1 className="visually-hidden">Сотрудничество</h1>

      <div className={styles.wrapper}>
        <section className={styles.sectionCooperation}>
          <h2 className={styles.title}>Сотрудничество</h2>

          <p className={styles.subtitle}>Нас выбирают, потому что:</p>

          <div className={styles.sectionWrapper}>
            <div className={styles.advantages}>
              <div className={styles.advantagesImage}>
                <Logo1 />
              </div>
              <h3 className={styles.advantagesTitle}>Преимущества работы с нами</h3>

              <ul className={styles.advantagesList}>
                <li>Скидки на продукцию</li>
                <li>Маркетинговая и техническая поддержка</li>
                <li>Бесплатная доставка до Вашего клиента</li>
              </ul>
            </div>

            <div className={styles.advantages}>
              <div className={styles.advantagesImage}>
                <Logo2 />
              </div>
              <h3 className={styles.advantagesTitle}>Став дилером Вы</h3>

              <ul className={styles.advantagesList}>
                <li>Найдете новых клиентов и партнеров</li>
                <li>Станете более конкурентоспособными</li>
                <li>Расширите ассортимент продукции</li>
              </ul>
            </div>

            <div className={styles.advantages}>
              <div className={styles.advantagesImage}>
                <Logo3 />
              </div>
              <h3 className={styles.advantagesTitle}>
                Мы обеспечим Вас всем необходимым для&nbsp;увеличения ваших продаж
              </h3>

              <ul className={styles.advantagesList}>
                <li>Образцами продукции для выставочного стенда</li>
                <li>Предоставим Вам печатные и электронные каталоги продукции</li>
                <li>Обеспечим техническую поддержку (бесплатные проекты освещения)</li>
              </ul>
            </div>
          </div>
        </section>

        <section className={styles.sectionDillers}>
          <h2 className={styles.title}>Дилеры</h2>

          <ul className={styles.slider}>
            <CustomSlider
              data={data.map((item) => ({ ...item, setActiveImage }))}
              SliderItem={CooperationSlider}
              settings={settings}
              variant="black"
            />
          </ul>

          <div className={styles.toStayPartner}>
            <p>Чтобы стать нашим парнером</p>

            <BlueButton to={`${BASE_URL_MEDIA}анкета на сотрудничество.xls`} text="Анкета для заполнения" target="_blank" />

            <BlueButton to={`${BASE_URL_MEDIA}Презентация%20FOS.pdf`} text="Презентация" target="_blank" />

            <span>
              Для оформления заявки на дилерское партнёрство, заполни анкету и&nbsp;отправь нам E-mail:
              info@fosgroup.ru
            </span>
          </div>
          <section className={classnames([styles.inner, styles.documents, [styles.open, docsButtonStateOpen]])}>
            <h2 className="visually-hidden">Документы</h2>
            <button
              className={styles.innerTitle}
              type="button"
              onClick={() => {
                setDocsButtonStateOpen(!docsButtonStateOpen);
              }}
            >Документы
            </button>
            <div
              className={styles.categoriesContainer}
              style={{ height: `${getDocumentsHeight(documentsWrapper.current?.getBoundingClientRect().height)}px` }}
            >
              <ul className={styles.categoriesWrapper} ref={documentsWrapper}>
                {docs.map((doc) => (
                  <li className={styles.certItem} key={doc.id}>
                    <Link to={doc.file} className={styles.certFile} download target="_blank" rel="noreferer">
                      <Icon className={styles.icon} />
                      <span className={styles.certTitle}>{doc.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </section>

      </div>
      <div className={styles.modal}>
        {activeImage && (
        <ModalWindow elementWindow={activeImage} setElementWindow={setActiveImage}>
          <div className={styles.window_photo}>
            <button type="button" onClick={setPrevItem} className={styles.prev}><div className="visually-hidden">Предыдущая</div>
              <PrevArrow />
            </button>
            <img src={activeImageData.image} alt={activeImageData.title} />
            <p className={styles.window_info}>{activeImageData.title}</p>
            <button type="button" onClick={setNextItem} className={styles.next}>
              <div className="visually-hidden">
                Следующая
              </div>
              <NextArrow />
            </button>

          </div>
        </ModalWindow>
        )}
      </div>
    </div>
  );
};

export default Cooperation;
