import React from 'react';
import { BlueButton } from 'components/universal_components';
import styles from './LastNewsSection.module.scss';

const LastNewsItem = ({ title, image, slug }) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.readMore}>
        <BlueButton text="Подробнее" variant="news" to={`/news/${slug}`} />
      </div>
    </div>
  </div>
);

export default LastNewsItem;
