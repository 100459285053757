import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const subscripitonsAPI = createApi({
  reducerPath: 'subscriptions',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    createSubscription: build.mutation({
      query: (data) => ({
        url: '/subscriptions/subscriptions/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
export default subscripitonsAPI;
