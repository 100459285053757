import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LaboratoryItem.module.scss';

const LaboratoryItem = ({ slug, title, image }) => (
  <Link to={`/laboratory/${slug}`} className={styles.wrapper}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.image}>
      <img src={image} alt={title} />
    </div>
  </Link>
);

export default LaboratoryItem;
