import React from 'react';
import { useWindowSize } from 'hooks';
import { CustomSlider, Loading } from 'components/universal_components';
import { teamAPI } from 'stateManager/services';
import styles from './TeamSection.module.scss';
import TeamSectionItem from './TeamSectionItem';

const TeamSection = () => {
  const [width] = useWindowSize();

  const { data, isFetching } = teamAPI.useFetchAllMembersQuery();

  if (isFetching) {
    return <Loading />;
  }

  if (data.length === 0) {
    return null;
  }

  const settings = {
    slidesToShow: width > 1365 ? 4 : 2,
    slidesToScroll: 1,
    infinite: false,
  };

  return (
    <section>
      <div className={styles.separator} />
      <h2 className={styles.title}>Наша команда</h2>
      <div className={styles.items} id="team">
        <CustomSlider settings={settings} data={data} SliderItem={TeamSectionItem} variant="black" />
      </div>
    </section>
  );
};

export default TeamSection;
