import React from 'react';
import { ReactComponent as LogoDesktop } from 'assets/images/logo_black.svg';
import { ReactComponent as LogoLaptop } from 'assets/images/logo_black_laptop.svg';
import { useWindowSize } from 'hooks';

const Logo = () => {
  const [windowWidth] = useWindowSize();

  return (
    windowWidth < 1366 ? <LogoLaptop /> : <LogoDesktop />
  );
};

export default Logo;
