import React, { useEffect, useState } from 'react';
import proceedGif from 'assets/images/proceed.gif';
import { subscripitonsAPI } from 'stateManager/services';
import { emailValidator, handleServerError, notEmptyValidator, setValidationErrors } from 'utils';
import { BlueButton, CustomForm, ModalWindow } from 'components/universal_components';
import styles from './FooterButtons.module.scss';

const FooterButtons = () => {
  const [email, setEmail] = useState('');
  const [emailValidationErrors, setEmailValidationErrors] = useState([]);

  const fields = [
    {
      name: 'email',
      inputType: 'text',
      placeholder: 'Ваш e-mail',
      required: true,
      value: email,
      onChange: (value) => setEmail(value),
      validators: [notEmptyValidator, emailValidator],
      validationErrors: emailValidationErrors,
      setValidationErrors: (value) => {
        setEmailValidationErrors((state) => setValidationErrors(state, value));
      },
    },
  ];

  const [sendForm, { isLoading, isSuccess, isError: isFetchError, error }] =
    subscripitonsAPI.useCreateSubscriptionMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  const sendData = () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      sendForm(dataToSend);
    }
  };

  // анимация ожидания
  if (isLoading) {
    return <img src={proceedGif} alt="" style={{ width: `${60}px`, height: `${60}px` }} />;
  }

  if (isSuccess) {
    return (
      <ModalWindow elementWindow={{}} setElementWindow={() => {}}>
        <div className={styles.success_sending}>
          <p>Заявка принята!</p>
          <p>Теперь вы будете получать полезную информацию о нас</p>
        </div>
      </ModalWindow>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.input}>
        <CustomForm fields={fields} styles={styles} />
      </div>
      <div className={styles.send}>
        <BlueButton text="Подписаться на новости" onClick={sendData} />
      </div>

    </div>
  );
};

export default FooterButtons;
