/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { catalogAPI } from 'stateManager/services';
import { classnames } from 'utils';
import { BlueButton, BreadCrumbs, HelmetHandle, Loading, ModalWindow } from 'components/universal_components';
// import { useTitle } from 'hooks';
// import metatags from 'metatags';
import styles from './ProductPage.module.scss';
import TableSection from './TableSection/TableSection';
import WatchedSection from './WatchedSection/WatchedSection';
import PopularSection from './PopularSection/PopularSection';
import { ReactComponent as Whatsapp } from '../../../../assets/images/socials/whatsapp_black.svg';
import AskDiscount from './AskDiscount/AskDiscount';
import Price from './Price/Price';
import CustomPrice from './Price/CustomPrice';

const ProductPage = () => {
  const params = useParams();
  const { pathname } = useLocation();
  const [elementWindow, setElementWindow] = useState();
  const [selectedLedBrand, setSelectedLedBrand] = useState({});

  const { data: product, isFetching } = catalogAPI.useFetchSingleProductQuery(params.slug);
  useEffect(() => {
    if (product) {
      setSelectedLedBrand(product.product_custom_price[0]);
      localStorage.setItem('watchedProducts', JSON.stringify([product.id, ...JSON.parse(localStorage.getItem('watchedProducts') || '[]').filter((pr) => pr !== product.id)]));
    }
  }, [product]);

  const {
    data: subCategory,
  } = catalogAPI.useFetchSingleSubCategoryQuery(product?.subcategory.slug);
  const images = [{ id: 0, image: product?.image }, ...(product?.additional_images ? product.additional_images : [])];

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!product) {
    return <>not found</>;
  }

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Каталог',
      link: '/catalog',
    },
    {
      title: subCategory?.category.title,
      link: `/catalog/${subCategory?.category.slug}`,
    },
    {
      title: product.subcategory.title,
      link: `/catalog/subcategory/${product.subcategory.slug}`,
    },
    {
      title: product.title,
      link: `/catalog/products/${product?.slug}`,
    },
  ];

  return (
    <div className="page_container">
      <HelmetHandle
        title={product.title}
        metaTitle={product.meta_title}
        description={product.subcategory.meta_description}
        metaDescription={product.meta_description}
        image={product.image}
        product
      />
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} variant="blue" />
      </div>

      <div itemScope itemType="http://schema.org/Product" className={styles.wrapper}>
        <meta itemProp="name" content={`${product.title}`} />
        <div className={styles.slider}>
          {images.length > 1 ? (
            <>
              <Slider
                className={styles.singleSlider}
                settings={settings}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
              >
                {images.map((image) => (
                  <img itemProp="image" src={image.image} alt="#" key={image.id} />
                ))}
              </Slider>

              <Slider
                className={styles.multipleSlider}
                slidesToShow={3}
                focusOnSelect="true"
                swipeToSlide="true"
                asNavFor={nav1}
                centerMode="true"
                centerPadding="20px"
                infinite={false}
                ref={(slider2) => setNav2(slider2)}
              >
                {images.map((image) => (
                  <img itemProp="image" src={image.image} alt="#" key={image.id} />
                ))}
              </Slider>
            </>
          ) : (
            <img itemProp="image" src={product.image} alt={product.title} />
          )}
        </div>

        <div itemProp="offers" itemScope itemType="http://schema.org/Offer" className={styles.info}>
          <h1 className={styles.title}>{product.title}</h1>
          <dl itemProp="description" className={styles.info_list}>
            <h2 className="visually-hidden">Общие характеристики</h2>
            <div className={styles.info_item}>
              <dt>Номинальная мощность</dt>
              <dd>{product.power}Вт</dd>
            </div>

            <div className={styles.info_item}>
              <dt>Тип крепления</dt>
              <dd>{product.fastening_type.title}</dd>
            </div>

            <div className={styles.info_item}>
              <dt>Габаритные размеры</dt>
              <dd>{product.sizes}</dd>
            </div>

            <div className={styles.info_item}>
              <dt>Вес</dt>
              <dd>{+product.weight}кг</dd>
            </div>

            <div className={styles.info_item}>
              <dt>Степень защиты</dt>
              <dd>{product.protection}</dd>
            </div>

            <div className={styles.info_item}>
              <dt>Температура эксплуатации</dt>
              <dd>
                от {product.temperature.start} до {product.temperature.end}°C
              </dd>
            </div>
            {
              product.is_custom_price && (
                <div className={classnames([styles.info_item, styles.led_brand])}>
                  <dt>Марка светодиода</dt>
                  <dd className={styles.led_brand}>
                    <select onChange={(e) => setSelectedLedBrand(product.product_custom_price[e.target.value])}>
                      {product.product_custom_price.map((price, index) => <option key={price.id} value={index}>{price.led_brand}</option>)}
                    </select>
                  </dd>
                </div>
              )
            }
          </dl>
          {product.is_custom_price ? <CustomPrice product={selectedLedBrand} /> : <Price product={product} />}
          <div className={styles.feedback}>
            {/* WhatsApp */}
            <div className={styles.whatsapp}>
              <Link to={`https://wa.me/79676092500?text=Здравствуйте, интересует товар https://fosgroup.ru${pathname}`} target="_blank">
                <Whatsapp />
                <span>Напишите нам</span>
              </Link>
            </div>

            {/* Форма обратной связи */}
            <div className={styles.form}>
              <BlueButton
                text="Узнать размер скидки"
                onClick={() => setElementWindow(true)}
              />

              {elementWindow && (
              <ModalWindow setElementWindow={setElementWindow}>
                <AskDiscount ProductLink={`https://fosgroup.ru${pathname}`} />
              </ModalWindow>
              )}
            </div>
          </div>

        </div>
      </div>
      <section className={styles.table}>
        <h2 className="visually-hidden">Таблица характеристик</h2>
        <TableSection items={product.instances} title={product.title} />
      </section>

      <div className={styles.popular}>
        <PopularSection currentProductId={product.id} />
      </div>

      <div className={styles.watched}>
        <WatchedSection product={product} />
      </div>
      <div />
    </div>
  );
};

export default ProductPage;
