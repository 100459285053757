/* eslint-disable no-nested-ternary */
import React from 'react';
import { CustomSlider, Loading } from 'components/universal_components';
import { catalogAPI } from 'stateManager/services';
import { useWindowSize } from 'hooks';
import styles from './PopularSection.module.scss';
import CatalogPageProduct from '../../ProductsSection/CatalogPageProduct/CatalogPageProduct';

const SliderItem = (props) => (
  <div className={styles.product}><CatalogPageProduct {...props} /></div>
);

const PopularSection = ({ currentProductId }) => {
  const { data: products, isFetching } = catalogAPI.useFetchPopularProductsQuery();
  const [width] = useWindowSize();

  if (isFetching) {
    return <Loading />;
  }

  if (!products.length) {
    return null;
  }

  const settings = {
    slidesToShow: width > 1365 ? 4 : width > 767 ? 2 : 1,
    slidesToScroll: 1,
    infinite: !(width > 1365),
  };

  return (
    <section className={styles.wrapper}>
      <h2 className="visually-hidden">Популярные товары</h2>
      <p className={styles.title}>Популярные</p>
      <div className={styles.products}>
        {products.count > 4 ? (
          <CustomSlider
            settings={settings}
            SliderItem={SliderItem}
            data={products.filter((pr) => pr.id !== currentProductId).slice(0, 4)}
            variant="white"
          />
        ) : (
          <div className={styles.inner}>
            {products.map((el) => (
              <CatalogPageProduct {...el} key={el.id} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default PopularSection;
