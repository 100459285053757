import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

export const serverMessageSlice = createSlice({
  name: 'serverMessage',
  initialState,
  reducers: {
    addMessage(state, action) {
      state.messages.push(action.payload);
    },
    removeMessage(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.messages = state.messages.filter((message) => message.id !== action.payload);
    },
  },
  extraReducers: {},
});

export default serverMessageSlice.reducer;
