import React from 'react';
import { Link } from 'react-router-dom';
import { classnames } from 'utils';
import styles from './EmptyButton.module.scss';

const EmptyButton = ({ text, onClick, to, variant = 'white' }) => {
  if (to) {
    return (
      <Link className={classnames([styles.button, [styles.black, variant === 'black'], [styles.bigPadding, variant === 'bigPadding']])} to={to}>
        {text}
      </Link>
    );
  }
  return (
    <button
      onClick={onClick}
      className={classnames([styles.button, [styles.black, variant === 'black'], [styles.bigPadding, variant === 'bigPadding'], [styles.coparison, variant === 'coparison']])}
      type="button"
    >
      {text}
    </button>
  );
};

export default EmptyButton;
