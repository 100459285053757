import React from 'react';
import styles from './TeamSection.module.scss';

const TeamSectionItem = ({ id, image, fio, position }) => (
  <div className={styles.item} key={id}>
    <div className={styles.itemPhoto}>
      <img src={image} alt={fio} />
    </div>
    <div className={styles.itemFio}>{fio}</div>
    <div className={styles.itemPosition}>{position}</div>
  </div>
);

export default TeamSectionItem;
