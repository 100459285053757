/* eslint-disable array-callback-return */
import React from 'react';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
import { projectsAPI } from 'stateManager/services';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import ProjectsCategory from './ProjectsCategory/ProjectsCategory';
import styles from './ProjectsPage.module.scss';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Проекты',
    link: '/projects',
  },
];

const ProjectsPage = () => {
  const { data: categories, isFetching } = projectsAPI.useFetchAllProjectsCategoriesQuery(20);
  // useTitle('Проекты');

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <HelmetHandle
        title="Проекты"
        metaTitle="Проекты"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <h1 className="visually-hidden">Проекты</h1>

      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <div className={styles.categories}>
          {categories.map((category) => (
            (category.projects.length > 0 && (
              <section className={styles.category} key={category.id}>
                <ProjectsCategory {...category} />
              </section>
            ))
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectsPage;
