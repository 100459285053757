import React from 'react';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
import { authAPI, blogAPI } from 'stateManager/services';
// import { useTitle } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import metatags from 'metatags';
import styles from './BlogPage.module.scss';
import BlogTabs from './BlogTabs/BlogTabs';
import BlogArticleListSection from './BlogArticleListSection';
import BlogVideoListSection from './BlogVideoListSection';

const BlogPage = ({ wholesale }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentData } = authAPI.useGetInfoQuery();
  const isDealer = currentData?.status === 'dealer';

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Компания',
      link: '/about',
    },
    {
      title: 'Блог',
      link: '/articles',
    },
  ];

  // useTitle('Блог');

  const { data: blogVideos, isFetching: isFetchingVideos } = blogAPI.useFetchAllBlogVideosQuery(20);

  const tabs = [
    { label: 'Статьи', id: 0, section: <BlogArticleListSection /> },
    { label: 'Видео', id: 1, section: <BlogVideoListSection /> },
  ];

  const activeTab = tabs[searchParams.get('tab') || 0];
  const setActiveTab = (tab) => {
    setSearchParams((params) => {
      params.set('tab', tab.id);
      params.set('page', 1);
      return params;
    });
  };

  if (isFetchingVideos) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }

  if (!blogVideos) {
    return <>not found</>;
  }

  return (
    <div className={styles.wrapper}>
      <HelmetHandle
        title="Блог компании"
        metaTitle="Блог компании"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />

      <div className="page_container">

        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <h1>Блог компании</h1>

        {
          isDealer && (
          <BlogTabs
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          )
        }
        {activeTab.section}
      </div>
    </div>
  );
};

export default BlogPage;
