/* eslint-disable no-nested-ternary */

import React from 'react';
import styles from './ContactsContacts.module.scss';
import ContactsContactsItem from './ContactsContactsItem/ContactsContactsItem';

const CONTACTS = [
  { title: 'Адрес', text: 'Россия, г. Красноярск, \nул. Пограничников, д. 31' },
  {
    title: 'Режим работы',
    text: 'Пн. — Пт.: с 8:00 до 16:30; \nСб. — Вс.: Выходной',
  },
  {
    title: 'Телефон',
    text: '8-800-600-55-69',
    link: 'tel:8-800-600-55-69',
  },
  {
    title: 'Почта',
    text: 'info@fosgroup.ru',
    link: 'mailto:info@fosgroup.ru',
  },
];

const ContactsContacts = () => (
  <div className={styles.wrapper}>
    {CONTACTS.map((contact) => (
      <div
        itemProp={
          contact.title === 'Телефон'
            ? 'telephone'
            : contact.title === 'Почта'
              ? 'email'
              : contact.title === 'Режим работы'
                ? 'openingHours'
                : 'streetAddress'
        }
        className={styles.contact}
        key={contact.title}
      >
        <ContactsContactsItem {...contact} />
      </div>
    ))}
  </div>
);

export default ContactsContacts;
