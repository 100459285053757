import React, { forwardRef } from 'react';
import { classnames } from 'utils';
import { EmptyButton } from 'components/universal_components';
import styles from './CatalogPageFilters.module.scss';
import RangeFilter from './RangeFilter/RangeFilter';

const UNDEFINED_VALUE = -1;

const POWER_FILTER_NAME = 'power';

const PowerFilter = forwardRef((props, ref) => (
  <div className={classnames([styles.filterWrapper, 'ignoreCloseFilter', [styles.filled, props.filters.power_min || props.filters.power_max]])}>
    <EmptyButton
      text="Мощность"
      variant="black"
      onClick={() => {
        props.setActiveFilterModal(POWER_FILTER_NAME === props.activeFilterModal ? '' : POWER_FILTER_NAME);
      }}
    />
    <RangeFilter
      isModalActive={POWER_FILTER_NAME === props.activeFilterModal}
      setIsModalActive={props.setActiveFilterModal}
      undefinedValue={UNDEFINED_VALUE}
      text="Потребляемая мощность, Вт"
      removeFilter={props.removeFilter}
      addFilter={props.addFilter}
      filterPrefix={POWER_FILTER_NAME}
      filters={props.filters}
      ref={ref}
    />
  </div>
));

export default PowerFilter;
