import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import styles from './BackLink.module.scss';

const BackLink = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    const prevPage = JSON.parse(localStorage.getItem('previousPage'));
    navigate(prevPage.pathname + prevPage.search + prevPage.hash);
  };

  return (
    <button type="button" className={styles.arrow} onClick={handleClick}>
      <Arrow />
    </button>
  );
};

export default BackLink;
