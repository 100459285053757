import React from 'react';
import { BreadCrumbs, HelmetHandle, VideoSection } from 'components/universal_components';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import styles from './AboutCompanyPage.module.scss';
import FactorySection from './FactorySection/FactorySection';
import MainSection from './MainSection/MainSection';
import TeamSection from './TeamSection/TeamSection';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Компания',
    link: '/about',
  },
];

// useTitle('О компании');

const AboutCompanyPage = () => (
  <div className={styles.wrapper}>
    <HelmetHandle
      title="О компании"
      metaTitle="О компании"
      description={metatags.description}
      metaDescription={metatags.description}
      image={metatags.image}
    />
    <div className="page_container">
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>

      <section className={styles.mainBlock}>
        <MainSection />
      </section>

      <section className={styles.video}>
        <h2 className="visually-hidden">Презентация производства светодиодных светильников FOS</h2>
        <VideoSection />
      </section>

      <div className={styles.separator} />

      <div className={styles.factory}>
        <FactorySection />
      </div>

      <div className={styles.team}>
        <TeamSection />
      </div>
    </div>
  </div>
);

export default AboutCompanyPage;
