import React from 'react';
import { CustomSlider, Loading } from 'components/universal_components';
import { factoryAPI } from 'stateManager/services';
import { useWindowSize } from 'hooks';
import styles from './FactorySection.module.scss';
import FactoryItem from './FactoryItem';

const FactorySection = () => {
  const { data, isFetching } = factoryAPI.useFetchAllFactoryItemsQuery();
  const [width] = useWindowSize();

  if (isFetching) {
    return <Loading />;
  }

  const settings = {
    slidesToShow: width > 1365 ? 4 : 2,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: true,
  };

  return (
    <div>
      <h2 className={styles.title}>Наше производство</h2>
      <div id="factory">
        <CustomSlider
          data={data}
          SliderItem={FactoryItem}
          settings={settings}
          variant="black"
        />
      </div>
    </div>
  );
};

export default FactorySection;
