/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { BlueButton, CustomForm, Loading } from 'components/universal_components';
import { authAPI } from 'stateManager/services';
import { handleServerError, notEmptyValidator, setValidationErrors } from 'utils';
import styles from './ChangeInfoModal.module.scss';

const ChangeInfoItem = ({ setModalState, inputName, inputType, inputPlaceholder }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputValidationErrors, setInputValidationErrors] = useState([]);
  const fields = [
    {
      name: inputName,
      inputType,
      placeholder: inputPlaceholder,
      required: true,
      value: inputValue,
      onChange: (value) => setInputValue(value),
      validators: [notEmptyValidator],
      validationErrors: inputValidationErrors,
      setValidationErrors: (value) => {
        setInputValidationErrors((state) => setValidationErrors(state, value));
      },
    },

  ];

  const [sendForm, { isLoading, isError: isFetchError, error }] = authAPI.useSetInfoMutation();
  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  if (isLoading) {
    return <Loading />;
  }

  const sendData = async () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      const res = await sendForm(dataToSend);
      if (res.error) {
        handleServerError(res.error);
      } else {
        setModalState();
      }
    }
  }; return (
    <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
      <CustomForm fields={fields} styles={styles} /> <BlueButton text="Сохранить" onClick={sendData} />
    </div>
  );
};

export default ChangeInfoItem;
