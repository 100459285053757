import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const blogAPI = createApi({
  reducerPath: 'blog',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllBlogArticles: build.query({
      query: (args) => ({
        url: '/blog/articles/',
        params: args,
      }),
    }),
    fetchBlogArticle: build.query({
      query: (slug) => ({
        url: `/blog/articles/${slug}/`,
      }),
    }),
    fetchAllBlogVideos: build.query({
      query: (args) => ({
        url: '/blog/videos/',
        params: args,
      }),
    }),
    fetchBlogVideo: build.query({
      query: (slug) => ({
        url: `/blog/videos/${slug}/`,
      }),
    }),
  }),
});
export default blogAPI;
