import React, { useState } from 'react';
import { classnames, moneyFormatter } from 'utils';
import { ReactComponent as Bin } from 'assets/images/bin.svg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeProduct, setQuantity } from 'stateManager/reducers/OrderSlice';
import styles from './OrderItem.module.scss';
import CartCounter from './CartCounter/CartCounter';

const OrderItem = ({ article, product, id }) => {
  const { order } = useSelector((state) => state.orderReducer);

  const dispatch = useDispatch();
  const [count, _setCount] = useState(order.find((pr) => pr.id === id).quantity);
  const setCount = (value) => {
    dispatch(setQuantity({ id, quantity: value }));
    _setCount(value);
  };

  return (
    <article className={styles.wrapper}>
      <Link to={`/catalog/products/${product.slug}`} className={styles.image}>
        <img src={product.image} alt={product.title} />
      </Link>
      <div className={styles.productTitle}>
        {product.title}
      </div>

      <ul className={styles.rest}>
        <li className={classnames([styles.title, styles.el])} data-text="Наименование">
          <p>{product.title}</p>
        </li>

        <li className={classnames([styles.articul, styles.el])} data-text="Артикул">
          <p>{article}</p>
        </li>

        <li className={classnames([styles.count, styles.el])} data-text="Количество">
          <CartCounter count={count} setCount={setCount} />
        </li>

        <li className={classnames([styles.price, styles.el])} data-text="Цена">
          <p className={styles.price_item} data-text="за 1 шт">{moneyFormatter(product.price)}</p>
        </li>

        <li className={classnames([styles.total, styles.el])} data-text="Сумма">
          <p>{moneyFormatter(product.price * count)}</p>
        </li>

        <li className={classnames([styles.bin, styles.el])} data-text=" ">
          <button type="button" onClick={() => dispatch(removeProduct(id))} aria-label="delete">
            <Bin />
          </button>
        </li>
      </ul>

    </article>
  );
};

export default OrderItem;
