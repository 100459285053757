import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const partnersAPI = createApi({
  reducerPath: 'partners',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllPartners: build.query({
      query: (limit = 5) => ({
        url: '/partners/partners/',
        params: {
          limit,
        },
      }),
      transformResponse: (response) => response.results,
    }),
  }),
});
export default partnersAPI;
