import React from 'react';
import { ModalWindow } from 'components/universal_components';
import ChangeInfoItem from './ChangeInfoItem';
import ChangePassword from './ChangePassword';

const ChangeInfoModal = ({ modalState, setModalState }) => {
  let ModalElement = '';
  switch (modalState) {
    case ('firstname'):
      ModalElement = (
        <ChangeInfoItem setModalState={setModalState} inputName="first_name" inputType="text" inputPlaceholder="Имя" />
      );
      break;
    case ('lastname'):
      ModalElement = <ChangeInfoItem setModalState={setModalState} inputName="last_name" inputType="text" inputPlaceholder="Фамилия" />;
      break;
    case ('middlename'):
      ModalElement = <ChangeInfoItem setModalState={setModalState} inputName="middle_name" inputType="text" inputPlaceholder="Отчество" />;
      break;
    case ('phone'):
      ModalElement = <ChangeInfoItem setModalState={setModalState} inputName="phone" inputType="tel" inputPlaceholder="Телефон" />;
      break;
    case ('password'):
      ModalElement = <ChangePassword setModalState={setModalState} />;
      break;
    default:
      ModalElement = '';
      break;
  }
  return (
    <ModalWindow setElementWindow={setModalState}>
      {ModalElement}
    </ModalWindow>
  );
};

export default ChangeInfoModal;
