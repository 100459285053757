import React, { useEffect, useState } from 'react';
import { BreadCrumbs, CustomSlider, HelmetHandle, Loading, ModalWindow, NextArrow, PrevArrow } from 'components/universal_components';
import thanksAPI from 'stateManager/services/ThanksService';
import { useWindowSize } from 'hooks';
import metatags from 'metatags';
import styles from './AboutCompanyThanks.module.scss';
import AboutCompanyThanksItem from './AboutCompanyThanksItem/AboutCompanyThanksItem';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Компания',
    link: '/about',
  },
  {
    title: 'Благодарности',
    link: '/about/thanks',
  },
];

const AboutCompanyThanks = () => {
  const { data: thanks, isFetching } = thanksAPI.useFetchAllThanksQuery(20);

  const [activeItem, setActiveItem] = useState(undefined);
  const [isBigScreenActive, _setIsBigScreenActive] = useState(false);
  const windowSize = useWindowSize();

  // useTitle('Благодарности');

  useEffect(() => {
    setActiveItem(thanks ? thanks[0] : undefined);
  }, [thanks]);

  const setNextItem = (event) => {
    event.stopPropagation();
    const activeIndex = thanks.indexOf(activeItem);
    setActiveItem(thanks[activeIndex < thanks.length - 1 ? activeIndex + 1 : 0]);
  };
  const setPrevItem = (event) => {
    event.stopPropagation();
    const activeIndex = thanks.indexOf(activeItem);
    setActiveItem(thanks[activeIndex > 0 ? activeIndex - 1 : thanks.length - 1]);
  };

  const setIsBigScreenActive = (value) => {
    if (value) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    _setIsBigScreenActive(value);
  };

  const settings = {
    slidesToShow: windowSize[0] > 1365 ? 7 : 5,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
  };

  const mobileSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    swipeToSlide: true,
  };

  if (isFetching) {
    <div className="page_container">
      <Loading />
    </div>;
  }
  if (!thanks) {
    return (
      <div className="page_container">
        <div className={styles.nothing}>Страница не заполнена</div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <HelmetHandle
        title="Благодарности"
        metaTitle="Благодарности"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <h1 className={styles.title}>Благодарности</h1>

        <div className={styles.activeItem}>
          <div className={styles.description}>{activeItem?.text}</div>
          <button type="button" className={styles.activeItemImage} onClick={() => setIsBigScreenActive(true)}>
            <img src={activeItem?.image} alt={activeItem?.title} />
          </button>
        </div>

        <div className={styles.items}>
          <CustomSlider
            data={thanks.map((item) => ({
              ...item,
              isActive: activeItem?.id === item?.id,
              activate: () => setActiveItem(item),
            }))}
            settings={windowSize[0] > 767 ? settings : mobileSettings}
            SliderItem={AboutCompanyThanksItem}
            variant="black"
          />
        </div>
      </div>

      {isBigScreenActive && (
        <ModalWindow elementWindow={{}} setElementWindow={() => { setIsBigScreenActive(false); }}>
          <button type="button" className={styles.bigScreen} onClick={() => setIsBigScreenActive(false)}>
            <button type="button" onClick={setPrevItem} className={styles.prev}><div className="visually-hidden">Предыдущая</div>
              <PrevArrow />
            </button>
            <img src={activeItem?.image} alt={activeItem?.title} />
            <button type="button" onClick={setNextItem} className={styles.next}>
              <div className="visually-hidden">
                Следующая
              </div>
              <NextArrow />
            </button>
          </button>
        </ModalWindow>
      )}
    </div>
  );
};

export default AboutCompanyThanks;
