import React from 'react';
import { Link } from 'react-router-dom';
import styles from './FooterNav.module.scss';

const FOOTER_NAV_LINKS = [
  { name: 'Каталог', link: '/catalog' },
  { name: 'Компания', link: '/about' },
  { name: 'Лаборатория', link: '/laboratory' },
  { name: 'Проекты', link: '/projects' },
  { name: 'Контакты', link: '/contacts' },
];

const FooterNav = () => (
  <nav className={styles.nav}>
    {FOOTER_NAV_LINKS.map((link) => (
      <Link to={link.link} key={link.name} className={styles.link}>
        {link.name}
      </Link>
    ))}
  </nav>
);

export default FooterNav;
