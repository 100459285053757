import React from 'react';
import styles from './BlogTabs.module.scss';

const BlogTabs = ({ tabs, activeTab, setActiveTab }) => {
  const buttonClickHandler = (id) => {
    setActiveTab(tabs.find((tab) => tab.id === id));
  };

  return (
    <div className={styles.tabs}>
      {tabs.map((el) => (
        <button
          className={activeTab.id === el.id ? `${styles.active} ${styles.button}` : styles.button}
          type="button"
          key={el.id}
          onClick={() => buttonClickHandler(el.id)}
        >
          <span>{el.label}</span>
        </button>
      ))}
    </div>
  );
};

export default BlogTabs;
