/* eslint-disable react/no-danger */
import React from 'react';
import { useParams } from 'react-router-dom';
import { blogAPI } from 'stateManager/services';
import { dateFormater } from 'utils';
import { BreadCrumbs, CustomSlider, HelmetHandle, Loading } from 'components/universal_components';
// import { useTitle } from 'hooks';
import PageFooter from 'components/universal_components/PageFooter/PageFooter';
import styles from './SingleBlogPage.module.scss';
import SingleBlogSlider from './SingleBlogSlider/SingleBlogSlider';

const SingleBlogPage = () => {
  const params = useParams();

  const { data: article, isFetching: isFetchingArticle } = blogAPI.useFetchBlogArticleQuery(params.slug);
  const { data: video, isFetching: isFetchingVideo } = blogAPI.useFetchBlogVideoQuery(params.slug);

  const blogType = article ? 'article' : 'video';
  const blogItem = article || video;

  if (isFetchingArticle || isFetchingVideo) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!(article || video)) {
    return <>not found</>;
  }

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Компания',
      link: '/about',
    },
    {
      title: 'Блог',
      link: '/articles',
    },
    {
      title: blogItem && `${blogItem.title}`,
      link: `/articles/${blogItem?.slug}`,
    },
  ];

  // useTitle(blogItem.title);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
  };

  const images = [
    { id: 0, image: blogItem?.image },
    ...(blogItem?.additional_images ? blogItem.additional_images : []),
  ];

  switch (blogType) {
    case 'article':
      return (
        <div itemScope itemType="http://schema.org/Article" className={styles.wrapper}>
          <HelmetHandle
            image={blogItem.cropped_image}
            title={blogItem.title}
            metaTitle={blogItem.meta_title}
            description={blogItem.meta_description}
            metaDescription={blogItem.meta_description}
          />

          <div className="page_container">
            <div className="breadCrumbs">
              <BreadCrumbs items={BREAD_CRUMBS} />
            </div>
            <h1 itemProp="name" className={styles.title}>{blogItem?.title}</h1>

            <div className={styles.subInfo}>
              <p className={styles.date}>{`${dateFormater(blogItem?.date).slice(0, -3)}г.`}</p>
              <p className={styles.time_to_read}>
                Время на прочтение: ~
                {`${
                  Math.round(blogItem && blogItem.reading_time / 60) > 1 ? Math.round(blogItem.reading_time / 60) : 1
                }`}
                мин.
              </p>
            </div>

            <article className={styles.article}>
              <div className={styles.slider}>
                <CustomSlider data={images} SliderItem={SingleBlogSlider} settings={settings} variant="black" />
              </div>
              <div
                itemProp="description"
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: blogItem.text }}
              />
            </article>

            <PageFooter />
          </div>
        </div>
      );
    case 'video':
      return (
        <div className={styles.wrapper}>
          <div className="page_container">
            <div className="breadCrumbs">
              <BreadCrumbs items={BREAD_CRUMBS} />
            </div>
            <h1 className={styles.title}>{blogItem?.title}</h1>

            <div className={styles.subInfo}>
              <p className={styles.date}>{`${dateFormater(blogItem?.date).slice(0, -3)}г.`}</p>
            </div>

            <article className={styles.article}>
              <div
                className={styles.video}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: blogItem.video }}
              />
              <div
                className={styles.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: blogItem.full_description }}
              />
            </article>

            <PageFooter />
          </div>
        </div>
      );
    default:
      return <div>Процесс</div>;
  }
};

export default SingleBlogPage;
