import { CustomInput } from 'components/universal_components';
import React from 'react';
import { classnames } from 'utils';
import styles from './CartCounter.module.scss';

const CartCounter = ({ count, setCount }) => (
  <div className={styles.wrap}>
    <button className={classnames([styles.button, styles.decrement])} type="button" onClick={() => setCount(count > 1 ? count - 1 : 1)}>
      <span className={styles.value}>-</span>
    </button>

    <CustomInput
      inputType="number"
      value={count}
      placeholder=""
      onChange={(value) => { setCount(Math.abs(value) || null); }}
      onBlur={(event) => {
        setCount(Math.abs(event.target.value) || 1);
      }}
      variant="count"
    />

    <button className={classnames([styles.button, styles.increment])} type="button" onClick={() => setCount(count + 1)}>
      <span className={styles.value}>+</span>
    </button>
  </div>
);

export default CartCounter;
