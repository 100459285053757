/* eslint-disable no-nested-ternary */
import { CustomSlider, Loading } from 'components/universal_components';
import React from 'react';
import { catalogAPI } from 'stateManager/services';
import { useWindowSize } from 'hooks';
import styles from './WatchedSection.module.scss';
import CatalogPageProduct from '../../ProductsSection/CatalogPageProduct/CatalogPageProduct';

const SliderItem = (props) => (
  <div className={styles.product}>
    <CatalogPageProduct {...props} />
  </div>
);

const WatchedSection = ({ product }) => {
  const previosWatchedIds = JSON.parse(
    localStorage.getItem('watchedProducts') || '[]',
  ).filter((item) => item !== product?.id);
  const [width] = useWindowSize();
  if (!previosWatchedIds.length) {
    return null;
  }
  const { data: products } = catalogAPI.useFetchAllProductsQuery({
    ids: previosWatchedIds,
  });

  if (!products) {
    return <Loading />;
  }

  const settings = {
    slidesToShow: width > 1365 ? 4 : width > 767 ? 2 : 1,
    slidesToScroll: 1,
    infinite: !(width > 1365),
  };

  return (
    <section className={styles.wrapper}>
      <h2>Просмотренные товары</h2>
      <p className={styles.title}>Вы смотрели</p>
      <div className={styles.products}>
        {products.count > 4 ? (
          <CustomSlider
            settings={settings}
            SliderItem={SliderItem}
            data={products.results}
            variant="white"
          />
        ) : (
          <div className={styles.inner}>
            {products.results?.map((el) => (
              <CatalogPageProduct {...el} key={el.id} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default WatchedSection;
