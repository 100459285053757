import React from 'react';
import styles from './Cooperation.module.scss';

const CooperationSlider = ({ id, image, title, setActiveImage }) => (
  <li className={styles.sertificat}>
    <button
      type="button"
      className={styles.sertButton}
      onClick={() => {
        setActiveImage(id);
      }}
    >
      <img src={image} alt={title} />
      <p>{title}</p>
    </button>

  </li>
);

export default CooperationSlider;
