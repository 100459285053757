import React, { useEffect, useState } from 'react';
import arrow from 'assets/images/slider/arrow.svg';
import styles from './UpButton.module.scss';

const UpButton = () => {
  const [isShowScrollTopButton, setShowScrollTopButton] = useState(false);
  const scrollToTop = () => {
    if (window.scrollY > 500) {
      setShowScrollTopButton(true);
    } else {
      setShowScrollTopButton(false);
    }
  };
  const scrollTopClickHandler = () => {
    const element = document.getElementById('header');
    if (element) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', scrollToTop);

    return () => window.removeEventListener('scroll', scrollToTop);
  }, []);

  return (
    <button
      type="button"
      className={styles.wrapper}
      onClick={scrollTopClickHandler}
      style={isShowScrollTopButton ? { transform: 'scale(1) rotate(90deg)' } : { transform: 'scale(0) rotate(90deg)' }}
    >
      <img src={arrow} alt="Вверх" />
    </button>
  );
};

export default UpButton;
