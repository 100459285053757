import React from 'react';
import styles from './ProjectImage.module.scss';

const ProjectImage = ({ image, activateModal }) => (
  <button type="button" className={styles.image} onClick={() => activateModal()}>
    <img src={image} alt="Фотография" />
  </button>
);

export default ProjectImage;
