import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { classnames } from 'utils';
import styles from './CustomSlider.module.scss';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

const CustomSlider = ({ settings, SliderItem, data, variant = '' }) => {
  const slider = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const nextSlide = () => {
    slider.current.slickNext();
  };
  const prevSlide = () => {
    slider.current.slickPrev();
  };
  if (!data) {
    return null;
  }
  if (data.length <= settings.slidesToShow) {
    return (
      <div className={styles.wrapper}>
        {data.map((item, index) => (
          <SliderItem {...item} key={item.id ? item.id : index} />
        ))}
      </div>
    );
  }
  return (
    <div className={classnames([styles.wrapper, styles[variant]])}>
      <button
        type="button"
        onClick={prevSlide}
        className={classnames([styles.prev, [styles.deactivate, currentSlide < 1]])}
        disabled={currentSlide < 1 && 'disabled'}
      >
        <PrevArrow />
      </button>
      <Slider
        {...settings}
        arrows={false}
        ref={slider}
        beforeChange={(prev, next) => {
          setCurrentSlide(next);
        }}
      >
        {data.map((item, index) => (
          <SliderItem {...item} key={item.id ? item.id : index} />
        ))}
      </Slider>
      <button
        type="button"
        onClick={nextSlide}
        className={classnames([styles.next, [styles.deactivate, currentSlide >= data.length - settings.slidesToShow]])}
        disabled={currentSlide >= data.length - settings.slidesToShow && 'disabled'}
      >
        <NextArrow />
      </button>
    </div>
  );
};

export default CustomSlider;
