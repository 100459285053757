import React from 'react';
import { ReactComponent as LoadSVG } from 'assets/images/loader.svg';
import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.modal}>
    <LoadSVG />
  </div>
);

export default Loading;
