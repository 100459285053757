import React from 'react';
import { BlueButton, CustomInput, EmptyButton } from 'components/universal_components';
import styles from './LikesPage.module.scss';

const ControlPanel = ({ setSearch, deleteAllLikes, search }) => (
  <div className={styles.control}>
    <div className={styles.search}>
      <CustomInput className={styles.search} inputType="text" placeholder="Введите название" onChange={(value) => { setSearch(value); }} onBlur={() => {}} value={search} />
    </div>

    <div className={styles.contitue}>
      <EmptyButton to="/catalog" text="Продолжить покупки" variant="bigPadding" />
    </div>

    <div className={styles.clear}>
      <BlueButton text="Очистить избранное" onClick={() => { deleteAllLikes(); }} />
    </div>
  </div>
);

export default ControlPanel;
