import React from 'react';
import { ReactComponent as Arrow } from 'assets/images/slider/arrow.svg';

const PrevArrow = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Arrow />
  </div>
);

export default PrevArrow;
