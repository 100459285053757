import React from 'react';
import { Link } from 'react-router-dom';
import CatalogCard from '../../CatalogCard/CatalogCard';
import styles from './CatalogPageCategory.module.scss';

const CatalogPageCategory = ({ title, image, slug }) => (
  <Link to={`/catalog/${slug}`} className={styles.wrapper}>
    <CatalogCard variant="category">
      <h3 className="visually-hidden">{title}</h3>
      <p className={styles.title}>{title}</p>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
    </CatalogCard>
  </Link>
);

export default CatalogPageCategory;
