import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { classnames } from 'utils';
import styles from './ChoiceFilter.module.scss';

const ChoiceFilter = forwardRef((props, ref) => {
  const [activeChoices, _setActiveChoices] = useState([]);
  const [activeChoicesIds, setActiveChoicesIds] = useState(props.filters[props.filterPrefix] || []);

  const setActiveChoices = (value) => {
    _setActiveChoices(value);
    setActiveChoicesIds(value.map((choice) => choice.id));
    props.addFilter({ type: props.filterPrefix, value: value.map((v) => v.id) });
  };

  useImperativeHandle(ref, () => ({
    emptyFilter() {
      setActiveChoices([]);
    },
  }));

  const addActiveChoice = (choice) => {
    setActiveChoices([...activeChoices, choice]);
  };
  const removeActiveChoice = (choiceId) => {
    setActiveChoices(activeChoices.filter((choice) => choice.id !== choiceId));
  };
  const toggleActiveChoive = (choice) => {
    if (activeChoicesIds.includes(choice.id)) {
      removeActiveChoice(choice.id);
    } else {
      addActiveChoice(choice);
    }
  };

  const closeModalByClick = (event) => {
    if (!event.target.closest('.ignoreCloseFilter')) {
      props.setIsModalActive('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeModalByClick);

    return () => document.removeEventListener('click', closeModalByClick);
  });

  return (
    <div className={styles.modal}>
      {props.isModalActive && (
        <button type="button" className={styles.background}>
          <AnimatePresence>
            <motion.div
              className={styles.filter}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.35,
                },
              }}
              exit={{
                opacity: 0,
              }}
            >
              {props.choices.map((choice) => (
                <button
                  type="button"
                  className={classnames([
                    styles.choice,
                    [styles.active, activeChoicesIds.includes(choice.id)],
                  ])}
                  onClick={() => toggleActiveChoive(choice)}
                  key={choice.id}
                >
                  <div>{choice.title}</div>
                </button>
              ))}
            </motion.div>
          </AnimatePresence>
        </button>
      )}
    </div>
  );
});

export default ChoiceFilter;
