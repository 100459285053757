import React from 'react';
import message from 'assets/images/footer/message.svg';
import phone from 'assets/images/footer/phone.svg';
import { ASK_QUESTION_MODAL, PHONE_CALL_MODAL } from '../constants';
import styles from './FooterContactLinks.module.scss';
import FooterContactLinksItem from './FooterContactLinksItem/FooterContactLinksItem';

const FooterContactLinks = ({ setElementWindow }) => {
  const LINKS = [
    { id: 1, image: phone, onClick: () => setElementWindow(PHONE_CALL_MODAL.name) },
    { id: 2, image: message, onClick: () => setElementWindow(ASK_QUESTION_MODAL.name) },
  ];
  return (
    <div className={styles.wrapper}>
      {LINKS.map((link) => (
        <FooterContactLinksItem key={link.id} {...link} />
      ))}
    </div>
  );
};

export default FooterContactLinks;
