import React, { useState } from 'react';
import { BreadCrumbs, HelmetHandle } from 'components/universal_components';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import styles from './CatalogPage.module.scss';
import CatalogPageFilters from './CatalogPageFilters/CatalogPageFilters';
import CategoriesSection from './CategoriesSection';
import ProductsSection from '../ProductsSection/ProductsSection';

const EMPTY_FILTERS = {};

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Каталог',
    link: '/catalog',
  },
];

const CatalogPage = () => {
  const [filters, _setFilters] = useState(JSON.parse(localStorage.getItem('catalogFilters')) || {});

  // useTitle('Каталог');

  const setFilters = (value) => {
    _setFilters(value);
    localStorage.setItem('catalogFilters', JSON.stringify(value));
  };

  return (
    <div className={styles.wrapper}>
      <HelmetHandle
        title="Каталог"
        metaTitle="Каталог"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} variant="blue" />
        </div>

        <h1 className={styles.title}>Каталог</h1>

        <div className={styles.categories}>
          <h2 className="visually-hidden">Категории товаров</h2>
          <CategoriesSection />
        </div>
        <div className={styles.filters}>
          <CatalogPageFilters filters={filters} setFilters={setFilters} emptyFilters={EMPTY_FILTERS} />
        </div>
        <div className={styles.products}>
          <h2 className={styles.products__title}>Товары, найденные по фильтру</h2>
          <ProductsSection filters={filters} />
        </div>
      </div>
    </div>
  );
};

export default CatalogPage;
