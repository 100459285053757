import React, { forwardRef } from 'react';
import { catalogAPI } from 'stateManager/services';
import { classnames } from 'utils';
import { EmptyButton, Loading } from 'components/universal_components';
import styles from './CatalogPageFilters.module.scss';
import ChoiceFilter from './ChoiceFilter/ChoiceFilter';

const CATEGORY_FILTER_NAME = 'category';

const CategoryFilter = forwardRef((props, ref) => {
  const { data: categories, isFetching } = catalogAPI.useFetchAllCategoriesQuery(20);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  return (
    <div className={classnames([styles.filterWrapper, 'ignoreCloseFilter', [styles.filled, props.filters.category?.length]])}>
      <EmptyButton
        text="Категория"
        variant="black"
        onClick={() => {
          props.setActiveFilterModal(CATEGORY_FILTER_NAME === props.activeFilterModal ? '' : CATEGORY_FILTER_NAME);
        }}
      />
      <ChoiceFilter
        choices={categories}
        isModalActive={CATEGORY_FILTER_NAME === props.activeFilterModal}
        setIsModalActive={props.setActiveFilterModal}
        removeFilter={props.removeFilter}
        addFilter={props.addFilter}
        filterPrefix="category"
        filters={props.filters}
        ref={ref}
      />
    </div>
  );
});

export default CategoryFilter;
