export default function setValidationErrors(state, value) {
  if (value.length === 0) {
    return [];
  }
  if (state) {
    if (state.find((item) => item.id === value.id)) {
      return state;
    }
  }
  if (!state) {
    return [value];
  }
  return [...state, value];
}
