import React, { useState } from 'react';
import { classnames } from 'utils';
import { BlueButton } from 'components/universal_components';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'hooks';
import { ReactComponent as Search } from 'assets/images/search.svg';
import styles from './HeaderSearch.module.scss';

const HeaderSearch = ({ setIsSearchActive, isSeacrhActive, black }) => {
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const [windowWidth] = useWindowSize();

  return (
    <div className={classnames([styles.wrapper, [styles.active, isSeacrhActive], [styles.black, black]])}>
      <input
        type="text"
        value={value}
        onFocus={() => setIsSearchActive(true)}
        // setTimeout чтобы клик по нужной кнопке в инпуте срабатывал раньше, чем display none этой кнопки
        onBlur={() => setTimeout(() => setIsSearchActive(false), 150)}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(evt) => evt.key === 'Enter' && navigate(`/search?search=${value}`)}
        className={classnames([styles.input, [styles.active, isSeacrhActive], [styles.black, black]])}
        placeholder="Поиск"
      />

      <div className={classnames([styles.buttonSearch, [styles.active, isSeacrhActive]])}>
        <BlueButton
          text={windowWidth < 767 ? <Search /> : 'Найти'}
          onClick={() => navigate(`/search?search=${value}`)}
          variant="small"
        />
      </div>
    </div>
  );
};

export default HeaderSearch;
