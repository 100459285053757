import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const feedbackAPI = createApi({
  reducerPath: 'feedback',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    createPhoneCallRequest: build.mutation({
      query: (data) => ({
        url: '/feedback/phone-calls/',
        method: 'POST',
        body: data,
      }),
    }),
    createQuestion: build.mutation({
      query: (data) => ({
        url: '/feedback/questions/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
export default feedbackAPI;
