import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const searchAPI = createApi({
  reducerPath: 'search',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllSearchs: build.query({
      query: (request) => ({
        url: `/search/search/${request}`,
      }),
    }),
  }),
});
export default searchAPI;
