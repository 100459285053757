import React from 'react';
import { laboratoryAPI } from 'stateManager/services';
import { CustomSlider, Loading } from 'components/universal_components';
import { useWindowSize } from 'hooks';
import LaboratoryItem from './LaboratoryItem/LaboratoryItem';
import styles from './LaboratorySection.module.scss';

const LaboratorySection = () => {
  const [width] = useWindowSize();
  const getQuantity = () => {
    if (width > 1365) {
      return 4;
    } if (width > 767) {
      return 2;
    }
    return 1;
  };

  const { data: articles, isFetching } = laboratoryAPI.useFetchAllLaboratoryArticlesQuery();

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  const settings = {
    slidesToShow: getQuantity(),
    slidesToScroll: 1,
    infinite: false,
    centerPadding: '40px',
    lazyLoad: true,
  };

  return (
    <div className={styles.wrapper} id="laboratorySection">
      <div className={styles.title}>Лаборатория</div>
      <div className={styles.items}>
        <CustomSlider settings={settings} SliderItem={LaboratoryItem} data={articles} variant="black" />
      </div>
    </div>
  );
};

export default LaboratorySection;
