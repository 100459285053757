/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import { classnames, handleServerError } from 'utils';
import { ReactComponent as Comparison } from 'assets/images/header/statistic.svg';
import { ReactComponent as Cart } from 'assets/images/cart.svg';
import { BlueButton } from 'components/universal_components';
import { catalogAPI } from 'stateManager/services';
import { addProduct, removeProduct } from 'stateManager/reducers/OrderSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './TableSection.module.scss';

const TableSection = ({ items, title }) => {
  if (!items.length) {
    return null;
  }
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const { data: coparisons } = catalogAPI.useFetchAllComparisonsQuery();

  const [_addToCoparison] = catalogAPI.useAddToCoparisonMutation();
  const [deleteFromCoparison] = catalogAPI.useDeleteFromCoparisonMutation();

  const addToCoparison = async (value) => {
    const res = await _addToCoparison(value);
    if (res.error) {
      handleServerError(res.error);
    }
  };

  const { order } = useSelector((state) => state.orderReducer);

  // Весь этот юзэффект отвечает за построение таблички в виде таблички. Как он это делает объяснить не смогу, так что если тебе надо это изменить. Удачи!
  // useEffect(() => {
  //   tableRef.current.querySelectorAll(`.${styles.title}`).forEach((item, index) => {
  //     item.style.display = index > 1 ? 'none' : 'flex';
  //     if (index === 1) {
  //       item.style.gridRow = '2/1000';
  //     }
  //   });

  //   const ledBrands = {};
  //   const ledBrandsNodes = tableRef.current.querySelectorAll(`.${styles.ledBrand}`);
  //   const lightOutputNodes = tableRef.current.querySelectorAll(`.${styles.lightOutput}`);

  //   ledBrandsNodes.forEach((item, index) => {
  //     const text = item.innerText;
  //     if (ledBrands[text]) {
  //       item.style.display = 'none';

  //       lightOutputNodes[index].style.display = 'none';
  //     } else {
  //       ledBrands[text] = true;

  //       const startIndex = index + 1;

  //       item.style.gridRow = index === 0 ? '1/2' : `${startIndex}/${startIndex + [...ledBrandsNodes].filter((node) => node.innerText === text).length}`;

  //       lightOutputNodes[index].style.gridRow = index === 0 ? '1/2' : `${startIndex}/${startIndex + [...ledBrandsNodes].filter((node) => node.innerText === text).length}`;

  //       const opticsNodes = tableRef.current.querySelectorAll(`.${styles.opt}`);
  //       let curNode;
  //       let prevStartIndex = 0;
  //       opticsNodes.forEach((optic, opticIndex) => {
  //         if (optic.innerText !== opticsNodes[opticIndex - 1]?.innerText) {
  //           if (curNode) {
  //             curNode.style.gridRow = `${prevStartIndex}/${opticIndex + 1}`;
  //           }
  //           prevStartIndex = opticIndex + 1;
  //           curNode = optic;
  //         } else {
  //           optic.style.display = 'none';
  //         }
  //       });
  //       curNode.style.gridRow = `${prevStartIndex}/1000`;
  //     }
  //   });
  // }, [tableRef, items]);
  return (
    <div className={styles.wrapper}>

      <div className={styles.table} ref={tableRef} style={{ gridTemplateColumns: items[0].glass_material ? 'repeat(13, auto)' : 'repeat(12, auto)' }}>
        <div className={classnames([styles.ledBrand, styles.table_header_item])} style={{ gridColumn: '1/2' }}>Марка светодиода</div>
        <div className={classnames([styles.opt, styles.table_header_item])} style={{ gridColumn: '2/3' }}>Оптика</div>
        {items[0].glass_material &&
        <div className={classnames([styles.table_header_item])} style={{ gridColumn: '3/4' }}>Тип рассеивателя</div> }
        <div className={classnames([styles.table_header_item])}>Цветовая температура</div>
        {/* <div className={classnames([styles.table_header_item])}>Мощность</div> */}
        <div className={classnames([styles.lightOutput, styles.table_header_item])}>Световая отдача</div>
        <div className={classnames([styles.table_header_item])}>Световой поток</div>
        <div className={classnames([styles.table_header_item])}>Производственный артикул</div>
        <div className={classnames([styles.table_header_item])}>Паспорт</div>
        <div className={classnames([styles.table_header_item])}>IES</div>
        {/* <div className={classnames([styles.table_header_item])}>Протокол сферы</div> */}
        <div className={classnames([styles.table_header_item, styles.head_comparison])} title="Добавить к сравнению">
          <Comparison />
        </div>
        <div className={classnames([styles.table_header_item, styles.head_cart])} title="Добавить в корзину">
          <Cart />
        </div>
        {items.map((item) => (
          <React.Fragment key={item.id}>
            <div className={classnames([styles.ledBrand, styles.table_body_item])} style={{ gridColumn: '1/2' }}>{item.led_brand}</div>

            <div className={classnames([styles.opt, styles.table_body_item])} style={{ gridColumn: '2/3' }}>
              {item.optic || '-'}
            </div>

            {item.glass_material &&
            <div className={classnames([styles.glass_material, styles.table_body_item])} style={{ gridColumn: '3/4' }}>{item.glass_material}</div>}
            <div className={classnames([styles.temperature, styles.table_body_item])}>{item.color_temperature}</div>
            {/* <div className={classnames([styles.power, styles.table_body_item])}>{item.power}</div> */}
            <div className={classnames([styles.lightOutput, styles.table_body_item])}>{item.light_output}</div>
            <div className={classnames([styles.lightFlow, styles.table_body_item])}>{item.light_flow}</div>
            <div className={classnames([styles.article, styles.table_body_item])}>{item.article}</div>

            <div className={classnames([styles.passport, styles.table_body_item])}>
              {item.passport
                ? <a href={item.passport} target="_blank" download rel="noreferrer">Скачать</a>
                : <span style={{ textAlign: 'center' }}>-</span>}
            </div>

            <div className={classnames([styles.ies, styles.table_body_item])}>
              {item.IES
                ? <a href={item.IES} target="_blank" download rel="noreferrer">Скачать</a>
                : <span style={{ textAlign: 'center' }}>-</span>}
            </div>

            {/* <div className={classnames([styles.sphere, styles.table_body_item])}>
              {item.sphere_protocol
                ? <a href={item.sphere_protocol} target="_blank" download rel="noreferrer">Скачать</a>
                : <span style={{ textAlign: 'center' }}>-</span>}
            </div> */}

            <div className={classnames([styles.table_body_item, styles.body_comparison])} title="Добавить к сравнению">
              <BlueButton
                text=""
                variant="cross"
                state={coparisons?.find((c) => c === item.id) && 'pressed'}
                onClick={() => {
                  // eslint-disable-next-line max-len
                  if (coparisons?.find((c) => c === item.id)) { deleteFromCoparison(item.id); } else { addToCoparison(item.id); }
                }}
                disabled=""
              />
            </div>
            <div className={classnames([styles.table_body_item, styles.body_cart])} title="Добавить в корзину">
              <BlueButton text="" variant="cross" state={order.find((i) => i.id === item.id) && 'pressed'} onClick={() => { dispatch(order.find((i) => i.id === item.id) ? removeProduct(item.id) : addProduct({ id: item.id, quantity: 1 })); }} disabled="" />
            </div>
          </React.Fragment>
        ))}
      </div>
      <table />
    </div>
  );
};

export default TableSection;
