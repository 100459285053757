import React from 'react';
import { ReactComponent as DesignIcon } from 'assets/images/icon_design.svg';
import { ReactComponent as ProductionIcon } from 'assets/images/icon_production.svg';
import { ReactComponent as DeliveryIcon } from 'assets/images/icon_delivery.svg';
import { ReactComponent as OrderIcon } from 'assets/images/icon_order.svg';
import { classnames } from 'utils';
import styles from './OrderStatusIcons.module.scss';

const OrderStatusIcons = ({ status }) => (
  <ul className={styles.list}>
    <li className={classnames([styles.item, [styles.white, status === 'start']])}>
      <p className={styles.text}>Заказ<br />оформлен</p>
      <OrderIcon className={styles.icon} />
    </li>

    <li className={classnames([styles.item, [styles.white, status === 'preparing']])}>
      <p className={styles.text}>Подготовка к&nbsp;производству</p>
      <DesignIcon className={styles.icon} />
    </li>

    <li className={classnames([styles.item, [styles.white, status === 'production']])}>
      <p className={styles.text}>Производство</p>
      <ProductionIcon className={styles.icon} />
    </li>

    <li className={classnames([styles.item, [styles.white, status === 'delivery']])}>
      <p className={styles.text}>Доставка</p>
      <DeliveryIcon className={styles.icon} />
    </li>
  </ul>
);

export default OrderStatusIcons;
