import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LogoVk } from 'assets/images/contacts/vk.svg';
import styles from './PageFooter.module.scss';
import BackLink from '../BackLink/BackLink';

const PageFooter = () => {
  const location = useLocation();
  return (
    <div className={styles.additional_info}>
      <BackLink source="/news" />

      <button
        type="button"
        className={styles.print}
        onClick={() => {
          window.print();
        }}
      >
        Распечатать
      </button>

      <p className={styles.share}>Поделиться:</p>

      <div className={styles.shareSocial}>
        {/* vk */}

        <Link
          to={`https://vk.com/share.php?url=https://fosgroup.ru${location.pathname}`}
          target="_blank"
          title="Поделиться ВКонтакте"
        >
          <LogoVk />
        </Link>
      </div>
    </div>
  );
};

export default PageFooter;
