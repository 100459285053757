import React from 'react';
import { catalogAPI } from 'stateManager/services';
import { Loading } from 'components/universal_components';
import styles from './CatalogPage.module.scss';
import CatalogPageCategory from './CatalogPageCategory/CatalogPageCategory';

const CategoriesSection = () => {
  const { data: categories, isFetching: isFetchingCategories } = catalogAPI.useFetchAllCategoriesQuery(20);
  if (isFetchingCategories) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  return categories.map((category) => (
    <div className={styles.category} key={category.id}>
      <CatalogPageCategory {...category} />
    </div>
  ));
};

export default CategoriesSection;
