import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlueButton, BreadCrumbs, Loading } from 'components/universal_components';
import { authAPI } from 'stateManager/services';
import { handleServerError } from 'utils';
import { useTitle } from 'hooks';
import styles from './CabinetPage.module.scss';
import ChangeInfoModal from './ChangeInfoModal/ChangeInfoModal';
import InfoSection from './InfoSection';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Мой кабинет',
    link: '/cabinet',
  },

];
const CabinetPage = () => {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState();

  const { data, isFetching, isError, error } = authAPI.useGetInfoQuery();
  useTitle('Личный кабинет');
  if (isFetching) {
    return <Loading />;
  }
  if (isError) {
    handleServerError(error);
  }
  if (!data) {
    return navigate('/');
  }
  return (
    <div className={styles.wrapper}>
      <div className="page_container">
        <div className={styles.controls}>
          <div className="breadCrumbs">
            <BreadCrumbs items={BREAD_CRUMBS} />
          </div>
          <div className={styles.logout}>
            <BlueButton
              text="Выйти"
              onClick={() => {
                localStorage.removeItem('token');
                window.location = '/';
              }}
            />
          </div>
        </div>

        <h1 className={styles.title}>Мой кабинет</h1>

        <div className={styles.about}>
          <div className={styles.status}>Ваш статус: {data.status === 'buyer' ? 'Покупатель' : 'Дилер'}</div>
          <div className={styles.orders}>
            <BlueButton text="Мои заказы" to="/cabinet/orders" />
          </div>
        </div>

        <div className={styles.info}>
          <InfoSection data={data} setModalState={setModalState} />
        </div>

        <div className={styles.changePassword}>
          <BlueButton text="Сменить пароль" onClick={() => setModalState('password')} />
        </div>

      </div>

      <div className={styles.modal}>
        {modalState && <ChangeInfoModal modalState={modalState} setModalState={setModalState} />}
      </div>
    </div>
  );
};

export default CabinetPage;
