import React from 'react';
import styles from './SingleNewsSliderItem.module.scss';

const SingleNewsSliderItem = ({ image }) => (
  <div className={styles.image}>
    <img itemProp="image" src={image} alt="" />
  </div>
);

export default SingleNewsSliderItem;
