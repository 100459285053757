import React from 'react';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
import { laboratoryAPI } from 'stateManager/services';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import styles from './LaboratoryPage.module.scss';

import LaboratoryItem from './LaboratoryItem/LaboratoryItem';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Лаборатория',
    link: '/laboratory',
  },
];

const LaboratoryPage = () => {
  const { data: articles, isFetching } = laboratoryAPI.useFetchAllLaboratoryArticlesQuery(20);

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!articles) {
    return <>not found</>;
  }

  // useTitle('Лаборатория');

  return (
    <div className={styles.wrapper}>
      <HelmetHandle
        title="Лаборатория"
        metaTitle="Лаборатория"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <h1 className={styles.title}>Лаборатория FOS Lab</h1>
        <div className={styles.items}>
          {articles.map((item) => (
            <div className={styles.item} key={item.id}>
              <LaboratoryItem {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LaboratoryPage;
