import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  authAPI,
  blogAPI,
  catalogAPI,
  dealersFormAPI,
  feedbackAPI,
  laboratoryAPI,
  newsAPI,
  partnersAPI,
  projectsAPI,
  subscripitonsAPI,
  thanksAPI,
  searchAPI,
  orderAPI,
  contactsAPI,
  factoryAPI,
  teamAPI,
  documentsAPI,
} from './services';

import { orderReducer, serverMessageReducer, userReducer } from './reducers';
import vacancyAPI from './services/VacancyService';

const rootReducer = combineReducers({
  serverMessageReducer,
  userState: userReducer,
  orderReducer,
  [projectsAPI.reducerPath]: projectsAPI.reducer,
  [newsAPI.reducerPath]: newsAPI.reducer,
  [blogAPI.reducerPath]: blogAPI.reducer,
  [laboratoryAPI.reducerPath]: laboratoryAPI.reducer,
  [thanksAPI.reducerPath]: thanksAPI.reducer,
  [catalogAPI.reducerPath]: catalogAPI.reducer,
  [partnersAPI.reducerPath]: partnersAPI.reducer,
  [dealersFormAPI.reducerPath]: dealersFormAPI.reducer,
  [subscripitonsAPI.reducerPath]: subscripitonsAPI.reducer,
  [feedbackAPI.reducerPath]: feedbackAPI.reducer,
  [vacancyAPI.reducerPath]: vacancyAPI.reducer,
  [authAPI.reducerPath]: authAPI.reducer,
  [searchAPI.reducerPath]: searchAPI.reducer,
  [orderAPI.reducerPath]: orderAPI.reducer,
  [contactsAPI.reducerPath]: contactsAPI.reducer,
  [factoryAPI.reducerPath]: factoryAPI.reducer,
  [teamAPI.reducerPath]: teamAPI.reducer,
  [documentsAPI.reducerPath]: documentsAPI.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    getDefaultMiddleware()
      .concat(projectsAPI.middleware)
      .concat(newsAPI.middleware)
      .concat(blogAPI.middleware)
      .concat(laboratoryAPI.middleware)
      .concat(thanksAPI.middleware)
      .concat(catalogAPI.middleware)
      .concat(partnersAPI.middleware)
      .concat(dealersFormAPI.middleware)
      .concat(subscripitonsAPI.middleware)
      .concat(feedbackAPI.middleware)
      .concat(vacancyAPI.middleware)
      .concat(authAPI.middleware)
      .concat(searchAPI.middleware)
      .concat(orderAPI.middleware)
      .concat(contactsAPI.middleware)
      .concat(factoryAPI.middleware)
      .concat(teamAPI.middleware)
      .concat(documentsAPI.middleware),
});

export default store;
