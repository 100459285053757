import React from 'react';
import styles from './HeaderNav.module.scss';
import HeaderNavItem from './HeaderNavItem/HeaderNavItem';

const NAV_ITEMS = [
  // { link: '#', text: 'Каталог', blue: true },
  { link: '/about', text: 'Компания' },
  { link: '/laboratory', text: 'Лаборатория' },
  { link: '/projects', text: 'Проекты' },
  { link: '/contacts', text: 'Контакты' },
];

const HeaderNav = () => (
  <ul className={styles.nav}>
    {NAV_ITEMS.map((item) => (
      <li className={styles.navItem} key={item.link}>
        <HeaderNavItem {...item} />
      </li>
    ))}
  </ul>
);

export default HeaderNav;
