import { BreadCrumbs, CustomInput, Loading } from 'components/universal_components';
import React, { useState } from 'react';
import { orderAPI, catalogAPI } from 'stateManager/services';
import CatalogPageProduct from 'components/pages/CatalogPages/ProductsSection/CatalogPageProduct/CatalogPageProduct';
import { classnames, dateFormater } from 'utils';
import styles from './OrdersListPage.module.scss';
import OrderListItem from './OrderListItem';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Мой кабинет',
    link: '/cabinet',
  },
  {
    title: 'Мои заказы',
    link: '/orders',
  },
];

const OrdersListPage = () => {
  const { data: orders, isFetching } = orderAPI.useFetchAllOrdersQuery();

  const [filter, setFilter] = useState('');

  const ordersIds = [];

  orders?.forEach((el) => (
    el.items.forEach((item) => {
      ordersIds.push(item.product);
    })
  ));

  const { data: products, isFetching: isFetchingProducts } = catalogAPI.useFetchAllInstancesQuery({ ids: ordersIds });

  if (isFetching || isFetchingProducts) {
    return <Loading />;
  }
  return (
    <div className="page_container">
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>
      <h1 className={styles.title}>Мои заказы</h1>

      <div className={styles.filter}>
        <CustomInput
          inputType="text"
          placeholder="Фильтр"
          onChange={(value) => { setFilter(value); }}
          onBlur={() => {}}
        />
      </div>

      {/* Текущие */}
      <div className={styles.current}>
        {orders.map((section) => {
          const time = section.created_at.slice(0, 10);

          return (
            (section.status !== 'delivered' &&
            <OrderListItem section={section} time={time} products={products} filter={filter} key={section.id} />)
          );
        })}
      </div>

      {/* Доставленные */}
      <div className={styles.delivered}>
        <p className={styles.title_section}>Вы заказывали ранее:</p>
        {orders.map((section) => {
          const time = section.created_at.slice(0, 10);

          return (
            (section.status === 'delivered' &&
            (
            <div
              className={classnames([styles.section])}
              key={section.id}
            >
              <p className={styles.date}>{dateFormater(time)}</p>

              <div className={styles.grid}>
                {products?.map((el) => (section.items.find((i) => i.product === el.id) && (
                  <div className={styles.item} key={el.id}>
                    <CatalogPageProduct {...el.product} />
                    <div className={styles.quantity}>
                      {section.items.find((item) => item.product === el.id).quantity} шт.
                    </div>
                  </div>
                )
                ))}
              </div>
            </div>
            ))
          );
        })}
      </div>
    </div>
  );
};

export default OrdersListPage;
