import React from 'react';
import styles from './ProjectsCategory.module.scss';
import ProjectsProject from './ProjectsProject/ProjectsProject';

const ProjectsCategory = ({ title, projects }) => (
  <>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.projects}>
      {projects?.map((project) => (
        <div className={styles.project} key={project.id}>
          <ProjectsProject {...project} />
        </div>
      ))}
    </div>
  </>
);

export default ProjectsCategory;
