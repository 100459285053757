import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ProjectsItem.module.scss';

const ProjectsItem = ({ image, title, slug }) => (
  <Link to={`/projects/${slug}`} className={styles.wrapper}>
    <div className={styles.image}>
      <img src={image} alt={title} />
    </div>
    <div className={styles.address}>{title}</div>
  </Link>
);

export default ProjectsItem;
