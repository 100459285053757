import React from 'react';
import { Link } from 'react-router-dom';
import CatalogCard from '../../CatalogCard/CatalogCard';
import styles from './SubCategoryCard.module.scss';

const SubCategoryCard = ({ title, image, slug }) => (
  <Link to={`/catalog/subcategory/${slug}`} className={styles.wrapper}>
    <CatalogCard>
      <div className={styles.image}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.title}>{title}</div>
    </CatalogCard>
  </Link>
);

export default SubCategoryCard;
