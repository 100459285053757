import { AnimatePresence, motion } from 'framer-motion';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { setValidationErrors } from 'utils';
import { CustomForm } from 'components/universal_components';
import styles from './RangeFilter.module.scss';

const RangeFilter = forwardRef((props, ref) => {
  const [at, _setAt] = useState(props.filters[`${props.filterPrefix}_min`] || -1);

  const [to, _setTo] = useState(props.filters[`${props.filterPrefix}_max`] || -1);

  const [atValidationErrors, setAtValidationErrors] = useState([]);
  const [toValidationErrors, setToValidationErrors] = useState([]);

  const setAt = (value) => {
    _setAt(value);
    props.addFilter({
      type: `${props.filterPrefix}_min`,
      value: value !== props.undefinedValue ? value : undefined,
    });
  };

  const setTo = (value) => {
    _setTo(value);
    props.addFilter({ type: `${props.filterPrefix}_max`, value: value !== props.undefinedValue ? value : undefined });
  };

  const fields = [
    {
      name: 'at',
      inputType: 'number',
      placeholder: 'От',
      required: false,
      value: at,
      undefinedValue: props.undefinedValue,
      onChange: (value) => setAt(value),
      validators: [],
      validationErrors: atValidationErrors,
      setValidationErrors: (value) => {
        setAtValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'to',
      inputType: 'number',
      placeholder: 'До',
      required: false,
      value: to,
      undefinedValue: props.undefinedValue,
      onChange: (value) => setTo(value),
      validators: [],
      validationErrors: toValidationErrors,
      setValidationErrors: (value) => {
        setToValidationErrors((state) => setValidationErrors(state, value));
      },
    },
  ];

  useImperativeHandle(ref, () => ({
    emptyFilter() {
      setAt(props.undefinedValue);
      setTo(props.undefinedValue);
    },
  }));

  const closeModalByClick = (event) => {
    if (!event.target.closest('.ignoreCloseFilter')) {
      props.setIsModalActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeModalByClick);

    return () => document.removeEventListener('click', closeModalByClick);
  });
  return (
    <AnimatePresence>
      <div className={styles.modal}>
        {props.isModalActive && (
          <motion.button
            type="button"
            className={styles.background}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
            }}
            exit={{
              opacity: 0,
            }}
          >
            <div className={styles.filter}>
              <div className={styles.title}>{props.text}</div>
              <CustomForm styles={styles} fields={fields} variant="black" />
            </div>
          </motion.button>
        )}
      </div>
    </AnimatePresence>
  );
});

export default RangeFilter;
