import store from 'stateManager';
import { serverMessageSlice } from 'stateManager/reducers/ServerMessageSlice';
import getUuid from './getUuid';

const handleServerError = (error) => {
  const { addMessage } = serverMessageSlice.actions;
  switch (error.status) {
    case 400: {
      Object.values(error.data).forEach((item) => {
        item.forEach((it) => store.dispatch(addMessage({ id: getUuid(), text: it })));
      });
      break;
    }
    case 401: {
      store.dispatch(
        addMessage({ id: getUuid(), text: 'Пожалуйста, войдите в аккаунт или зарегистрируйтесь' }),
        localStorage.removeItem('token'),
      );
      break;
    }

    default: {
      switch (error.originalStatus) {
        case 404: {
          store.dispatch(
            addMessage({ id: getUuid(), text: 'Ой-ой, похоже что-то пошло не так. Пожалуйста, попробуй позже' }),
          );
          break;
        }
        case 500: {
          store.dispatch(
            addMessage({ id: getUuid(), text: 'Ой-ой, похоже что-то пошло не так. Пожалуйста, попробуй позже' }),
          );
          break;
        }
        default: {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      }
    }
  }
};

export default handleServerError;
