/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */

const checkObjectFields = (obj) => {
  for (const key in obj) {
    if (
      obj[key] !== false &&
      !(Array.isArray(obj[key]) && obj[key].length === 0)
    ) {
      return true;
    }
  }
  return false;
};

export default checkObjectFields;
