import React from 'react';
import { EmptyButton } from 'components/universal_components';
import { Link } from 'react-router-dom';
import styles from './NewsItem.module.scss';

const NewsItem = ({ date, title, description, slug, cropped_image: croppedImage }) => (
  <div className={styles.wrapper}>
    <Link to={`/news/${slug}`} className={styles.image}>
      <img src={croppedImage} alt={title} />
    </Link>
    <div className={styles.text}>
      <div>
        <div className={styles.date}>{date}</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>

      <div className={styles.read_more}>
        <EmptyButton text="Подробнее" to={`/news/${slug}`} />
      </div>
    </div>
  </div>
);

export default NewsItem;
