import React from 'react';
import vk from 'assets/images/contacts/vk.svg';
import styles from './ContactsSocials.module.scss';

const ContactsSocials = () => (
  <a href="https://vk.com/fosgroupkrsk/" target="_blank" className={styles.vk} rel="noreferrer">
    <img src={vk} alt="ВК" />
  </a>
);

export default ContactsSocials;
