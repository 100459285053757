import React from 'react';
import { useParams } from 'react-router-dom';
import { laboratoryAPI } from 'stateManager/services';
import { BreadCrumbs, HelmetHandle, Loading } from 'components/universal_components';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import styles from './LaboratoryItemPage.module.scss';

const LaboratoryItemPage = () => {
  const params = useParams();
  const { data: article, isFetching } = laboratoryAPI.useFetchSingleLaboratoryArticleQuery(params.slug);

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!article) {
    return <>not found</>;
  }

  function createMarkup() {
    return { __html: article?.text };
  }

  const BREAD_CRUMBS = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Лаборатория',
      link: '/laboratory',
    },
    {
      title: article && `${article.title}`,
      link: `/laboratory/ ${article.slug}`,
    },
  ];

  // useTitle(article.title);

  return (
    <article itemScope itemType="http://schema.org/BlogPosting" className="page_container">
      <HelmetHandle
        image={article.cropped_image}
        title={article.title}
        metaTitle={article.meta_title}
        description={metatags.description}
        metaDescription={article.meta_description}
        product="true"
      />

      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>

      <h1 itemProp="name">{article?.title}</h1>

      <div className={styles.image}>
        <img itemProp="image" src={article?.image} alt={article?.title} />
      </div>

      <div />
      <div
        itemProp="description"
        className={styles.article}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={createMarkup()}
      />
    </article>
  );
};

export default LaboratoryItemPage;
