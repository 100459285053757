/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';

import pic1 from 'assets/images/main/pic_catalog.webp';
import pic2 from 'assets/images/main/pic_sales.webp';
import pic3 from 'assets/images/main/pic_docs_2.webp';
import { useWindowSize } from 'hooks';
import { classnames } from 'utils';
// eslint-disable-next-line import/no-unresolved
import styles from './PromoSection.module.scss';

const PromoSection = () => {
  const [width] = useWindowSize();
  return (
    <div className={styles.promo}>
      <ul className={`${styles.links} page_container`}>
        <li className={classnames([styles.item, styles.catalog])}>
          <Link to="/catalog">
            <div className={styles.image}>
              <img src={width > 767 ? pic1 : pic3} alt="" />
            </div>
            <h3>
              <span>Каталог</span>
            </h3>
          </Link>
        </li>
        <li className={classnames([styles.item, styles.sales])}>
          <Link to="/promotion">
            <div className={styles.image}>
              <img src={pic2} alt="" />
            </div>
            <h3>
              Новинки
              <br />
              Акции
            </h3>
          </Link>
        </li>
        <li className={classnames([styles.item, styles.catalog, styles.download])}>
          <Link to="/documentation">
            <div className={styles.image}>
              <img src={pic3} alt="" />
            </div>
            <h3>
              Документация
            </h3>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default PromoSection;
