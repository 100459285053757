import React from 'react';
import { classnames } from 'utils';
import styles from './CustomInput.module.scss';

const TextArea = ({
  validationErrors,
  required,
  value,
  setIsActive,
  setIsFilled,
  setValidationErrors,
  onChange,
  onBlur,
}) => (
  <textarea
    className={classnames([[styles.input_error, validationErrors?.length > 0]])}
    type="textarea"
    required={required}
    value={value}
    onFocus={() => setIsActive(true)}
    onBlur={() => {
      setIsActive(false);
      onBlur();
    }}
    onChange={(event) => {
      setIsFilled(!!event.target.value);
      setValidationErrors([]);
      onChange(event.target.value);
    }}
  />
);

export default TextArea;
