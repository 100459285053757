import React from 'react';
import ReactPaginate from 'react-paginate';
import { classnames } from 'utils';
import { useSearchParams } from 'react-router-dom';
import styles from './Pagination.module.scss';

const Pagination = ({ pageCount, page, variant = '' }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currPage = searchParams.get('page') ? +searchParams.get('page') - 1 : page;
  return (
    <ReactPaginate
      nextLabel="Далее >"
      onPageChange={(el) => {
        setSearchParams((params) => {
          params.set('page', el.selected + 1);
          return params;
        });
      }}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      pageCount={pageCount}
      previousLabel="< Назад"
      pageClassName={styles.page_item}
      pageLinkClassName={styles.page_link}
      previousClassName={classnames([styles.page_item, styles.prev])}
      previousLinkClassName={classnames([styles.page_link, styles.prev])}
      nextClassName={classnames([styles.page_item, styles.next])}
      nextLinkClassName={classnames([styles.page_link, styles.next])}
      breakLabel="..."
      breakClassName={styles.page_item}
      breakLinkClassName={styles.page_link}
      containerClassName={classnames([styles.pagination, styles[variant]])}
      activeClassName={styles.active}
      disabledClassName={styles.disable}
      disabledLinkClassName={styles.disable}
      renderOnZeroPageCount={null}
      forcePage={currPage}
    />
  );
};

export default Pagination;
