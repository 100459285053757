import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const newsAPI = createApi({
  reducerPath: 'news',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllNews: build.query({
      query: (args = {}) => ({
        url: '/news/news/',
        params: args,
      }),
      transformResponse: (response) => ({ items: response.results, count: response.count }),
    }),
    fetchAllYears: build.query({
      query: () => ({
        url: '/news/news-years/',
      }),
    }),
    fetchSingleNews: build.query({
      query: (slug) => ({ url: `/news/news/${slug}/` }),
    }),
  }),
});
export default newsAPI;
