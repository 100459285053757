import React, { useEffect, useState } from 'react';
import proceedGif from 'assets/images/proceed.gif';
import dealersFormAPI from 'stateManager/services/DealersFormService';
import { emailValidator, handleServerError, notEmptyValidator, phoneValidator, setValidationErrors } from 'utils';
import { BlueButton, ConsentPersonalData, CustomForm, CustomInput, ModalWindow } from 'components/universal_components';
import styles from './DealersSection.module.scss';

const DealersSection = () => {
  const [name, setName] = useState('');
  const [nameValidationErrors, setNameValidationErrors] = useState([]);

  const [phone, setPhone] = useState('');
  const [phoneValidationErrors, setPhoneValidationErrors] = useState([]);

  const [email, setEmail] = useState('');
  const [emailValidationErrors, setEmailValidationErrors] = useState([]);

  const [description, setDescription] = useState('');
  const [descriptionValidationErrors, setDescriptionValidationErrors] = useState([]);

  const fields = [
    {
      name: 'fio',
      inputType: 'text',
      placeholder: 'Имя',
      required: true,
      value: name,
      onChange: (value) => setName(value),
      validators: [notEmptyValidator],
      validationErrors: nameValidationErrors,
      setValidationErrors: (value) => {
        setNameValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'phone',
      inputType: 'tel',
      placeholder: 'Телефон',
      required: true,
      value: phone,
      onChange: (value) => setPhone(value),
      validators: [phoneValidator, notEmptyValidator],
      validationErrors: phoneValidationErrors,
      setValidationErrors: (value) => {
        setPhoneValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'email',
      inputType: 'email',
      placeholder: 'Email',
      required: true,
      value: email,
      onChange: (value) => setEmail(value),
      validators: [notEmptyValidator, emailValidator],
      validationErrors: emailValidationErrors,

      setValidationErrors: (value) => {
        setEmailValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'description',
      inputType: 'text',
      placeholder: 'Ваша деятельность',
      required: true,
      value: description,
      onChange: (value) => setDescription(value),
      validators: [notEmptyValidator],
      validationErrors: descriptionValidationErrors,
      setValidationErrors: (value) => {
        setDescriptionValidationErrors((state) => setValidationErrors(state, value));
      },
    },
  ];

  const [isAgree, setIsAgree] = useState(false);
  const [sendForm, { isLoading, isSuccess, isError: isFetchError, error }] = dealersFormAPI.useCreateRequestMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  const sendData = () => {
    if (isAgree) {
      const dataToSend = {};
      let isError = false;
      fields.forEach((field) => {
        field.validators.forEach((validateFunction) => {
          if (validateFunction.func(field.value)) {
            dataToSend[field.name] = field.value;
          } else {
            isError = true;
            field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
          }
        });
      });

      if (!isError) {
        sendForm(dataToSend);
      }
    }
  };

  if (isLoading) {
    return (
      <ModalWindow>
        <img src={proceedGif} alt="" style={{ width: `${60}px`, height: `${60}px` }} />;
      </ModalWindow>
    );
  }

  if (isSuccess) {
    return (
      <ModalWindow>
        <div className={styles.success_sending}>
          <p>Вопрос отправлен!</p>
          <p>Менеджер свяжется с&nbsp;вами в&nbsp;ближайшее время</p>
        </div>
      </ModalWindow>
    );
  }

  return (
    <section className={styles.dealers} id="dealers">
      <h2 className={styles.title}>Стать нашим дилером</h2>

      <div className={styles.wrapper}>

        <CustomForm fields={fields} styles={styles} />
        <div className={styles.agree}>
          <CustomInput inputType="checkbox" checked={isAgree} onChange={(value) => setIsAgree(value)} />
          <ConsentPersonalData setIsAgree={setIsAgree} />
        </div>
        <BlueButton text="Отправить" disabled={!isAgree} variant="diller" onClick={sendData} />
      </div>
    </section>
  );
};

export default DealersSection;
