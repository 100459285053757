/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// import { renderToString } from 'react-dom/server';
import App from './App';

import './index.css';
import store from './stateManager';
import reportWebVitals from './reportWebVitals';

import { ScrollToTop } from './components/universal_components';

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
);

reportWebVitals();
