import React from 'react';
import { BreadCrumbs, HelmetHandle } from 'components/universal_components';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import ContactsContacts from './ContactsContacts/ContactsContacts';
import ContactsFilialsMap from './ContactsFilialsMap/ContactsFilialsMap';
import ContactsMap from './ContactsMap/ContactsMap';
import styles from './ContactsPage.module.scss';
import ContactsSocials from './ContactsSocials/ContactsSocials';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Контакты',
    link: '/contacts',
  },
];

// useTitle('Контакты');

const ContactsPage = () => (
  <div itemScope itemType="https://schema.org/ContactPage" className={styles.wrapper}>
    <HelmetHandle
      title="Контакты"
      metaTitle="Контакты"
      description={metatags.description}
      metaDescription={metatags.description}
      image={metatags.image}
    />
    <div className="page_container">
      <div className="breadCrumbs">
        <BreadCrumbs items={BREAD_CRUMBS} />
      </div>

      <h1 className={styles.title}>Контакты</h1>

      <div itemProp="name" className={styles.name}>Общество с ограниченной ответственностью «ФОС ГРУПП»</div>

      <div itemProp="address" className={styles.contacts}>
        <h2 className="visually-hidden">Адрес и телефон</h2>
        <ContactsContacts />
      </div>

      <section itemScope itemType="https://schema.org/SocialMediaPosting" className={styles.socials}>
        <h2 className="visually-hidden">Социальные сети</h2>
        <ContactsSocials />
      </section>

      <section className={styles.map}>
        <h2 className="visually-hidden">Головной офис</h2>
        <ContactsMap />
      </section>

      <div className={styles.separator} />

      <section className={styles.filials__map}>
        <h2 className={styles.filials__title}>Наши филиалы</h2>
        <ContactsFilialsMap />
      </section>
    </div>
  </div>
);

export default ContactsPage;
