/* eslint-disable max-len */
import React from 'react';
import { searchAPI } from 'stateManager/services';
import { HelmetHandle, Loading } from 'components/universal_components';
// import { useTitle } from 'hooks';
import { classnames } from 'utils';
import { useSearchParams } from 'react-router-dom';
import metatags from 'metatags';
import styles from './SearchPage.module.scss';
import CatalogPageProduct from '../CatalogPages/ProductsSection/CatalogPageProduct/CatalogPageProduct';
import SearchResultItem from './SearchResultItem/SearchResultItem';
import SearchInput from './SearchInput';

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const searchString = searchParams.get('search');
  const { data: searchResults, isFetching } = searchAPI.useFetchAllSearchsQuery(searchString);

  // useTitle('Результаты поиска');

  if (isFetching) {
    return (
      <div className={styles.search}>
        <div className="page_container">
          <h1 className="visually-hidden">Результаты поиска</h1>

          <SearchInput />

          <Loading />
        </div>
      </div>
    );
  }

  if (!(searchResults?.products_by_article.length || searchResults?.products.length || searchResults?.news.length || searchResults?.blog.length)) {
    return (
      <div className={styles.search}>
        <HelmetHandle
          title="Результаты поиска"
          metaTitle="Результаты поиска"
          description={metatags.description}
          metaDescription={metatags.description}
          image={metatags.image}
        />
        <div className="page_container">
          <h1 className="visually-hidden">Результаты поиска</h1>
          <SearchInput />
          <p className={styles.notFound}>По вашему запросу ничего не найдено</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.search}>
      <HelmetHandle
        title="Результаты поиска"
        metaTitle="Результаты поиска"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className="page_container">
        <h1 className="visually-hidden">Результаты поиска</h1>

        <SearchInput />

        {!!searchResults.products_by_article.length && (
          <section className={classnames([styles.products, styles.section])}>
            <h2 className={styles.title}>Товары (найденные по артикулу)</h2>

            <div className={styles.inner}>
              {searchResults.products_by_article.slice(0, 8).map((item) => (
                <CatalogPageProduct {...item} key={item.id} />
              ))}
            </div>
          </section>
        )}

        {!!searchResults.products.length && (
          <section className={classnames([styles.products, styles.section])}>
            <h2 className={styles.title}>Товары (найденные по названию)</h2>

            <div className={styles.inner}>
              {searchResults.products.slice(0, 8).map((item) => (
                <CatalogPageProduct {...item} key={item.id} />
              ))}
            </div>
          </section>
        )}

        {!!searchResults.news.length && (
          <section className={classnames([styles.news, styles.section])}>
            <h2 className={styles.title}>Новости</h2>

            {searchResults.news.map((item) => (
              <SearchResultItem {...item} page="news" key={item.id} />
            ))}
          </section>
        )}

        {!!searchResults.blog.length && (
          <section className={classnames([styles.blog, styles.section])}>
            <h2 className={styles.title}>Блог</h2>

            {searchResults.blog.map((item) => (
              <SearchResultItem {...item} page="blog" key={item.id} />
            ))}
          </section>
        )}

      </div>
    </div>

  );
};

export default SearchPage;
