import React, { useState } from 'react';
import { CustomSlider, ModalWindow, NextArrow, PrevArrow } from 'components/universal_components';
import { useWindowSize } from 'hooks';
import styles from './ProjectImages.module.scss';
import ProjectImage from './ProjectImage/ProjectImage';

const sliderItem = (props) => (
  <div className={styles.image}>
    <ProjectImage
      {...props}
      activateModal={props.activateModal}
    />
  </div>
);

const ProjectImages = ({ images }) => {
  const [width] = useWindowSize();

  const [modalImageNumber, setModalImageNumber] = useState(false);

  const setPrevImage = (event) => {
    event.stopPropagation();
    if (modalImageNumber) {
      setModalImageNumber(modalImageNumber - 1);
    } else {
      setModalImageNumber(images.length - 1);
    }
  };

  const setNextImage = (event) => {
    event.stopPropagation();
    if (modalImageNumber >= images.length - 1) {
      setModalImageNumber(0);
    } else {
      setModalImageNumber(modalImageNumber + 1);
    }
  };

  const getSlidesToShow = () => {
    if (width > 1365) {
      return 4;
    } if (width > 767) {
      return 2;
    }
    return 1;
  };

  const settings = {
    slidesToShow: getSlidesToShow(),
    slidesToScroll: 1,
    infinite: false,
  };

  return (
    <div className={styles.wrapper}>
      <CustomSlider
        SliderItem={sliderItem}
        data={images.map((image, index) => ({ ...image, activateModal: () => setModalImageNumber(index) }))}
        settings={settings}
        variant="black"
      />
      {(modalImageNumber || modalImageNumber === 0) && (
        <div className={styles.modal}>
          <ModalWindow setElementWindow={setModalImageNumber}>
            <>
              {images.length > 1 && (
              <button type="button" className={styles.prev} onClick={setPrevImage}>
                <div className="visually-hidden">Предыдущая</div>
                <PrevArrow />
              </button>
              ) }
              <img itemProp="image" src={images[modalImageNumber].image} alt="Фотография" />
              {images.length > 1 && (
              <button type="button" className={styles.next} onClick={setNextImage}>
                <div className="visually-hidden">Предыдущая</div>
                <NextArrow />
              </button>
              )}
            </>
          </ModalWindow>
        </div>
      )}
    </div>
  );
};

export default ProjectImages;
