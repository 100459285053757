import React from 'react';
import { classnames } from 'utils';
import CustomInput from '../CustomInput/CustomInput';

const CustomForm = ({ fields, styles, variant = 'white' }) => (
  <div className={styles.fields}>
    {fields.map((field) => (
      <div className={classnames([styles[field.name], styles.field])} key={field.name}>
        <CustomInput
          inputType={field.inputType}
          placeholder={field.placeholder}
          required={field.required}
          value={field.value}
          undefinedValue={field.undefinedValue}
          onChange={field.onChange}
          validationErrors={field.validationErrors}
          setValidationErrors={field.setValidationErrors}
          onBlur={() => {
            field.validators.forEach((validateFunction) => {
              if (!validateFunction.func(field.value)) {
                field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
              }
            });
          }}
          variant={variant}
        />
      </div>
    ))}
  </div>
);

export default CustomForm;
