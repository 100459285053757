/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { BlueButton, CustomForm, CustomInput, Loading } from 'components/universal_components';
import { authAPI } from 'stateManager/services';
import { handleServerError, notEmptyValidator, passwordValidators, setValidationErrors } from 'utils';
import styles from './ChangeInfoModal.module.scss';

const ChangePassword = ({ setModalState }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputValidationErrors, setInputValidationErrors] = useState([]);

  const [retypedPassword, setRetypedPassword] = useState('');
  const [retypedPasswordValidationErrors, setRetypedPasswordValidationErrors] = useState([]);
  const fields = [
    {
      name: 'new_password',
      inputType: 'password',
      placeholder: 'Новый пароль',
      required: true,
      value: inputValue,
      onChange: (value) => setInputValue(value),
      validators: [notEmptyValidator, ...passwordValidators],
      validationErrors: inputValidationErrors,
      setValidationErrors: (value) => {
        setInputValidationErrors((state) => setValidationErrors(state, value));
      },
    },

  ];

  const [sendForm, { isLoading, isError: isFetchError, error }] = authAPI.useChangePasswordMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  if (isLoading) {
    return <Loading />;
  }

  const sendData = async () => {
    if (inputValue !== retypedPassword) {
      setRetypedPasswordValidationErrors([{ text: 'Пароли не совпадают', id: 'password' }]);
      return;
    }
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      const res = await sendForm(dataToSend);
      if (!res.error) {
        localStorage.removeItem('token');
        setModalState();
        window.location = '/';
      } else {
        handleServerError(res.error);
      }
    }
  }; return (
    <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
      <CustomForm fields={fields} styles={styles} />
      <div className={styles.password_second}>
        <CustomInput
          inputType="password"
          onBlur={() => {
            if (inputValue !== retypedPassword) {
              setRetypedPasswordValidationErrors([{ text: 'Пароли не совпадают', id: 'password' }]);
            }
          }}
          onChange={setRetypedPassword}
          validationErrors={retypedPasswordValidationErrors}
          setValidationErrors={setRetypedPasswordValidationErrors}
          placeholder="Повторите пароль"
        />
      </div>

      <BlueButton text="Сохранить" onClick={sendData} />
    </div>
  );
};

export default ChangePassword;
