import React from 'react';
import { CustomInput } from 'components/universal_components';
import { useSearchParams } from 'react-router-dom';
import styles from './SearchPage.module.scss';

const SearchInput = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className={styles.sectionSearch}>
      <CustomInput placeholder="Bведите запрос" inputType="text" onChange={(value) => { setSearchParams({ ...searchParams, search: value }); }} value={searchParams.get('search')} />
    </div>
  );
};

export default SearchInput;
