import React from 'react';
import { authAPI } from 'stateManager/services';
import metatags from 'metatags';
import { HelmetHandle } from 'components/universal_components';
import styles from './PromotionPage.module.scss';
import ProductsSection from '../CatalogPages/ProductsSection/ProductsSection';

const PromotionPage = () => {
  const newFilters = {
    is_new: true,
  };
  const promoFilters = { old_price: true };

  const { currentData } = authAPI.useGetInfoQuery();
  const isDealer = currentData?.status === 'dealer';

  return (
    <div className="page_container">
      <HelmetHandle
        title="Новинки и акции"
        metaTitle="Новинки и акции"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <h1 className="visually-hidden">Новинки и акции</h1>
      <div className={styles.new}>
        <h2 className={styles.title}>
          Новинки
        </h2>
        <div className={styles.content}>
          <ProductsSection
            variant="white"
            pagination={false}
            filters={newFilters}
          />
        </div>
      </div>

      {!isDealer && (
        <div className={styles.promo}>
          <h2 className={styles.title}>
            Акции
          </h2>
          <div className={styles.content}>
            <ProductsSection
              variant="white"
              pagination={false}
              filters={promoFilters}
            />
          </div>
        </div>
      ) }
    </div>
  );
};

export default PromotionPage;
