import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { BreadCrumbs, EmptyButton, HelmetHandle, Loading, Pagination } from 'components/universal_components';
import { newsAPI } from 'stateManager/services';
import { dateFormater } from 'utils';
// import { useTitle } from 'hooks';
import metatags from 'metatags';
import styles from './NewsPage.module.scss';
import YearsSection from './YearsSection';

const BREAD_CRUMBS = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Компания',
    link: '/about',
  },
  {
    title: 'Новости',
    link: '/news',
  },
];

const LIMIT_NEWS_ON_PAGE = 20;

const NewsPage = () => {
  // useTitle('Новости');

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') ? +searchParams.get('page') - 1 : 0;
  const offset = page * LIMIT_NEWS_ON_PAGE;
  const yearFilter = searchParams.get('year') || '';
  const setYearFilter = (year) => {
    setSearchParams(
      (params) => {
        params.set('year', year);
        return params;
      },
    );
  };

  const { data, isFetching } = newsAPI.useFetchAllNewsQuery(
    { limit: LIMIT_NEWS_ON_PAGE,
      offset,
      year: yearFilter || undefined },
  );
  const { items: news, count } = data || { items: {}, count: 0 };
  const pageCount = Math.ceil(count / LIMIT_NEWS_ON_PAGE);

  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  if (!news) {
    return <>not found</>;
  }

  return (
    <div className={styles.wrapper}>
      <HelmetHandle
        title="Новости"
        metaTitle="Новости"
        description={metatags.description}
        metaDescription={metatags.description}
        image={metatags.image}
      />
      <div className="page_container">
        <div className="breadCrumbs">
          <BreadCrumbs items={BREAD_CRUMBS} />
        </div>

        <h1 className={styles.title}>Новости</h1>

        <ul className={styles.period}>
          <li>
            <button type="button" onClick={() => setYearFilter('')}>
              За всё время
            </button>
          </li>
          <YearsSection
            setYearFilter={(value) => {
              setYearFilter(value);
              setSearchParams(
                (params) => {
                  params.set('page', 1);
                  return params;
                },
              );
            }}
            yearFilter={yearFilter}
          />
        </ul>

        <section className={styles.newsSection}>
          {(yearFilter ? news.filter((item) => item.date.slice(0, 4) === yearFilter) : news).map((el) => (
            <article className={styles.newsItem} key={el.id}>
              <div className={styles.newsWrapper}>
                <p className={styles.date}>{dateFormater(el.date)}</p>
                <h2 className={styles.articleTitle}>{el.title}</h2>
                <p className={styles.description}>{el.short_description}</p>

                <div className={styles.moreInfo}>
                  <EmptyButton text="Подробнее" to={`/news/${el.slug}`} />
                </div>
              </div>

              <Link to={`/news/${el.slug}`} className={styles.image}>
                <img src={el.cropped_image} alt={el.title} />
              </Link>
            </article>
          ))}
          {pageCount > 1 && <Pagination pageCount={pageCount} page={page} /> }
        </section>

      </div>
    </div>
  );
};

export default NewsPage;
