import React, { forwardRef } from 'react';
import { catalogAPI } from 'stateManager/services';
import { classnames } from 'utils';
import { EmptyButton, Loading } from 'components/universal_components';
import styles from './CatalogPageFilters.module.scss';
import ChoiceFilter from './ChoiceFilter/ChoiceFilter';

const FASTENING_TYPE_FILTER_NAME = 'fastening_type';

const constactFilter = (value) => (filteringValue) => {
  const { id } = filteringValue;
  return value.map((fasteningType) => fasteningType.id).includes(id);
};

const FasteningTypeFilter = forwardRef((props, ref) => {
  const { data: fasteningTypes, isFetching } = catalogAPI.useFetchAllFasteningTypesQuery(20);
  if (isFetching) {
    return (
      <div className="page_container">
        <Loading />
      </div>
    );
  }
  return (
    <div className={classnames([styles.filterWrapper, 'ignoreCloseFilter', [styles.filled, props.filters.fastening_type?.length]])}>
      <EmptyButton
        text="Тип крепления"
        variant="black"
        onClick={() => {
          props.setActiveFilterModal(
            FASTENING_TYPE_FILTER_NAME === props.activeFilterModal ? '' : FASTENING_TYPE_FILTER_NAME,
          );
        }}
      />
      <ChoiceFilter
        choices={fasteningTypes}
        isModalActive={FASTENING_TYPE_FILTER_NAME === props.activeFilterModal}
        setIsModalActive={props.setActiveFilterModal}
        removeFilter={props.removeFilter}
        addFilter={props.addFilter}
        filterPrefix="fastening_type"
        constactFilter={constactFilter}
        filters={props.filters}
        ref={ref}
      />
    </div>
  );
});

export default FasteningTypeFilter;
