import React from 'react';
import { classnames } from 'utils';
import styles from './CustomInput.module.scss';

const CheckboxInput = ({
  validationErrors,
  required,
  checked,
  setIsActive,
  setIsFilled,
  setValidationErrors,
  onChange,
}) => (
  <input
    className={classnames([[styles.input_error, validationErrors?.length > 0]])}
    type="checkbox"
    required={required}
    checked={checked}
    onFocus={() => setIsActive(true)}
    onBlur={() => {
      setIsActive(false);
    }}
    onChange={(event) => {
      setIsFilled(event.target.checked);
      setValidationErrors([]);
      onChange(event.target.checked);
    }}
  />
);

export default CheckboxInput;
