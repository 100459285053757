import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const orderAPI = createApi({
  reducerPath: 'orderAPI',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    } }),
  tagTypes: ['Orders'],
  endpoints: (build) => ({
    createOrder: build.mutation({
      query: (data) => ({
        url: '/orders/orders/',
        method: 'POST',
        body: { items: data.map((pr) => ({ product: pr.id, quantity: pr.quantity })) },
      }),
      invalidatesTags: ['Orders'],
    }),
    fetchAllOrders: build.query({
      query: () => ({
        url: '/orders/orders/',
      }),
      transformResponse: (response) => response.results,
      providesTags: ['Orders'],
    }),

  }),
});
export default orderAPI;
