import React from 'react';
import styles from './HistorySection.module.scss';
import HistoryItem from './HistoryItem/HistoryItem';

const HistorySection = () => (
  <div className={styles.wrapper}>
    <div className={styles.title}>Наша история</div>
    <div className={styles.items}>
      <div className={styles.item}>
        <HistoryItem year="2016" text="Основание компании" />
      </div>
      <div className={styles.item}>
        <HistoryItem year="2020" text="Появление участка лазерной резки" />
      </div>
      <div className={styles.item}>
        <HistoryItem year="2021" text="Получение гранта от Фонда содействия инновациям" />
      </div>
      <div className={styles.item}>
        <HistoryItem
          year="2022"
          text="Регистрация торговой марки FOS. Обновление оборудования, расширение складских запасов"
        />
      </div>
    </div>
  </div>
);

export default HistorySection;
