import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const teamAPI = createApi({
  reducerPath: 'team',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    fetchAllMembers: build.query({
      query: (args = {}) => ({
        url: '/team/members/',
        params: args,
      }),
      transformResponse: (response) => response.results,
    }),
  }),
});
export default teamAPI;
