import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Like } from 'assets/images/heart.svg';
import { classnames, moneyFormatter } from 'utils';
import { BlueButton } from 'components/universal_components';
import { catalogAPI } from 'stateManager/services';
import { useAuth, useWindowSize } from 'hooks';
import { ReactComponent as Cross } from 'assets/images/cross.svg';
import CatalogCard from '../../CatalogCard';
import styles from './CatalogPageProduct.module.scss';

const CatalogPageProduct = ({
  title,
  image,
  subcategory,
  price,
  power,
  protection,
  temperature,
  min_price: minPrice,
  old_price: oldPrice,
  wholesale_price: wholesale,
  light_flow: lightFlow,
  slug,
  id,
  type,
  sizes,
  weight,
  coparisonId,
  is_new: newProduct,
  isActive = false }) => {
  const { isAuth } = useAuth();

  const [windowWidth] = useWindowSize();
  const [deleteCoparsion] = catalogAPI.useDeleteFromCoparisonMutation();

  const { data: likes } = catalogAPI.useFetchAllLikesQuery();
  const isLikeActive = likes?.includes(id);
  const [setLike] = catalogAPI.useCreateLikeMutation();
  const [deleteLike] = catalogAPI.useDeleteLikeMutation();
  const { pathname } = useLocation();

  return (
    <CatalogCard itemProp="itemListElement" itemScope itemType="http://schema.org/Product" isActive={isActive}>
      <div className={classnames([styles.wrapper, styles[type]])}>
        <Link to={`/catalog/products/${slug}`} className={styles.image}>
          <img itemProp="image" src={image} alt={title} />

          {newProduct &&
            <div className={styles.labelNew}>New</div>}
        </Link>

        {pathname === '/cabinet/coparisons' && (
        <button
          onClick={() => deleteCoparsion(coparisonId)}
          className={styles.deleteButton}
          type="button"
        >
          <Cross />
        </button>
        )}

        <div className={classnames([styles.text, styles[type]])}>
          <p className={styles.subcategory}>{subcategory.title}</p>
          {windowWidth < 768
            ? <Link to={`/catalog/products/${slug}`} className={styles.title}>{title}</Link>
            : <h3 itemProp="name" className={styles.title}>{title}</h3>}

          <div className={styles.priceWrapper}>
            <p
              className={classnames([styles.price, [styles.action, !wholesale && oldPrice > 0]])}
              data={oldPrice > 0 ? `${oldPrice} ₽` : ''}
            >
              <span className={styles.typePrice}>Цена</span>
              <span itemProp="price" content={price} className={styles.cost}>{moneyFormatter(price)}</span>
              {!wholesale && oldPrice && (<span className={styles.old}>{moneyFormatter(oldPrice)}</span>)}
            </p>

            {wholesale && (
            <>
              <p className={classnames([styles.price, styles.min_price])}>
                <span className={styles.typePrice}>Минимальная&nbsp;цена</span>
                <span className={styles.cost}>{moneyFormatter(minPrice)}</span>
              </p>
              <p className={classnames([styles.price, styles.wholesale])}>
                <span className={styles.typePrice}>Оптовая&nbsp;цена</span>
                <span className={styles.cost}>{moneyFormatter(wholesale)}</span>
              </p>
            </>
            )}
          </div>

          <div className={styles.moreInfo}>
            <div className={styles.moreInfoItem}>
              <div className={styles.moreInfoTitle}>Габариты:</div>
              <div className={styles.moreInfoValue}>{sizes} см</div>
            </div>
            <div className={styles.moreInfoItem}>
              <div className={styles.moreInfoTitle}>Вес:</div>
              <div className={styles.moreInfoValue}>{weight} кг</div>
            </div>
            <div className={styles.moreInfoItem}>
              <div className={styles.moreInfoTitle}>Световой поток:</div>
              <div className={styles.moreInfoValue}>{lightFlow} лм</div>
            </div>
            <div className={styles.moreInfoItem}>
              <div className={styles.moreInfoTitle}>Мощность:</div>
              <div className={styles.moreInfoValue}>{power} Вт</div>
            </div>
            <div className={styles.moreInfoItem}>
              <div className={styles.moreInfoTitle}>Степень защиты:</div>
              <div className={styles.moreInfoValue}>{protection}</div>
            </div>
            <div className={styles.moreInfoItem}>
              <div className={styles.moreInfoTitle}>Температура эксплуатации:</div>
              <div className={styles.moreInfoValue}> от {temperature.start} до {temperature.end} °C</div>
            </div>
          </div>
          <div className={styles.links}>
            <BlueButton to={`/catalog/products/${slug}`} variant="readMore" text="Подробнее" />
            {isAuth && (
            <button className={classnames([styles.like, [styles.active, isLikeActive]])} type="button" onClick={() => (isLikeActive ? deleteLike(id) : setLike(id))}>
              <Like />
            </button>
            )}
          </div>
        </div>
      </div>
    </CatalogCard>
  );
};

export default CatalogPageProduct;
