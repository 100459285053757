import React from 'react';
import styles from './ContactsContactsItem.module.scss';

const ContactsContactsItem = ({ title, text, link = '' }) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>{title}</div>
    {link ? (
      <a href={link} className={styles.text}>
        {text}
      </a>
    ) : (
      <span className={styles.text}>{text}</span>
    )}
  </div>
);

export default ContactsContactsItem;
