import React from 'react';
import { Link } from 'react-router-dom';
import styles from './MainSection.module.scss';

const MainSection = () => (
  <div itemScope itemType="http://schema.org/Organization" className={styles.wrapper}>
    <h1 itemProp="name" className={styles.title}>Компания</h1>
    <div className={styles.navbar}>
      <Link to="/news">Новости</Link>
      <Link to="/articles">Блог</Link>
      <Link to="/about/cooperation">Сотрудничество</Link>
    </div>
    <div itemProp="description" className={styles.description}>
      <strong>С 2016 года наши инновационные системы освещают производственные площадки
        крупных промышленных предприятий,
        здания и улицы городов Сибири.
        Уникальные технологические решения обеспечивают надежное освещение
        даже при экстремальных температурах и в агрессивных средах.
        Наши инновационные разработки защищены несколькими патентами.
      </strong>
      {'\n'}
      {'\n'}
      Ранее продукция была известна под брендами PROLEDSYSTEM и VECTOR.
      Создание нового бренда FOS в 2022 году стало новым этапом развития,
      который в полной мере отражает характер, идеологию и стремление компании.
      {'\n'}
      {'\n'}
      На сегодняшний день ООО «ФОС ГРУПП»
      является лидером среди производителей светодиодных светильников в Красноярском крае.
      {'\n'}
      {'\n'}
      <strong>Миссия компании</strong> – улучшать условия труда и жизни людей
      через внедрение инноваций и сервиса в сфере надежного и качественного света.
      {'\n'}
      <strong>Наши преимущества</strong> – мощная производственная база
      и высокий уровень экспертизы на всех этапах производства.
      {'\n'}
      {'\n'}
      Основные линейки выпускаемой продукции –
      уличное освещение, промышленное освещение, светильники для эксплуатации
      в средах с высокой температурой, светильники для химически агрессивных сред,
      взрывозащищенные светильники, светильники для РЖД, коммерческое освещение, архитектурное освещение.
    </div>
  </div>
);

export default MainSection;
