/* eslint-disable max-len */
import React from 'react';

const VideoSection = () => (
  <iframe
    width="1835"
    height="650"
    src="https://rutube.ru/play/embed/ce8e4a1f0b97a630f9045f1a072ef612/"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    webkitAllowFullScreen
    mozallowfullscreen
    allowFullScreen
    title="YouTube video player"
  />

);

export default VideoSection;
