import React, { useState, useRef } from 'react';

import arrow2 from 'assets/images/arrow2.svg';

import styles from './ConsentPersonalData.module.scss';
import BlueButton from '../BlueButton/BlueButton';

const ConsentPersonalData = ({ setIsAgree }) => {
  const [isOpen, setIsOpen] = useState(false);
  const textRef = useRef();

  const scrollToTop = () => {
    textRef.current.scrollTo(0, textRef.current.scrollTop - 30);
  };

  const scrollToBottom = () => {
    textRef.current.scrollTo(0, textRef.current.scrollTop + 30);
  };

  return (
    <>
      <p className={styles.consent}>
        <span>Соглашаюсь&nbsp;на&nbsp;обработку{' '}</span>
        <button type="button" onClick={() => setIsOpen(true)}>персональных данных
        </button>
      </p>
      {isOpen && (
        <div className={styles.modal}>
          <div className={styles.content}>
            <div ref={textRef} className={styles.text}>
              <p>
                Настоящим я, далее – «Субъект Персональных Данных», во
                исполнение требований Федерального закона от 27.07.2006 г. №
                152-ФЗ «О персональных данных» (с изменениями и дополнениями)
                свободно, своей волей и в своем интересе даю свое согласие
                организации ООО «ФОС ГРУПП» (далее – Компания, юридический адрес:
                Россия, г. Красноярск, ул. Пограничников, д. 31) на обработку своих персональных данных, указанных путем
                заполнения веб-формы на сайте fosgroup.ru и его поддоменов
                *.fosgroup.ru (далее – Сайт), направляемой (заполненной) с
                использованием Сайта.{' '}
              </p>
              <p>
                Под персональными данными я понимаю любую информацию,
                относящуюся ко мне как к Субъекту Персональных Данных, в том
                числе мои фамилию, имя, отчество, адрес, образование, профессию,
                контактные данные (телефон, факс, электронная почта, почтовый
                адрес), фотографии, иную другую информацию. Под обработкой
                персональных данных я понимаю сбор, систематизацию, накопление,
                уточнение, обновление, изменение, использование,
                распространение, передачу, в том числе трансграничную,
                обезличивание, блокирование, уничтожение, бессрочное хранение),
                и любые другие действия (операции) с персональными данными.
              </p>
              <p>
                Обработка персональных данных Субъекта Персональных Данных
                осуществляется исключительно в целях регистрации Субъекта
                Персональных Данных в базе данных Компании с последующим
                направлением Субъекту Персональных Данных почтовых сообщений и
                смс-уведомлений, в том числе рекламного содержания, от Компании,
                его аффилированных лиц и/или субподрядчиков, информационных и
                новостных рассылок, приглашений на мероприятия Компании и другой
                информации рекламно-новостного содержания, а также с целью
                подтверждения личности Субъекта Персональных Данных при
                посещении мероприятий Компании.
              </p>
              <p>
                Датой выдачи согласия на обработку персональных данных Субъекта
                Персональных Данных является дата отправки веб-формы с Сайта
                Компании.
              </p>
              <p>
                Обработка персональных данных Субъекта Персональных Данных может
                осуществляться с помощью средств автоматизации и/или без
                использования средств автоматизации в соответствии с действующим
                законодательством РФ и внутренними положениями Компании.
              </p>
              <p>
                Компания принимает необходимые правовые, организационные и
                технические меры или обеспечивает их принятие для защиты
                персональных данных от неправомерного или случайного доступа к
                ним, уничтожения, изменения, блокирования, копирования,
                предоставления, распространения персональных данных, а также от
                иных неправомерных действий в отношении персональных данных, а
                также принимает на себя обязательство сохранения
                конфиденциальности персональных данных Субъекта Персональных
                Данных. Компания вправе привлекать для обработки персональных
                данных Субъекта Персональных Данных субподрядчиков, а также
                вправе передавать персональные данные для обработки своим
                аффилированным лицам, обеспечивая при этом принятие такими
                субподрядчиками и аффилированными лицами соответствующих
                обязательств в части конфиденциальности персональных данных.
              </p>
              <p>Я ознакомлен(а), что:</p>
              <ul>
                <li>
                  настоящее согласие на обработку моих персональных данных,
                  указанных при регистрации на Сайте Компании, направляемых
                  (заполненных) с использованием Cайта, действует в течение 20
                  (двадцати) лет с использования формы на Cайте Компании;
                </li>
                <li>
                  согласие может быть отозвано мною на основании письменного
                  заявления в произвольной форме;
                </li>
                <li>
                  предоставление персональных данных третьих лиц без их согласия
                  влечет ответственность в соответствии с действующим
                  законодательством Российской Федерации.
                </li>
              </ul>
            </div>
            <div className={styles.scrollBar}>
              <button onClick={scrollToTop} type="button">
                <div className="visually-hidden">Наверх</div>
                <img src={arrow2} alt="up" />
              </button>
              <button onClick={scrollToBottom} type="button">
                <img src={arrow2} alt="down" />
              </button>
            </div>
            <div className={styles.buttons}>
              <BlueButton
                onClick={() => {
                  setIsAgree(true);
                  setIsOpen(false);
                }}
                text="Принимаю"
              />
              <BlueButton
                onClick={() => {
                  setIsAgree(false);
                  setIsOpen(false);
                }}
                text="Не принимаю"
                variant="transparent"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsentPersonalData;
