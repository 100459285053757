import React, { useState } from 'react';
import { classnames } from 'utils';
import CheckboxInput from './CheckboxInput';
import styles from './CustomInput.module.scss';
import EmailInput from './EmailInput';
import NumberInput from './NumberInput';
import PasswordInput from './PasswordInput';
import PhoneInput from './PhoneInput';
import TextArea from './TextArea';
import TextInput from './TextInput';

const CustomInput = ({
  inputType,
  placeholder,
  onChange,
  validationErrors,
  setValidationErrors = () => {},
  value,
  undefinedValue,
  checked = false,
  onBlur = () => {},
  variant = '',
  required = false,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isFilled, setIsFilled] = useState(!!value && value !== undefinedValue);
  let inputComponent = '';

  switch (inputType) {
    case 'text':
      inputComponent = (
        <TextInput
          required={required}
          value={value}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onChange={onChange}
          onBlur={onBlur}
        />
      );
      break;
    case 'tel':
      inputComponent = (
        <PhoneInput
          required={required}
          value={value}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
      break;
    case 'email':
      inputComponent = (
        <EmailInput
          required={required}
          value={value}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
      break;
    case 'checkbox':
      inputComponent = (
        <CheckboxInput
          required={required}
          checked={checked}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
      break;
    case 'number':
      inputComponent = (
        <NumberInput
          required={required}
          value={value}
          undefinedValue={undefinedValue}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onChange={onChange}
          onBlur={onBlur}
          variant={variant}
        />
      );
      break;
    case 'textarea':
      inputComponent = (
        <TextArea
          required={required}
          checked={checked}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
      break;
    case 'password':
      inputComponent = (
        <PasswordInput
          required={required}
          value={value}
          setIsActive={setIsActive}
          setIsFilled={setIsFilled}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          onBlur={onBlur}
          onChange={onChange}
        />
      );
      break;
    default:
      inputComponent = null;
  }

  return (
    <div className={styles.wrapper}>
      {inputComponent}
      <span
        className={classnames([
          styles.placeholder,
          [styles.active, isActive],
          [styles.filled, isFilled],
          [styles.black, variant === 'black'],
        ])}
      >
        {placeholder} {required && ' *'}
      </span>

      {validationErrors &&
        validationErrors.map((error) => (
          <div key={error.id} className={styles.error}>
            {error.text}
          </div>
        ))}
    </div>
  );
};

export default CustomInput;
