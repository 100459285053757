import React from 'react';
import { classnames } from 'utils';
import styles from './CustomInput.module.scss';

const NumberInput = ({
  validationErrors,
  required,
  value,
  undefinedValue,
  setIsActive,
  setIsFilled,
  setValidationErrors,
  onChange,
  onBlur,
  variant,
}) => (
  <input
    onWheel={(event) => event.target.blur()}
    className={classnames([
      [styles.input_error, validationErrors?.length > 0],
      [styles.black, variant === 'black'],
      [styles.count, variant === 'count'],
    ])}
    type="number"
    required={required}
    value={value !== undefinedValue ? value : ''}
    onFocus={() => {
      setIsActive(true);
    }}
    onBlur={(e) => {
      setIsActive(false);
      onBlur(e);
    }}
    onChange={(event) => {
      setIsFilled(!!event.target.value);
      setValidationErrors([]);
      onChange(event.target.value ? event.target.value : undefinedValue);
    }}
  />
);

export default NumberInput;
