export const notEmptyValidator = {
  errorText: 'Поле не должно быть пустым',
  func: (value) => value.trim().length > 0,
  id: -1,
};

export const emailValidator = {
  errorText: 'Введите корректный email',
  func: (value) => {
    const regex =
      // eslint-disable-next-line max-len
      /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9-]+\.)+([a-zA-Z0-9\-.]+)+([;]([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9-]+\.)+([a-zA-Z0-9\-.]+))*$/;
    const emailContainsAtSymbol = value.trim().match(regex);
    const isFirstLevelDomainContainsAtleasTwoCharacter = value.split('.').slice(-1)[0].length > 1;
    return (isFirstLevelDomainContainsAtleasTwoCharacter && emailContainsAtSymbol) || value.length === 0;
  },
  id: -2,
};

export const phoneValidator = {
  errorText: 'Некорректный номер телефона',
  func: (value) => value.indexOf('_') === -1 || value.split('').filter((letter) => letter === '_').length === 10,
  id: -3,
};

export const positiveValidator = {
  errorText: 'Число должно быть не меньше 0',
  func: (value) => value > -1,
  id: -4,
};

export const minLengthValidator = {
  errorText: 'Не менее 8 символов',
  func: (value) => value.length >= 8 || value === '',
  id: -5,
};

export const letterValidator = {
  errorText: 'Пароль должен содержать буквы',
  func: (value) => !Number(value) || value === '' || value.length < 8,
  id: -6,
};

export const passwordValidators = [minLengthValidator, letterValidator];
