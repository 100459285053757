import React from 'react';
import ReactInputMask from 'react-input-mask';
import { classnames } from 'utils';
import styles from './CustomInput.module.scss';

const PhoneInput = ({
  validationErrors,
  required,
  value,
  setIsActive,
  setIsFilled,
  setValidationErrors,
  onChange,
  onBlur,
}) => (
  <ReactInputMask
    className={classnames([[styles.input_error, validationErrors?.length > 0]])}
    type="tel"
    mask="+7\ (999) 999-99-99"
    maskChar="_"
    required={required}
    value={value}
    onFocus={() => setIsActive(true)}
    onBlur={() => {
      setIsActive(false);
      onBlur();
    }}
    onChange={(event) => {
      setIsFilled(!!event.target.value);
      setValidationErrors([]);
      onChange(event.target.value);
    }}
  />
);

export default PhoneInput;
