import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL_API } from './BASE_URL';

const AskDiscount = createApi({
  reducerPath: 'catalog',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL_API }),
  endpoints: (build) => ({
    createAskDiscountApi: build.mutation({
      query: (data) => ({
        url: '/catalog/send-manager-request/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});
export default AskDiscount;
