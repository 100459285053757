import React from 'react';
import { Link } from 'react-router-dom';
import styles from './LaboratoryItem.module.scss';

const LaboratoryItem = ({ title, slug, cropped_image: croppedImage }) => (
  <Link to={`/laboratory/${slug}`} className={styles.wrapper}>
    <div className={styles.image}>
      <img src={croppedImage} alt={title} />
    </div>
    <div className={styles.text}>{title}</div>
  </Link>
);

export default LaboratoryItem;
