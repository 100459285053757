import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { emailValidator, handleServerError, notEmptyValidator, passwordValidators, setValidationErrors } from 'utils';
import { BlueButton, CustomForm, Loading } from 'components/universal_components';
import { authAPI } from 'stateManager/services';
import { setToken } from 'stateManager/reducers/userSlice';
import styles from './LoginModal.module.scss';

const LoginModal = ({ setModalState }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [emailValidationErrors, setEmailValidationErrors] = useState([]);

  const [password, setPassword] = useState('');
  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);

  const fields = [
    {
      name: 'username',
      inputType: 'text',
      placeholder: 'e-mail',
      required: true,
      value: email,
      onChange: (value) => setEmail(value),
      validators: [notEmptyValidator, emailValidator],
      validationErrors: emailValidationErrors,
      setValidationErrors: (value) => {
        setEmailValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'password',
      inputType: 'password',
      placeholder: 'пароль',
      required: true,
      value: password,
      onChange: (value) => setPassword(value),
      validators: [notEmptyValidator, ...passwordValidators],
      validationErrors: passwordValidationErrors,
      setValidationErrors: (value) => {
        setPasswordValidationErrors((state) => setValidationErrors(state, value));
      },
    },
  ];

  const [sendLoginForm, { isLoading, isError: isFetchError, error }] = authAPI.useLoginUserMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  if (isLoading) {
    return <Loading />;
  }

  const sendData = async () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      const res = await sendLoginForm(dataToSend);

      if (!res.error) {
        dispatch(setToken(res.data.token));
        setModalState();
        navigate('/cabinet');
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Вход</h3>
      <CustomForm fields={fields} styles={styles} />
      <p className={styles.required}>* - Поля, обязательные для заполнения</p>
      <BlueButton
        text="Войти"
        variant="diller"
        onClick={() => {
          sendData();
        }}
      />
    </div>
  );
};

export default LoginModal;
