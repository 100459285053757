/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { emailValidator, handleServerError, notEmptyValidator, passwordValidators, phoneValidator, setValidationErrors } from 'utils';
import { BlueButton, ConsentPersonalData, CustomForm, CustomInput, Loading } from 'components/universal_components';
import { setToken } from 'stateManager/reducers/userSlice';
import { authAPI } from 'stateManager/services';
import styles from './RegistrationModal.module.scss';

const RegistrationModal = ({ setModalState, setRegistrationSuccess }) => {
  const dispatch = useDispatch();
  // const { addMessage } = serverMessageSlice.actions;

  const [firstName, setFirstName] = useState('');
  const [firstNameValidationErrors, setFirstNameValidationErrors] = useState([]);

  const [lastName, setLastName] = useState('');
  const [lastNameValidationErrors, setLastNameValidationErrors] = useState([]);

  const [phone, setPhone] = useState('');
  const [phoneValidationErrors, setPhoneValidationErrors] = useState([]);

  const [email, setEmail] = useState('');
  const [emailValidationErrors, setEmailValidationErrors] = useState([]);

  const [password, setPassword] = useState('');
  const [passwordValidationErrors, setPasswordValidationErrors] = useState([]);

  const [repassword, setRePassword] = useState('');
  const [repasswordValidationErrors, setRePasswordValidationErrors] = useState([]);

  const [isAgree, setIsAgree] = useState(false);

  const fields = [
    {
      name: 'first_name',
      inputType: 'text',
      placeholder: 'Имя',
      required: true,
      value: firstName,
      onChange: (value) => setFirstName(value),
      validators: [notEmptyValidator],
      validationErrors: firstNameValidationErrors,
      setValidationErrors: (value) => {
        setFirstNameValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'last_name',
      inputType: 'text',
      placeholder: 'Фамилия',
      required: true,
      value: lastName,
      onChange: (value) => setLastName(value),
      validators: [notEmptyValidator],
      validationErrors: lastNameValidationErrors,
      setValidationErrors: (value) => {
        setLastNameValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'email',
      inputType: 'text',
      placeholder: 'e-mail',
      required: true,
      value: email,
      onChange: (value) => setEmail(value),
      validators: [notEmptyValidator, emailValidator],
      validationErrors: emailValidationErrors,
      setValidationErrors: (value) => {
        setEmailValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'phone',
      inputType: 'tel',
      placeholder: 'телефон',
      required: true,
      value: phone,
      onChange: (value) => setPhone(value),
      validators: [notEmptyValidator, phoneValidator],
      validationErrors: phoneValidationErrors,
      setValidationErrors: (value) => {
        setPhoneValidationErrors((state) => setValidationErrors(state, value));
      },
    },
    {
      name: 'password',
      inputType: 'password',
      placeholder: 'пароль',
      required: true,
      value: password,
      onChange: (value) => setPassword(value),
      validators: [notEmptyValidator, ...passwordValidators],
      validationErrors: passwordValidationErrors,
      setValidationErrors: (value) => {
        setPasswordValidationErrors((state) => setValidationErrors(state, value));
      },

    },
    {
      name: 'repassword',
      inputType: 'password',
      placeholder: 'Повторите пароль',
      required: true,
      value: repassword,
      onChange: (value) => setRePassword(value),
      validators: [{ id: 1, func: (value) => value === password, errorText: 'Пароли не совпадают' }],
      validationErrors: repasswordValidationErrors,
      setValidationErrors: (value) => {
        setRePasswordValidationErrors((state) => setValidationErrors(state, value));
      },
    },
  ];

  const [sendForm, { isLoading, isError: isFetchError, error }] = authAPI.useRegisterUserMutation();

  useEffect(() => {
    if (isFetchError) {
      handleServerError(error);
    }
  }, [isFetchError]);

  if (isLoading) {
    return <Loading />;
  }

  const sendData = async () => {
    const dataToSend = {};
    let isError = false;
    fields.forEach((field) => {
      field.validators.forEach((validateFunction) => {
        if (validateFunction.func(field.value)) {
          dataToSend[field.name] = field.value;
        } else {
          isError = true;
          field.setValidationErrors({ text: validateFunction.errorText, id: validateFunction.id });
        }
      });
    });

    if (!isError) {
      const res = await sendForm(dataToSend);

      if (!res.error) {
        dispatch(setToken(res.data.auth_token));
        setModalState();
        // dispatch(
        //   addMessage({ id: getUuid(), text: 'Аккаунт создан! На ваш email выслано письмо для подтверждения регистрации', type: 'success' }),
        // );
        setRegistrationSuccess(true);
      }
    }
  };

  return (
    <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
      <dl className={styles.text}>
        <dt>Что дает регистрация</dt>
        <dd>
          Работа со списками товаров, создание, редактирование, копирование. Хранение списков сравнения, избранного.
          Подписка на&nbsp;анонс товарных новинок и&nbsp;новостей компании. Получение специальных предложений.
        </dd>
      </dl>
      <div className={styles.inner}>
        <h3 className={styles.title}>Регистрация</h3>
        <CustomForm fields={fields} styles={styles} />
        <div className={styles.agree}>
          <CustomInput inputType="checkbox" checked={isAgree} onChange={(value) => setIsAgree(value)} />
          <ConsentPersonalData setIsAgree={setIsAgree} />
        </div>
        <p className={styles.required}>* - Поля, обязательные для заполнения</p>
        <BlueButton text="Зарегистрироваться" variant="diller" onClick={sendData} disabled={!isAgree} />
      </div>
    </div>
  );
};

export default RegistrationModal;
