import React from 'react';
import { classnames } from 'utils';
import { ReactComponent as Pencil } from 'assets/images/pencil.svg';
import styles from './CabinetPage.module.scss';

const InfoSection = ({ data, setModalState }) => (
  <>
    <div className={classnames([styles.firstname, styles.item])}>
      <div className={styles.info_title}>
        Имя
      </div>
      <div className={styles.info_value}>
        {data.first_name}
      </div>
      <button type="button" onClick={() => setModalState('firstname')} className={styles.info_change}><Pencil /><span className="visually-hidden">Редактировать</span></button>
    </div>

    <div className={classnames([styles.lastname, styles.item])}>
      <div className={styles.info_title}>
        Фамилия
      </div>
      <div className={styles.info_value}>
        {data.last_name}
      </div>
      <button type="button" onClick={() => setModalState('lastname')} className={styles.info_change}><Pencil /><span className="visually-hidden">Редактировать</span></button>
    </div>

    <div className={classnames([styles.middlename, styles.item])}>
      <div className={styles.info_title}>
        Отчество
      </div>
      <div className={styles.info_value}>
        {data.middle_name}
      </div>
      <button type="button" onClick={() => setModalState('middlename')} className={styles.info_change}><Pencil /><span className="visually-hidden">Редактировать</span></button>
    </div>

    <div className={classnames([styles.phone, styles.item])}>
      <div className={styles.info_title}>
        Телефон
      </div>
      <div className={styles.info_value}>
        {data.phone}
      </div>
      <button type="button" onClick={() => setModalState('phone')} className={styles.info_change}><Pencil /><span className="visually-hidden">Редактировать</span></button>
    </div>
  </>
);

export default InfoSection;
