/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { classnames, winScroll } from 'utils';
import Logo from 'components/universal_components/Logo/Logo';
import { Burger } from 'components/universal_components';
import HeaderBurgerMenu from './HeaderBurgerMenu/HeaderBurgerMenu';
import HeaderIcons from './HeaderIcons/HeaderIcons';
import HeaderNav from './HeaderNav/HeaderNav';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import styles from './HeaderSection.module.scss';

const HeaderSection = ({ setModalRegisterState }) => {
  const scroll = winScroll();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState(false);

  const { pathname } = useLocation();
  const [isBlack, setIsBlack] = useState(false);
  const [isLogoBlack, setIsLogoBlack] = useState(false);
  const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

  useEffect(() => {
    setIsBurgerMenuActive(false);
    setIsBlack(pathname.indexOf('/catalog') >= 0);
    setIsLogoBlack(pathname.indexOf('/catalog') >= 0 || pathname === '/');
  }, [pathname]);

  useEffect(() => { if (document.location.pathname === '/') { if (window.innerHeight < window.scrollY) { setIsLogoBlack(false); } else { setIsLogoBlack(true); } } }, [window.scrollY]);

  return (
    <header id="header" className={classnames([styles.header, [styles.scroll, scroll > 100]])} style={isBurgerMenuActive ? { paddingRight: scrollBarWidth } : null}>
      <div
        className={classnames([
          'page_container',
          styles.wrapper,
          [styles.black, isBlack],
          [styles.scroll, scroll > 100],
          [styles.menuActive, isBurgerMenuActive]])}

      >
        {/* Logo */}
        <Link to="/" className={classnames([styles.logo, [styles.black, isLogoBlack]])}>
          <Logo />
        </Link>

        {/* Catalog */}
        <div className={classnames([styles.blue, [styles.black, isBlack], [styles.hidden, isSearchActive]])}>
          <Link to="/catalog">Каталог</Link>
        </div>

        {/* Navigation */}
        <nav className={classnames([styles.nav, [styles.searchActive, isSearchActive], [styles.black, isBlack]])}>
          <HeaderNav />
        </nav>

        {/* Search */}
        <div className={classnames([styles.search, [styles.active, isSearchActive], [styles.black, isBlack]])}>
          <HeaderSearch setIsSearchActive={setIsSearchActive} isSeacrhActive={isSearchActive} black={isBlack} />
        </div>

        {/* Icons */}
        <div className={classnames([styles.icons, 'ignoreCloseMenu', [styles.black, isBlack]])}>
          <HeaderIcons
            black={isBlack}
            setModalRegisterState={setModalRegisterState}
          />
        </div>

        <button
          type="button"
          className={classnames(['ignoreCloseMenu', styles.burger, styles.icon, [styles.pressed, isBurgerMenuActive], [styles.black, isBlack]])}
          onClick={() => setIsBurgerMenuActive(!isBurgerMenuActive)}
        >
          <Burger isBurgerMenuActive={isBurgerMenuActive} black={isBlack} />
        </button>
      </div>

      <div className={classnames([styles.burgerMenu, [styles.black, isBlack]])}>
        <HeaderBurgerMenu
          isActive={isBurgerMenuActive}
          setIsActive={setIsBurgerMenuActive}
        />
      </div>
    </header>
  );
};

export default HeaderSection;
